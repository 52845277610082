<script>
	import { createEventDispatcher } from "svelte";
	import FileAttach from "../components/FileAttach.svelte";
	import { utils_files_add } from "../helpers/apiBackend";
	import { _ } from "svelte-i18n";

	export let idTypeAttachment;
	export let objectName;
	export let previousEvolution;

	let session_date = new Date().toISOString().split("T")[0];

	const objNameList = {
		E: "ExamsDetailCareplan__c",
		C: "InterviewDetailCareplan__c",
		Q: "Questionnaire"
	};

	let file_type = "Documento";
	let document = "";
	let errorMessages = false;

	const dispatch = createEventDispatcher();
	let bodyCompleteSession = {
		Evolucion__c: previousEvolution || "",
		Fecha_de_sesion__c: new Date().toISOString().split("T")[0],
		Completado__c: true,
	};

	const completeSession = async () => {
		bodyCompleteSession.Evolucion__c = previousEvolution;
		bodyCompleteSession.Fecha_de_sesion__c = session_date
		dispatch("complete", { body: bodyCompleteSession });
		if (document !== "") {
			document.detail.fileData.forEach(async (element) => {
				await utils_files_add(element, "", objNameList[objectName]);
			});
		}
	};

	let bodyCompleteSessionWhitoutSend = {
		Evolucion__c: previousEvolution || "",
		Fecha_de_sesion__c: new Date().toISOString().split("T")[0],
		Completado__c: false,
	};

	const completeSessionWhitoutSend = async () => {
		bodyCompleteSessionWhitoutSend.Evolucion__c = previousEvolution;
		bodyCompleteSessionWhitoutSend.Fecha_de_sesion__c = session_date
		dispatch("complete", { body: bodyCompleteSessionWhitoutSend });
		if (document !== "") {
			document.detail.fileData.forEach(async (element) => {
				await utils_files_add(element, "", objNameList[objectName]);
			});
		}
	};

	const closeModal = () => {
		dispatch("cancel");
	};
</script>

<section>
	<div>
		<div class="text-center text-muted">
			<h3>{$_("ModalComplete.text_title")}</h3>
		</div>
		<div>
			<div>
				<label for="fecha-sesion">{$_("ModalComplete.text_th_session_date")}</label><small class="error"
					>*</small>
				<input
					bind:value={session_date}
					type="date"
					class="form-control form-control-sm" />
			</div>
			<div class="form-group">
				<label for="evolucion">{$_("ModalComplete.text_title_Evolution")}</label><small class="error">*</small>

				<textarea
					bind:value={previousEvolution}
					class="form-control"
					id="evolucion"
					rows="3"
					maxlength="99000" />
				
			</div>
			<FileAttach
				{idTypeAttachment}
				{file_type}
				multipleState={true}
				object_name={objNameList[objectName]}
				photoState={true}
				on:dataFile={async (e) => {
					document = e.detail;
					console.log({ e });
				}} />

			<!-- Slot (In case create new) -->
			<div>
				<slot />
			</div>
		</div>

		<div class="errormessageDV">
			{#if errorMessages}
				<small class="error">{$_("ModalComplete.text_errorMessages")}</small>
			{/if}
		</div>
		<br />
		<div class="text-center">
			{#if bodyCompleteSession.Fecha_de_sesion__c !== "" && previousEvolution != ""}
				<button
					on:click={() => {
						completeSession();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("ModalComplete.button_to_complete")}</button>
					<button
					on:click={() => {
						completeSessionWhitoutSend();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("ModalComplete.button_without_complete")}</button>
			{:else}
				<button
					on:click={() => {
						errorMessages = !errorMessages;
					}}
					class="py-0 mx-2 py-2 btnBlue disable">{$_("ModalComplete.button_to_complete")}</button>
					<button
					on:click={() => {
						completeSessionWhitoutSend();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("ModalComplete.button_without_complete")}</button>
			{/if}
			<button
				on:click={() => {
					closeModal();
				}}
				class="py-0 mx-2 py-2 btnWhite">{$_("ModalComplete.button_Cancel")}</button>
		</div>
	</div>
</section>

<style>
	.error {
		color: red;
	}
	.errormessageDV {
		text-align: center;
	}
</style>
