<script>
	import { onMount } from "svelte";
	import { patientLayout, user, language } from "../../helpers/storage/stores";
	//import DoctorListHeader from "../../components/Layouts/Layout_DoctorListHeader.svelte";
	import { generic_account_get, ROLES } from "../../helpers/apiBackend";
	//import dataLabel from "../../helpers/dataLabel.json";
	import { _ } from "svelte-i18n";

	let langSelect = "es";
	if ($language == "es" || $language == "en") {
		console.log("ingreso");
		langSelect = $language;
	}
	//"../../../components/Layouts/Layout_DoctorListHeader.svelte";

	export let isVisible;
	let edad = "";
	let patologias = [];
	let fotoPerfil = "";
	let caregiverName = "";
	let obraSocial = "";
	let plan = "";
	let nAfiliado = "";
	let fullname = "";
	let caregiver = false;
	let fotoPerfilCaregiver = "";
	let result = [];
	let myHeader = true;
	let fechaNac;
	const toggleHeader = () => {
		myHeader = !myHeader;
	};
	let rol = "";
	function getEdad(dateString) {
		let hoy = new Date();
		let fechaNacimiento = new Date(dateString);
		let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
		let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
		if (diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
			edad--;
		}
		return edad;
	}
	// console.log(window.location.href.split("#/")[1]);

	onMount(async () => {
		// console.log("Recarga de layout patient");
		// if ($user.hasOwnProperty("Especialidades__r")) {
		// 	// result = await generic_account_get("", $patientLayout.Id, ROLES.patient);
		// 	result = await generic_account_get("", $patientLayout.Id, "Patient");

		// 	console.log("Verificar ", result);
		// } else {
		// 	result = $user;
		// }
		// console.log({ result });
		// if (result.FirstName) {
		// 	if (result.hasOwnProperty("Patolog_as2__r")) {
		// 		result.Patolog_as2__r.records.forEach((element) => {
		// 			console.log({ element });
		// 			//patologias.push(element.Name);
		// 		});
		// 	}

		// 	edad = getEdad(result.PersonBirthdate);
		// 	obraSocial = result.Cobertura_Salud__c;
		// 	plan = result.Careplan2__r;
		// 	nAfiliado = result.Nro_de_afiliado__c;
		// 	fullname = result.FirstName + " " + result.LastName;
		// 	if (result.hasOwnProperty("Archivos_S3__r")) {
		// 		result.Archivos_S3__r.records.map((element) => {
		// 			console.log(element);
		// 			if (element.Link__c && element.Tipo__c == "Foto de perfil") {
		// 				fotoPerfil = element.Link__c;
		// 			}
		// 		});
		// 	}
		// }
		console.log("Layout_Patient - onMount - : ", $user);
	});

	function loadData() {
		let loggedPatient = JSON.parse(localStorage.getItem("patientLayout"));
		edad = getEdad(loggedPatient.birthDate);
		const [anio, mes, dia] = loggedPatient.birthDate.split("-");
		fechaNac = `${dia}-${mes}-${anio}`;
		obraSocial = loggedPatient.coverage;
		plan = loggedPatient.pathologies[0];
		nAfiliado = loggedPatient.member_number;
		fullname = loggedPatient.name;
		fotoPerfil = loggedPatient.profile_picture;
		// if (loggedPatient.hasOwnProperty("Archivos_S3__r")) {
		// 	loggedPatient.Archivos_S3__r.records.map((element) => {
		// 		console.log(element);
		// 		if (element.Link__c && element.Tipo__c == "Foto de perfil") {
		// 			fotoPerfil = element.Link__c;
		// 		}
		// 	});
		// }
	}

	$: {
		if (isVisible) {
			console.log("Recarga Layout Patient - new");
			loadData();
		}
	}
	$: outerWidth = 0;
	$: innerWidth = 0;
	$: outerHeight = 0;
	$: innerHeight = 0;
</script>

<svelte:window bind:innerWidth bind:outerWidth bind:innerHeight bind:outerHeight />

{#if isVisible}
	<!-- Layout -->
	<section>
		<div class="info-top">
			<div class="mb-3 rounded-bottom shadow">
				<div
					class="elemntPatient d-flex align-items-center justify-content-between mx-auto"
					style="max-width: 1128px;">
					<div class="img-name-container d-flex align-items-center">
						<div class="perfil_info text-center" name="patient-picture">
							<img
								class="imgPerfil"
								style="width: 55px;height: 55px;"
								src={fotoPerfil ? fotoPerfil : "./img/perfil_none.png"}
								alt="" />
							<div class="name-patologia ml-2" style="text-align: justify;margin-top: 2%;">
								{#if fullname}
									<span style="display: inline-block; width: 250px;"
										><strong>{fullname} </strong></span>
									<br />
									<span style="display: inline-block; width: 250px;"
										><strong>{$_("LayoutPatient.textAge")}: </strong> {edad}
									</span>
								
										<strong>{$_("LayoutPatient.textPathologi")}:</strong>
										{#if plan.length > 40}
											{plan.toString().slice(0,37)}...
										{:else}
											{plan}
										{/if}
									<br />
									<span style="display: inline-block; width: 250px;"
										><strong>{$_("LayoutPatient.text_birthdate")}:</strong> {fechaNac}</span>
									<span><strong>{$_("LayoutPatient.text_title_Coverage")}</strong> 
										{#if obraSocial.length > 30}
											{obraSocial.toString().slice(0,27)}...
										{:else}
											{obraSocial}
										{/if} - {nAfiliado}</span>
								{/if}
								{#if result.hasOwnProperty("Patolog_as2__r") && result.Patolog_as2__r.hasOwnProperty("records") && result.Patolog_as2__r.records.length > 0}
									{#if $language === "es"}
										<p class="text-reduce m-0">{result.Patolog_as2__r.records[0].Name_ES__c}</p>
									{:else if $language === "en"}
										<p class="text-reduce m-0">{result.Patolog_as2__r.records[0].Name_EN__c}</p>
									{/if}
								{/if}
								{#if result.hasOwnProperty("Careplan2__r")}
									{#if $language === "es"}
										<p class="text-reduce m-0">{result.Careplan2__r.Name_ES__c}</p>
									{:else if $language === "en"}
										<p class="text-reduce m-0">{result.Careplan2__r.Name_EN__c}</p>
									{/if}
								{/if}
								{#if result.hasOwnProperty("Cobertura_Salud__c")}
									<p class="m-0">{result.Cobertura_Salud__c} - {result.Nro_de_afiliado__c}</p>
								{/if}
							</div>
						</div>
					</div>
					<div class="buttons-container d-flex align-items-center">
						<button
							on:click={() => {
								window.location.href = "#/ClinicHistory";
							}}
							class="btnBlue my-1 mr-2 patient-profile-picture"
							>{$_("LayoutPatient.button_history")}</button>

						<button
							on:click={() => {
								window.location.href = "#/Plan";
							}}
							class="btnBlue my-1 mr-2 patient-profile-picture">{$_("LayoutPatient.button_plan")}</button>
						<!-- <button
						on:click={() => {
							//window.location.href = "#/CreateActivity";
						}}
						class="btnBlue my-1 patient-profile-picture">Perfil</button> -->
					</div>
				</div>
			</div>
		</div>
		<div class="info-bottom">
			<slot />
		</div>
	</section>
{/if}

<style>
	.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Ajusta según tu diseño */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    white-space: pre-wrap; /* Permite saltos de línea si el texto es muy largo */
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
	:root {
		--color-patient: #3a0c97;
	}

	.imgPerfil {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-size: 120%;
	}
	.name-patologia {
		margin-left: 10px;
	}

	.img-name-container {
		flex-grow: 1;
	}
	.perfil_info {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.buttons-container button {
		min-width: 100px;
	}

	/* .small-header-button:hover {
		background-color: #3A0C97;
		color: white;
		transition: 0.2s;
	}
	.contenPatient {
		width: 100%;
		justify-content: end;
	} */
	.elemntPatient {
		justify-content: center;
	}
	/* .variableA {
		width: 500px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: initial;
	} */
	.btnBlue {
		letter-spacing: 1px;
		border-radius: 10px;
		color: #fff;
		background-color: transparent;
	}
	.btnBlue:hover {
		letter-spacing: 1px;
		border-radius: 10px;
		color: #ffffff;
		background-color: var(--my-dark-blue);
	}
	/* .space_title {
		width: 100px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: initial;
	}
	.my-header {
		background-color: var(--color-patient) !important;
		color: #ffffff;
	} */
	.text-reduce {
		width: 742px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: initial;
	}
	.info-top {
		color: #fff;
		background-color: #420eaa;
	}
	
</style>
