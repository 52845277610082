<script>
	import { onMount } from "svelte";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import { user } from "../../../helpers/storage/stores.js";

	let userStatusMedicoCabera;
	onMount(() => {
		userStatusMedicoCabera = $user["Estado_Medico_de_cabecera__c"];
		if (userStatusMedicoCabera == "Pendiente de aprobación") {
			window.location.href = "#/Patient/Pending";
		} else if (userStatusMedicoCabera == "Aún no se eligió medico") {
			window.location.href = "#/Patient/SearchDoctor?action=assignHeaderDoctor";
		}
	});
</script>

<section>
	<LayoutNav secure={true} rol="Familiar_Cuidador">
		<div class="m-3 rounded shadow-sm bg-white">
			<div class="text-center">
				<div class="my-plan-header p-5 text-white d-flex align-items-center justify-content-center">
					<div class="">
						<h3>Perfil</h3>
					</div>
				</div>
				<div class="border rounded shadow bg-white container">
					<div class="p-3 mx-1 text-center border-bottom">
						<h3 class="text-muted">Seleccione perfil</h3>
					</div>
					<div class="py-5">
						<div class="d-flex justify-content-center p-2 flex-wrap">
							<button
								on:click={() => {
									window.location.href = "#/Patient/Records?type=Citas/Entrevistas";
								}}
								class="btnCitas">
								<div class="logo text-center">
									<img class="imglogo" src="./img/perfil_none.png" alt="logo" />
								</div>
								Carmen Pereira
								<br />
								Patologia: Pader Wills
								<br />
								Edad: 30
							</button>

							<button disabled class="btnRegistros">
								<div class="logo text-center">
									<img class="imgAdd" src="./img/add.png" alt="logo" />
								</div>
								Nuevo Paciente</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</LayoutNav>
</section>

<style>
	.imglogo {
		width: 100px;
		border-radius: 50%;
		background-size: 120%;
	}
	.imgAdd {
		width: 100px;
		background-size: 120%;
	}

	/* .activity-button{
        width: 150px;
    } */
	.btnCitas {
		margin: 10px;
		width: 20%;
		height: 15rem;
		letter-spacing: 1px;
		color: #0C0C0C;
		border-radius: 10px;
		background-color: #fff;
	}

	.container {
		max-width: 98%;
		height: auto;
	}
	.btnRegistros {
		margin: 10px;
		width: 20%;
		height: 15rem;
		letter-spacing: 1px;
		color: #0C0C0C;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnRegistros:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}
	button:hover {
		transform: scale(1.1);
		transition: 0.2s;
		-webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
	}
</style>
