import { addMessages, init } from "svelte-i18n";
import en from "./en.json";
import es from "./es.json";

addMessages("en", en);

addMessages("es", es);
let storedLanguage = JSON.parse(localStorage.getItem("language")) || "es";

if (Object.keys(storedLanguage).length === 0) {
	storedLanguage = "es";
} else {
	// storedLanguage contiene datos
	// Realiza las acciones correspondientes
	//console.log({ storedLanguage });
}
//console.log({ storedLanguage });

init({ initialLocale: storedLanguage });
