<script>
	// Layouts
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import DoctorListHeader from "../../../components/Layouts/Layout_DoctorListHeader.svelte";

	// Dependency
	import { user } from "../../../helpers/storage/stores.js";
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";

	let user_status_header_doctor;
	onMount(() => {
		user_status_header_doctor = $user["Estado_Medico_de_cabecera__c"];
		if (user_status_header_doctor == "Pendiente de aprobación") {
			window.location.href = "#/Patient/Pending";
		} else if (user_status_header_doctor == "Aún no se eligió medico" || user_status_header_doctor == "Rechazado") {
			window.location.href = "#/Patient/SearchDoctor?action=assignHeaderDoctor";
		}
	});
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	<div class="rounded shadow-sm bg-white">
		<div class="text-center">
			<div class="my-plan-header p-5 text-white d-flex align-items-center justify-content-center">
				<div class="">
					<h3>{$_("PlanPatient.text_plan")}</h3>
				</div>
			</div>
			<DoctorListHeader
				myPage=""
				buttomHC={$_("PlanPatient.button_history")}
				buttomHCHref="#/ClinicHistory"
				buttomPlan=""
				buttomPlanHref=""
				selected={window.location.href.split("#/")[1]} />
			<!-- buttomDiag={$_("PlanPatient.button_diagnosis")}
					buttomDiagHref="#/DiagnosisByProf" -->
			<div class="border rounded shadow bg-white container">
				<div class="p-3 mx-1 text-center border-bottom">
					<h3 class="text-muted">{$_("PlanPatient.title")}</h3>
				</div>
				<div class="text-center text-white myCustom-patientHeader p-3" />
				<div class="py-5">
					<div class="d-flex justify-content-center p-2 flex-wrap">
						<button
							on:click={() => {
								window.location.href = "#/Patient/Records?type=Citas/Entrevistas";
							}}
							class="btnCitas">{$_("PlanPatient.btnCitas")}</button>
						<button
							on:click={() => {
								window.location.href = "#/Patient/Records?type=Estudios";
							}}
							class="btnEstudios">{$_("PlanPatient.btnEstudios")}</button>
						<button disabled class="btnRegistros">{$_("PlanPatient.btnRegistros")}</button>
					</div>
					<div class="d-flex justify-content-center p-2 flex-wrap">
						<button
							on:click={() => {
								window.location.href = "#/Patient/RecordMedicationPatient";
							}}
							class="btnEstudios">{$_("PlanPatient.btnMedicinas")}</button>
						<button
							on:click={() => {
								window.location.href = "#/Patient/ViewCuestionarioPatient?type=Cuestionarios";
								// alert('Sitio en construccion Cuestionarios') btnCuestionarios
							}}
							class="btnCuestionarios">{$_("PlanPatient.btnCuestionarios")}</button>
						<button disabled class="btnOtros">{$_("PlanPatient.btnOtros")}</button>
					</div>
				</div>
				<!-- <div class="d-flex justify-content-center mx-1 border-top">
                        <a href="#/Doctor">Volver a mis pacientes</a>
                    </div> -->
			</div>
		</div>
	</div>
	<!-- </LayoutNav> -->
</section>

<style>
	.my-plan-header {
		background-color: #215273;
		/* background-image: url('https://images.unsplash.com/photo-1487956382158-bb926046304a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80'); */
	}

	.btnCitas {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #0C0C0C;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnEstudios {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #0C0C0C;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnOtros {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #0C0C0C;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnOtros:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}
	.container {
		max-width: 98%;
		height: auto;
	}

	.btnCuestionarios {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #0C0C0C;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnCuestionarios:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}

	.btnRegistros {
		margin: 10px;
		width: 25%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #0C0C0C;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnRegistros:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}
	button:hover {
		transform: scale(1.1);
		transition: 0.2s;
		-webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
	}
</style>
