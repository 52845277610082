<script>
	import Amplify, { Auth } from "aws-amplify";
	import { replace } from "svelte-spa-router";
	//import Modal from "../../components/Modal.svelte";
	import awsconfig from "../../aws-exports";

	import { _ } from "svelte-i18n";
	export let params;

	Auth.configure(awsconfig);
	let username = params.email;
	let new_password;
	let code = "";
	let errorPassword = "";
	// let confirmModal = false
	// let message = ''

	/**
	 * @description
	 */
	async function confirmEmail() {
		if (validatePassword()) {
		Auth.forgotPasswordSubmit(username, code, new_password)
			.then((data) => {
				replace("/");
			})
			.catch((err) => {}); //console.log(err));
		} else {
			errorPassword = "La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una letra minúscula, un número y un carácter especial (@$!%*?&.).";
		}

	}

	function validatePassword() {
		const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
		if (!regex.test(new_password)) {
			return false;
			errorMessagePassword = console.log("pass no valido");
			("La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una letra minúscula, un número y un carácter especial.");
		} else {
			return true;
		}

		// Enviar un evento si la validación pasa
		if (!errorMessagePassword) {
			return true;
			dispatch("validPassword", password);
		}
	}
</script>

<svelte:head>
	<title>RDCOM - {$_("NewPassword.title")}</title>
	<link rel="icon" href="/img/new_favicon.png">
</svelte:head>
<div class="register-conteiner">
	<div class="register-info-conteiner">
		<form class="register-form" on:submit|preventDefault={confirmEmail}>
			<!-- <h1 class="title-welcome">Restablecer su contraseña</h1> -->
			<div class="confirmar-email" style="d-flex justify-content-center">
				{$_("NewPassword.text_code")}
				<input
					class="input-log"
					type="text"
					maxlength="6"
					placeholder="Ingrese su codigo"
					bind:value={code}
					required />
			</div>
			<br />
			<div class="contraseña">
				{$_("NewPassword.text_new_pass")}
				<input
					class="input-log"
					type="password"
					placeholder="al menos 8 caracteres"
					minlength="8"
					bind:value={new_password}
					required />
			</div>
			{#if errorPassword != ""}
					<div class="conteiner-error">
						<small class="messageError">{errorPassword}</small>
					</div>
			{/if}
			<br />
			<button class="btnRegister">{$_("NewPassword.button_confirm")}</button>
		</form>
		<p class="span_register">{$_("NewPassword.text_message_regis")}</p>
		<a href="/Login" on:click={() => replace("/")}>{$_("NewPassword.button_login")}</a>
	</div>
</div>

<!-- <Modal isOpenModal={confirmModal} on:closeModal={()=>replace("/")}> 
  <p>{message}</p>
</Modal> -->
<style>
	.logo {
		width: max-content;
		height: max-content;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
	}
	.register-conteiner {
		margin-top: 130px;
		width: 500px;
		height: max-content;
		padding: 17px;
		background: #fff;
		margin-left: auto;
		border-radius: 15px;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}
	.register-info-conteiner {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 0.5rem;
	}
	.register-form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.conteiner-img {
		text-align: center;
	}
	.title-welcome {
		color: #0C0C0C;
		text-align: center;
		padding-right: 15px;
		padding-left: 30px;
		width: 200px;
	}

	.confirmar-email {
		width: 90%;
		color: #0C0C0C;
		font-weight: 600;
	}
	.input-log {
		width: 100%;
		height: 3.125rem;
		padding-left: 9px;
		border-radius: 5px;
		letter-spacing: 1px;
	}

	.btnRegister {
		margin: 10px;
		width: 90%;
		height: 3.2rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #f7fafc;
		border-radius: 5px;
		background-color: #25085e;
	}
	.conteiner-error {
		text-align: start;
		width: 90%;
		align-items: flex-start;
		overflow: hidden;
		color: red;
	}
	.contraseña {
		width: 90%;
		color: #0C0C0C;
		font-weight: 600;
	}
	@media screen and (max-width: 1000px) {
		.conteiner-img {
			display: none;
		}
		.register-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
	}
</style>
