<script>
	import Menu from "../../components/Menu.svelte";
	import { currentSession } from "../../helpers/storage/sessions.js";
	import { user, CantAlta, patient } from "../../helpers/storage/stores.js";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import HelpFomr from "../../components/HelpFomr.svelte";
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";
	//import LayoutPatient from "./Layout_Patient.svelte";
	export let secure = false;
	export let rol = "";
	export let valisLoggeIn;
	let menu = true;
	let useBackProfesional = false;
	let modalSuccessHelp = false;
	let isVisible = true;

	$: altasCount = $CantAlta;

	const handleStorageChange = (event) => {
		if (event.key === "CantAlta") {
			// Update the altasCount when CantAlta changes
			altasCount = event.newValue;
		}
	};
	function closeHelpForm() {
		modalSuccessHelp = false;
	}

	function redirectToAltas() {
		window.location.href = "#/Altas";
	}

	// // Función para verificar si una clave tiene un valor en el local storage - SALI 080724
	// function checkLocalStorageValue(key) {
    // 	const value = localStorage.getItem(key);
	// 	const data = JSON.parse(value);
		
	// 	// const parsedUserData = JSON.parse(statusReg);
	// 	// const customStatusReg = data.attributes.find(attr => attr.Name === "custom:StatusReg").Value;
	// 	console.log("Datos user Menu",data); // Esto imprimirá "false"
	// 	console.log("Datos user Menu",data.attributes["custom:StatusReg"]); // Esto imprimirá "false"


	// 	return value !== null && value !== '';
  	// }

	onMount(async () => {
		
	});

	let styleMobile = false;
	$: outerWidth = 0;
	$: innerWidth = 0;
	$: outerHeight = 0;
	$: innerHeight = 0;
	$: {
		if (innerWidth < 805) {
			menu = false;
			styleMobile = true;
		} else {
			styleMobile = false;
		}
	}
	const closeSession = () => {
		$currentSession = {};
		$user = {};
		$patient = {};
		window.location.href = "/";
	};
</script>



<svelte:window bind:innerWidth bind:outerWidth bind:innerHeight bind:outerHeight />

{#if isVisible}
<section class="{useBackProfesional ? 'box' : 'box-patient'} ">
	{#if Object.keys($user).length !== 0}
		{#if menu}
			<div class="{styleMobile ? 'panel-left-mobile' : 'panel-left'} panel-left d-flex flex-column">
				<button
					class="btnMenuA p-3"
					on:click={() => {
						menu = !menu;
					}}>
					<div class="icono-con-fondo"><img src="./img/menu.svg" alt="Menú"></div>
				</button>
				<Menu />
			</div>
		{:else}
			<div
				class="{styleMobile
					? 'panel-left-short-mobile'
					: 'panel-left-short'} panel-left-short text-center d-flex flex-column">
				<div class="d-flex flex-column">
					<button
						class="btnMenu p-3"
						on:click={() => {
							menu = !menu;
						}}>
						<div class="icono-con-fondo"><img src="./img/menu.svg" alt="Menú"></div>
						</button>
					<div class="text-center">
						<!-- <img class="imag" src="./img/Frame 106.png" alt="logo" width="30px" height="30px" /> -->
						<img class="imag" src="./img/new_logo_redondo.png" alt="logo" width="30px" height="30px" />
						<br />
					</div>
					<button
						on:click={() => {
							menu = !menu;
						}}
						class="btnPerfil p-3"
						title={$_("Menu.text_profile")}><i class="gg-profile" /></button>
					{#if rol == "Profesional_de_Salud"}
						<button
							class="btnPaciente p-3"
							on:click={() => {
								window.location.href = "#/Doctor";
							}}
							title={$_("Menu.button_patient")}>
							<div class="icono-con-fondo"><img src="./img/user-list.svg" alt="Menú"></div>
						</button>
						<!-- <button
						class="btnAltas p-3"
						on:click={() => {
							window.location.href = "#/Altas";
						}}
						title="Altas"><i class="gg-user-add" /></button> -->
						<button class="btnAltas p-3" on:click={redirectToAltas} title={$_("Menu.button_altas")}>
							<div class="icono-con-fondo"><img src="./img/user-add.svg" alt="Menú">
							<!-- {#if altasCount > 0}
								<span class="badge" style="display: inline-table;">{altasCount}</span>
							{/if} -->
						</div>
						</button>
					{/if}
					{#if rol == "Paciente" && $user.Estado_Medico_de_cabecera__c == "Aprobado"}
						<button
							class="btnPaciente p-3"
							on:click={() => {
								window.location.href = "#/Patient/Plan";
							}}
							title={$_("Menu.button_plan")}><i class="gg-clipboard" /></button>
					{/if}
					{#if rol == "Familiar_Cuidador"}
						<button
							class="btnPaciente p-3"
							on:click={() => {
								console.log("mostrar pacientes");
							}}
							title="Pacientes"><i class="gg-clipboard" /></button>
					{/if}
					{#if false}
						<button
							class="btnPaciente p-3"
							on:click={() => {
								console.log("mostrar pacientes");
							}}
							title={$_("Menu.button_setting")}><i class="gg-toolbox" /></button>
					{/if}
					{#if false}
						<button
							class="btnPaciente p-3"
							on:click={() => {
								console.log("mostrar pacientes");
							}}
							title="Help"><i class="gg-flag-alt" /></button>
					{/if}
				</div>

				<div class="d-flex flex-column mt-auto">
					<button
						class="btnPaciente p-3"
						data-title={$_("Menu.button_help")}
						on:click={() => {
							modalSuccessHelp = true;
						}}>
						<div class="icono-con-fondo"><img src="./img/info.svg" alt="Menú"></div>
					</button>
					<button
						on:click={() => {
							closeSession();
						}}
						id="close-session-button"
						class="btncerrarS p-3"
						title={$_("Menu.button_logout")}>
						<div class="icono-con-fondo"><img src="./img/log-off.svg" alt="Menú"></div>
					</button>
				</div>
			</div>
		{/if}
	{/if}
	<div class={menu ? "panel-rigth-short" : "panel-rigth-large"}>
		<slot />
	</div>
</section>

<LayoutModal
	tam="30"
	isOpenModal={modalSuccessHelp}
	on:closeModal={() => {
		modalSuccessHelp = false;
	}}>
	<HelpFomr on:closeHelpForm={closeHelpForm} />
</LayoutModal>

{/if}

<style>
	.icono-con-fondo {
            display: inline-block;  /* Permite que el contenedor se ajuste al tamaño del contenido */
            background-color: #fff; /* Color de fondo */
            padding: 5px;  /* Espacio alrededor del ícono */
            border-radius: 50%;  /* Bordes redondeados (círculo) */
    }
        /* Estilo de la imagen */
    .icono-con-fondo img {
            width: 18px;  /* Ancho del ícono */
            height: 18px; /* Altura del ícono */
            display: block;  /* Evita el espacio extra debajo de la imagen */
    }
	#close-session-button:hover {
		background-color: rgb(255, 195, 195);
		color: #f5f5f5;
		transition: 0.2s;
	}
	.imag {
		margin-top: 20%;
	}
	.btnMenu {
		background-color: #25085e;
		color: #fff;
		border: none;
	}
	.btnMenuA {
		background-color: #25085e;
		color: #fff;
		border: none;
		margin-left: auto;
	}
	.btnPaciente {
		background-color: #25085e;
		color: #fff;
		border: none;
	}
	.btnAltas {
		background-color: #25085e;
		color: #fff;
		border: none;
	}
	.btncerrarS {
		background-color: #25085e;
		color: #fff;
		border: none;
	}
	[data-title]:hover::after {
		content: attr(data-title);
		background-color: #a6c8de;
		color: white;
		padding: 2px 6px;
		border-radius: 2px;
		position: absolute;
		left: 5%;
		transform: translateX(-50%);
		font-size: 12px;
		z-index: 1;
	}
	.badge {
		position: absolute;
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(255, 0, 0, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.btnAltas {
		position: relative;
	}
	.btnPerfil {
		background-color: #25085e;
		color: #fff;
		border: none;
	}
	.box {
		display: flex;
		/* background-image: url('https://images.unsplash.com/photo-1552860512-13148a37d7a2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'); */
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.box-patient {
		display: flex;
		/* background-image: url('https://images.unsplash.com/photo-1568832359672-e36cf5d74f54?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'); */
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.panel-left {
		background: #25085e;
		width: 20%;
		height: 100vh;
	}
	.panel-left-mobile {
		background: #25085e;
		width: 100vw;
		height: 110vh;
	}
	.panel-left-short {
		background: #25085e;
		height: 100vh;
		display: flex;
		flex-direction: column;
		color: aqua;
	}
	.panel-left-short-mobile {
		background: #25085e;
		height: 100vh;
		display: flex;
		flex-direction: column;
		color: aqua;
		/* position: fixed; */
	}
	.panel-rigth-short {
		width: 80%;
		margin-right: auto;
		margin-left: auto;
	}
	.panel-rigth-large {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
</style>
