<script>
	import { onMount } from "svelte";
	//import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import { formatDate } from "../../../helpers/helpers";
	//import AddDetailsMedications from "../../../components/addDetailsMedications.svelte";
	import {
		utils_files_get,
		utils_files_add,
		generic_questionnaire_detail_get,
		generic_questionnaire_results_get,
		questionnaire_details_update_postcomplete,
	} from "../../../helpers/apiBackend";
	import LayoutPatientHeader from "../../../components/Layouts/Layout_Patient_Header.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { registerDetail, user, language } from "../../../helpers/storage/stores";
	import { currentSession } from "../../../helpers/storage/sessions.js";
	import Modal from "../../../components/Modal.svelte";
	import { _ } from "svelte-i18n";
	// import FileAttach from "../../../components/FileAttach.svelte";
	// import { button } from "aws-amplify";
	// import { user } from "../../../helpers/storage/stores";
	// import { each, element } from "svelte/internal";
	// import { identity } from "svelte/internal";

	let questionResult = {};
	let linkQuestionPro = "";
	let surveyView = false;
	let modalViewAnswer = false;
	let error = null;
	let resultsAnswer = [];
	let progress = "100";
	let accountId = $user.Id;
	let RefResultado = "";
	let questionnaire_detail = [];

	//let typeAttachment = "Patologia_Detalle__c";
	let idTypeAttachment = "";
	let file_type = "Certificado patologia";
	let fileAttPatologia = {};
	let dateSession = "";
	let type = "";
	let patching = true;
	let dataAdjunto = [];
	let myType, myScope, myParams, originalRecord, myHeaders, myToken, myRecomendedDoctor;
	let [editRealDate, modalLoadingAnimation, modalSuccess] = [false, false, false];
	let modalEdit = false;
	const AWS_HOST = process.env.HOST_DEV;

	if ($currentSession.hasOwnProperty("signInUserSession")) {
		myToken = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
	} else {
		alert("Error!");
	}

	// Generate body to send
	let myBody = {};

	// Update
	// PATCH (204 Only / Return code with body)
	let modalPatch = false;

	let sendChanges = false;

	function getViewAnswer(questionnaireId) {
		//modalLoadingAnimation = true; // Mostrar animación de carga

		// Llamar a la función de la API para obtener los resultados del cuestionario
		generic_questionnaire_results_get(questionnaireId)
			.then((res) => {
				console.log({ res });
				if (res.statusCode === 200) {
					console.log("Resultados del cuestionario obtenidos correctamente");
					//modalLoadingAnimation = false; // Ocultar animación de carga
					resultsAnswer = res.data; // Asignar los resultados a la variable resultsAnswer
					modalViewAnswer = true; // Mostrar el modal con los resultados
					console.log({ resultsAnswer });
				} else {
					console.error("Error al obtener los resultados del cuestionario:", res.message);
					modalLoadingAnimation = false; // Ocultar animación de carga en caso de error
					// Manejar el error de acuerdo a tus necesidades
				}
			})
			.catch((err) => {
				console.error("Error al obtener los resultados del cuestionario:", err);
				modalLoadingAnimation = false; // Ocultar animación de carga en caso de error
				// Manejar el error de acuerdo a tus necesidades
			});
	}

	async function validateQuestionnaireId(questionnaireId) {
		console.log({ questionnaireId });
		//modalLoadingAnimation = true;
		var today = new Date();
		var formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
		let data = {
			Id: questionnaireId,
			completed: true,
			dateCompleted: formattedDate,
			accountId,
		};
		try {
			modalLoadingAnimation = true;
			const res = await questionnaire_details_update_postcomplete(data);
			if (res.statusCode == 201) {
				//questionnaire_detail["completed"] = true;
				modalLoadingAnimation = false;
				return true; // Retorna true si la actualización se realiza correctamente
			} else {
				modalLoadingAnimation = false;
				return false; // Retorna false si hay algún error en la actualización
			}
		} catch (error) {
			modalLoadingAnimation = false;
			console.error("Error al realizar la solicitud:", error);
			return false; // Retorna false si ocurre algún error
		}
	}

	onMount(async () => {
		myType = "Cuestionarios";
		// Validate Parameters (RecordType / Scope)
		delete $registerDetail["Actividad_Detalles__r"];
		myParams = document.location.href.split("?")[1];
		if (myParams.indexOf("&") > -1) {
			myParams = myParams.split("&");
			myType = myParams[0].split("=")[1];
			myScope = myParams[1].split("=")[1];
		}
		if (myScope == undefined || myScope == "" || myType == undefined || myType == "") {
			window.location.href = "#/GeneralError";
		}
		//let id = $registerDetail["detail"]["Id"];
		let Id = $registerDetail["detail"]["Id"];
		await generic_questionnaire_detail_get(Id, $language).then((res) => {
			console.log({ res });
			if (res.statusCode == 200) {
				questionnaire_detail = res.data[0];
				linkQuestionPro = questionnaire_detail["qestionnaireCareplan"][0].linkQuestionPro;
			}
			console.log({ questionnaire_detail });
			patching = false;
		});
		//let res = questionResult["resultados"];
		if ($registerDetail["detail"]["Name"] == "Escala de la severidad de la fatiga") {
			res.forEach((element) => {
				let p = (element.Resultado__c * 100) / 63;
				p = Math.ceil(p);
				progress = p.toString();
			});
		}

		// res.forEach((item) => {
		// 	console.log({ item });
		// 	RefResultado = item?.Actividad_Detalle__r?.Referencias_resultado__c;
		// });

		//let response = [];
		//console.log({ response });

		//questionResult.preguntas_respondidas = response;
	});
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	{#if patching}
		<div class="p-5 text-center">
			<Loader text={false} />
			<p class="text-muted">{$_("RegisterDetailQuestionPatient.loading")}</p>
		</div>
	{:else}
		<LayoutPatientHeader />
		<div class="content">
			<div class="conteiner justify-content-start">
				<div class="my-record">
					<div class="conteiner-detail py-2 my-3 border rounded shadow-sm bg-white">
						<div class="shadow-sm" style="width: 100%; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);">
							<h5
								class="text-reduce text-muted"
								style="
    margin-left: 2%;
">
								{myType} | {questionnaire_detail.name}
							</h5>
						</div>

						{#if questionnaire_detail != {} && !surveyView}
							<div class="form-detail">
								<div class="row">
									<!-- requested -->
									<div class="column">
										<p class="requested">
											{$_("RegisterDetailQuestion.text_required")}
										</p>
										{#if questionnaire_detail["requested"]}
											<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{:else}
											<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{/if}
									</div>
									<!-- completed -->
									<div class="column">
										<p class="text_complete">
											{$_("RegisterDetailQuestion.text_complete")}
										</p>
										{#if questionnaire_detail["completed"]}
											<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{:else}
											<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{/if}
									</div>
									<!-- createdByName -->
									<div class="column">
										<p class="createdByName">
											{$_("RegisterDetailQuestion.text_th_createdby")}
										</p>
										{#if questionnaire_detail.hasOwnProperty("createdByName")}
											{questionnaire_detail["createdByName"]}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailQuestion.text_not_State")}</span>
										{/if}
									</div>
								</div>
							</div>
							<div class="form-detail">
								<div class="row">
									<div class="column">
										<p class="requested">
											{$_("RegisterDetailQuestion.text_dateSuggested")}
										</p>
										{#if questionnaire_detail["dateSuggested"]}
											{formatDate(questionnaire_detail["dateSuggested"], $language)}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailQuestion.text_not_assigned")}</span>
										{/if}
									</div>
									<div class="column">
										<p class="dateCompleted">
											{$_("RegisterDetailQuestion.text_survey_date_complete")}
										</p>
										{#if questionnaire_detail.hasOwnProperty("dateCompleted") && questionnaire_detail["dateCompleted"] != "" && questionnaire_detail["dateCompleted"] != null}
											{formatDate(questionnaire_detail["dateCompleted"], $language)}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailProf.text_th_session_not_date")}</span>
										{/if}
									</div>
									<!-- dateSuggested -->

									<div class="column">
										<p class="dateReal">
											{$_("RegisterDetailQuestion.text_survey_date_create")}
										</p>
										{#if questionnaire_detail["dateReal"]}
											{formatDate(questionnaire_detail["dateReal"], $language)}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailQuestion.text_th_not_dateReal")}</span>
										{/if}
									</div>
								</div>
							</div>

							<div class="form-detail">
								<div class="row">
									<div class="column">
										<p class="requested">
											{$_("RegisterDetailQuestion.text_Picklist_Scale_Person")}
										</p>
										{#if $registerDetail["register"]["Picklist_Scale_Person"]}
											{$registerDetail["register"]["Picklist_Scale_Person"]}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailQuestion.text_not_assigned")}</span>
										{/if}
									</div>
									<div class="column">
										<p class="dateCompleted">
											{$_("RegisterDetailQuestion.text_toWhom")}
										</p>
										{#if $registerDetail["register"]["toWhom"]}
											{$registerDetail["register"]["toWhom"]}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailProf.text_th_session_not_date")}</span>
										{/if}
									</div>
									<!-- dateSuggested -->

									<div class="column">
										<p class="dateReal">
											{$_("RegisterDetailQuestion.text_variable")}
										</p>
										{#if $registerDetail["register"]["variable"]}
											{$registerDetail["register"]["variable"]}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailProf.text_th_session_not_date")}</span>
										{/if}
									</div>
								</div>
							</div>

							<div class="form-detail">
								<br />
								<div class="d-flex justify-content-center">
									{#if questionnaire_detail.requested && !questionnaire_detail.completed}
										<button
											on:click={() => {
												//	console.log("Completar", myType);
												//	modalComplete = true;
												surveyView = true;
											}}
											class="btnBlue ml-3"
											>{$_("RegisterDetailQuestion.button_to_complete")}</button>
									{/if}
									{#if questionnaire_detail.completed}
										<button
											on:click={() => {
												resultsAnswer = getViewAnswer(questionnaire_detail.Id);
												modalViewAnswer = true;
											}}
											class="btnBlue ml-3">{$_("RegisterDetailQuestion.button_answers")}</button>
									{/if}
								</div>
							</div>
						{:else}
							<div class="container-miIframe">
								<!-- svelte-ignore a11y-missing-attribute -->
								<iframe id="miIframe" src={linkQuestionPro} width="100%" height="500px"></iframe>
							</div>
							<!-- <div class="finally_button_contein">
										{#if surveyViewButton}
											<button
												class="finally_button"
												style="cursor: pointer; padding: 0.8rem 0px;"
												on:click={() => {
													completeQuestionnaireValidate();
												}}>Finalizar</button>
										{/if}
									</div> -->
						{/if}
					</div>

					<div
						class=" d-flex justify-content-center"
						style="
    height: 28px;
">
						{#if !surveyView}
							<a
								class="mx-2"
								style="padding: 1rem 0;"
								href="#/Patient/ViewCuestionarioPatient?type={myType}">Volver a {myType}</a>
						{:else}
							<!-- svelte-ignore a11y-missing-attribute -->
							<a
								class="mx-2"
								style="cursor: pointer; padding: 1rem 0px;"
								on:click={() => {
									questionnaire_detail["completed"] = validateQuestionnaireId(
										questionnaire_detail["Id"]
									);
									surveyView = false;
								}}>Volver atras</a>
						{/if}

						<a class="mx-2" style="padding: 1rem 0;" href="#/Patient/Plan">Volver al plan</a>
					</div>
				</div>
			</div>
		</div>
	{/if}
	<!-- </LayoutNav> -->
</section>

<!-- Modal Cargando -->
<!-- <LayoutModal
	isOpenModal={modalLoadingAnimation}
	on:closeModal={() => {
		modalLoadingAnimation = false;
	}}>
	<div class="p-3">
		<Loader text={false} />
		<p class="text-muted">...</p>
	</div>
</LayoutModal> -->

<Modal isOpenModal={modalLoadingAnimation}>
	<div class="text-center">
		<br />
		<Loader text={false} />
		<div>
			<p class="text-muted">{$_("RegisterDetailQuestionPatient.loading")}</p>
		</div>
	</div>
</Modal>

<!-- Modal Success -->
<LayoutModal isOpenModal={modalSuccess}>
	<div class="p-2 text-center">
		{#if myType}
			<p>{$_("RegisterDetailQuestionPatient.text_success")}</p>
			<a class="mx-2" href="#/Records?type={myType}"
				>{$_("RegisterDetailQuestionPatient.button_return")} {myType}</a>
		{/if}
	</div>
</LayoutModal>
<LayoutModal
	tam="50"
	isOpenModal={modalViewAnswer}
	on:closeModal={() => {
		modalViewAnswer = false;
	}}>
	<div class="p-3 text-start">
		{#if resultsAnswer && resultsAnswer.length > 0}
			<div>
				<h2 class="text-color" style="color: #0C0C0C;">Resultados del cuestionario:</h2>
				<ul>
					{#each resultsAnswer as resultado}
						<div class="card-shadow p-4 rounded-xl" style="">
							<div class="inline-flex items-start">
								<div class="flex items-center gap-3">
									<div>
										<div
											class="w-7 h-7 rounded-full flex items-center justify-center"
											style="width: 20px;background-color: rgb(40, 114, 161);">
										</div>
									</div>
									<div
										class="text-color"
										style="
                                            color: #215273;
                                            font-family: &quot;Roboto&quot;, sans-serif;
                                            font-size: 15px;
                                            font-weight: bold;
                                        ">
										{resultado.questionText}
									</div>
								</div>
							</div>
						</div>
						{#if resultado.answerText != null || resultado.answerText != ""}
							<div
								class="card-shadow p-2 border rounded-xl col-span-3"
								style="background-color: rgb(230, 240, 248); border-color: rgb(93, 169, 211);">
								<div class="inline-flex items-start">
									<div
										class="flex items-center gap-3"
										style="
                                        display: flex;
                                    ">
										<div>
											<div
												class="w-7 h-7 rounded-full flex items-center justify-center"
												style="width: 20px;background-color: rgb(40, 114, 161);">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 16 16"
													fill="currentColor"
													class="w-4 h-4"
													style="color: rgb(230, 240, 248); width: 1em; height: 1em;">
													<path
														d="M10.618 10.26c-.361.223-.618.598-.618 1.022 0 .226-.142.43-.36.49A6.006 6.006 0 0 1 8 12c-.569 0-1.12-.08-1.64-.227a.504.504 0 0 1-.36-.491c0-.424-.257-.799-.618-1.021a5 5 0 1 1 5.235 0ZM6.867 13.415a.75.75 0 1 0-.225 1.483 9.065 9.065 0 0 0 2.716 0 .75.75 0 1 0-.225-1.483 7.563 7.563 0 0 1-2.266 0Z">
													</path>
												</svg>
											</div>
										</div>
										<div
											class="text-color"
											style="
                                            margin-left: 4%;
                                        ">
											{resultado.answerText == null ? "Sin respuesta" : resultado.answerText}
										</div>
									</div>
								</div>
							</div>
						{/if}
					{/each}
				</ul>
				<div
					class="flex my-4"
					style="
    display: flex;
    align-items: center;
    justify-content: center;
">
					<!-- <button class="btn_Previous" on:click={() => changePage(-1)} disabled={currentPage === 0}
						>Anterior</button>
					<button class="btn_Next" on:click={() => changePage(1)} disabled={currentPage === totalPages - 1}
						>Siguiente</button> -->
					<div class="d-flex justify-content-center">
						<button
							on:click={() => {
								modalViewAnswer = false;
							}}
							class="btnClose">Cerrar</button>
					</div>
				</div>
			</div>
		{:else if error !== null}
			<p>Ocurrió un error al obtener los resultados: {error}</p>
		{:else}
			<p>Cargando...</p>
		{/if}
	</div>
</LayoutModal>

<style>
	/* .my-tr {
      height: 5px !important;
    } */
	.amount-progress {
		accent-color: #215273;
		height: 50%;
		animation: progress-animation 0.7s ease-in forwards;
	}
	.refResult {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		text-align: center;
	}
	.resultado {
		margin: 1%;
	}
	.container-miIframe {
		width: 100%;
		height: 60vh;
		overflow: hidden; /* Oculta la barra de desplazamiento */
		position: relative; /* Para posicionar el iframe dentro del contenedor */
	}
	.container-miIframe iframe {
		width: calc(100% + 17px); /* Ajusta el ancho para compensar el espacio ocupado por la barra de desplazamiento */
		height: 100%;
		border: none; /* Elimina el borde del iframe */
		position: absolute; /* Posiciona el iframe */
		top: 0;
		left: 0;
	}
	/* .myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	} */
	/* .btnSolicitar {
      margin: 10px;
      width: 15%;
      height: 3.2rem;
      letter-spacing: 1px;
      color: #0C0C0C;
      border-radius: 10px;
      background-color: #fff;
      font-family: "Roboto", sans-serif;
    } */
	/* .my-tr td {
      padding: 0;
    } */
	.my-record {
		max-height: 75vh;
		min-height: 45vh;
	}
	.Title-detail {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 25px;
		text-align: center;
	}
	.title {
		font-size: 25px;
	}
	.Completado {
		width: auto;
		border-bottom: 1px solid rgb(2, 2, 2);
		height: 3.2rem;
	}
	/* .adjuntos {
      width: auto;
      height: 3.2rem;
    } */
	.Completado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
	}
	/* .adjuntos_p {
      color: #215273;
      font-family: "Roboto", sans-serif;
      font-size: 15px;
    } */
	.Creado {
		width: auto;
		border-bottom: 1px solid rgb(2, 2, 2);
		height: 3.2rem;
	}
	.Creado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
	}
	.conteiner {
		padding-left: 5%;
		width: 90%;
	}
	.requested {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.text_complete {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.createdByName,
	.dateCompleted,
	.dateReal {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.form-detail {
		margin: 1%;
		margin-left: 3%;
		width: 100%;
	}
	.column {
		flex-basis: 33.33%; /* Para dividir equitativamente en tres columnas */
		padding: 10px;
		box-sizing: border-box;
	}
</style>
