<script>
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import FileAttach from "../../../components/FileAttach.svelte";
	import { user } from "../../../helpers/storage/stores";
	import Loader from "../../../components/Loader.svelte";
	import { utils_files_add, generic_account_status_update } from "../../../helpers/apiBackend";
	import { onMount, afterUpdate } from "svelte";
	import { _ } from "svelte-i18n";
	export let params;
	let validateStatus = false;
	let idTypeAttachment = params.id;
	let fileCredential = {};
	let message = "";
	let loading = false;
	let fileData = [];
	let file_type = "validation_picture";
	let filecant = true;
	let showAlert = false;
	$user.Id = params.id;

	/**
	 * @description
	 */
	async function handleSuccess() {
		loading = true;
		console.log("handleSuccess inicio");
		let cant = fileCredential?.detail?.fileData?.length;
		fileData = fileCredential?.detail?.fileData;
		console.log({ fileData });
		if (cant == undefined) {
			console.log("handleSuccess cant == undefined");
			message = "Debe adjuntar frente y dorso del DNI";
		}
		console.log({ cant });
		if (cant > 2 || cant < 2) {
			console.log("handleSuccess cant > 2 || cant < 2");
			console.log({ cant });
			message = "Debe adjuntar solo 2 imagenes el frente y dorso del DNI";
		}
		if (cant == 2) {
			let processedFiles = 0;
			console.log({ fileCredential });
			fileData.forEach(async (element) => {
				console.log("fileCredential : ", element);
				await utils_files_add(element).then((data) => {
					console.log({ data });
					if (data.error) {
						message = data.message;
					} else {
						processedFiles++;
					}
				});
				if (processedFiles === 2) {
					console.log("Pending Verification");
					generic_account_status_update($user.Id, "Pending Verification").then((data) => {
						console.log({ data });
						if (data.error) {
							message = data.message;
						} else {
							$user.statusAccount = "Pending Verification";
							validateStatus = true;
						}
					});
				}
			});
		}

		console.log({ message });
		if (message) {
			showAlert = true;
			loading = false;

			setTimeout(() => {
				showAlert = false;
				message = "";
			}, 5000); // Oculta el mensaje después de 5 segundos
		}
		console.log("handleSuccess fin");
	}

	/**
	 * @description
	 * onMount
	 */
	 onMount(async () => {
		validateStatus = false;
		console.log("llega a PendingValidation");
		// if ($user.statusAccount == "Pending Verification") {
		// 	validateStatus = true;
		// }
	});
</script>

<section>
	<LayoutNav secure={true} rol="Patient">
		<div class="d-flex py-3 text-center border rounded shadow-sm my-3 bg-white">
			<div class="messageSolicitud">
				{#if !validateStatus}
					<p class="title">
						{$_("Pending.text_message_attach_id")}
						<br />
						{$_("Pending.text_message_attach_id_front_back")}
					</p>
					<p class="securityText">
						{$_("Pending.text_message_security")}
					</p>

					{#if loading && !validateStatus}
						<div class="p-3 text-center">
							<Loader text={true} loadingText={$_("Pending.text_send")} />
						</div>
					{/if}
					{#if !loading}
						<div class="textContainer">
							<div class="FileAttachContainer">
								<FileAttach
									{idTypeAttachment}
									multipleState={true}
									photoState={true}
									{file_type}
									on:dataFile={async (e) => {
										fileCredential = e.detail;
										fileData = fileCredential?.detail?.fileData;
										filecant = false;
									}} />
								<!-- <input type="file" accept="image/*" id="dniFileBack" /> -->
							</div>
						</div>
					{/if}
					{#if showAlert}
						<div class="alert">
							<p>{message}</p>
						</div>
					{/if}

					<div class="dniFrame">
						{#if filecant}
							<div class="dniSection">
								<div class="imgSolicitud">
									<img
										class="image-conteiner"
										src="/img/id_card_icon_124180.png"
										alt="login"
										width="60%"
										btn />
								</div>
							</div>
						{/if}
						{#if filecant}
							<div class="dniSection">
								<div class="imgSolicitud">
									<img
										class="image-conteiner"
										src="/img/id-card_icon-icons.com_69362.png"
										alt="login"
										width="60%" />
								</div>
							</div>
						{/if}
					</div>
					{#if !loading}
						<div class="textContainer">
							<div class="buttonContainer">
								<button
									class="btnBlue"
									on:click={() => {
										handleSuccess();
									}}>{$_("Pending.button_send")}</button>
							</div>
						</div>
					{/if}
				{/if}
				{#if validateStatus}
					<div class="validationMessage">
						<p class="title">
							{$_("Pending.text_message_attach_id")}
						</p>
						<div class="imgSolicitud">
							<img class="image-conteiner" src="/img/Frame 92.png" alt="login" />
						</div>
						<br />
						<p>{$_("Pending.text_message_validationF")}</p>
						<p>
							{$_("HelpForm.text_messageHelp")} <a href="mailto:info@rdcom.global">info@rdcom.global</a>
						</p>
					</div>
				{/if}
			</div>
		</div>
	</LayoutNav>
</section>

<style>
	.dniFrame {
		display: flex;
		justify-content: space-between;
	}

	.dniSection {
		width: 48%;
		margin: 0 1%;
	}
	.alert {
		position: fixed;
		top: 10px;
		right: 10px;
		background-color: #f8d7da;
		color: #721c24;
		padding: 10px 15px;
		border: 1px solid #f5c6cb;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		z-index: 1000;
	}
	.imgSolicitud {
		width: 100%;
		margin-top: 10px;
	}

	.messageSolicitud {
		width: 100%;
		padding-left: 15%;
		padding-right: 15%;
		box-sizing: border-box;
	}

	.title {
		color: #0C0C0C;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 20px;
		width: 100%;
		margin-bottom: 10px;
	}

	.textContainer {
		width: 100%;
		padding-left: 20px;
		margin-bottom: 10px;
	}

	.buttonContainer {
		width: 30%;
		text-align: center;
		margin: 0 auto;
	}

	/* input[type="file"] {
		display: none;
	}

	.buttonAttach {
		background-color: #0C0C0C;
		color: #fff;
		padding: 8px 12px;
		cursor: pointer;
		border-radius: 5px;
		display: inline-block;
		width: 100%;
	} */

	.securityText {
		color: #555;
		font-size: 14px;
		width: 100%;
	}

	.validationMessage {
		margin-top: 20px;
	}
</style>
