<script>
	import { onMount } from "svelte";
	import { writable } from "svelte/store";
	import {
		saveCaregiver,
		utils_files_update,
		patient_patient_update,
		professional_patients_add,
		utils_resources_get,
		ROLES,
		utils_files_add,
	} from "../../helpers/apiBackend";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	//import prov from "../../helpers/prov.json";
	import Loader from "../Loader.svelte";
	import { currentSession } from "../../helpers/storage/sessions";
	import { user, language } from "../../helpers/storage/stores";
	import { validateOnlyLetters, validateOnlyNumbers, validateOnlyDate } from "../../helpers/validation";
	import { replace } from "svelte-spa-router";
	import { Auth, a } from "aws-amplify";
	import { _ } from "svelte-i18n";
	import awsconfig from "../../aws-exports";
	import FileAttach from "../FileAttach.svelte";
	import { createEventDispatcher } from "svelte";
	//import MultiSelect from "../MultiSelect.svelte";
	//import { getProfilePhoto } from "../../helpers/helpers";
	//import SelectPlan from "../../Routes/Home/Doctor/SelectPlan.svelte";

	export let edit = false;

	Auth.configure(awsconfig);

	let photo = writable(null);
	let [apiDone, myConfirm, confirmPlan, assignCall] = [true, false, false, false];
	let selectedPlan = "";
	let errors = {};
	let success = false;
	let statusView = false;
	let showAlert = false;
	let rol = "";
	let codA1 = "";
	let myDiseases = "";
	let myDiseasesStatus = [];
	let dataArray = [];
	let patologias = [];
	let Phone1 = "";
	let codA2 = "";
	let Phone2 = "";
	let provincias = {};
	let coberturasList = [];
	let listLanguage = [];
	let countryList = [];
	let countryName = "";
	let countryId = "";
	let stateName = "";
	let covergaeName = "";
	let credentials = {};
	let idTypeAttachment = "";
	//let file_type = "Foto de perfil";
	let monitoringPlans = [];
	let finish = true;
	let errorMessages = [];
	//let languageExtra = "";
	let selectLanguage = [];
	let loadingModal = false;
	let questions = $_("AddPatient.select_gender");
	const complexItems = $_("AddPatient.select_status");
	let dispatch = createEventDispatcher();

	let langSelect = "es";

	if ($language == "es" || $language == "en") {
		console.log("ingreso");
		langSelect = $language;
	}

	async function onCountrySelect(event) {
		console.log({ event });
		const selectedCountry = event.target.value;
		console.log({ selectedCountry });
		const selectedCountryData = countryList.find((c) => c.label === selectedCountry);
		console.log({ selectedCountryData });
		if (selectedCountryData) {
			credentials.country = selectedCountryData;
			countryId = selectedCountryData.Id;
			countryName = selectedCountryData.label;
			await loadStates(countryId);
		}
	}

	async function onStateSelect(event) {
		console.log({ event });
		const selectedState = event.target.value;
		console.log({ selectedState });
		const selectedStateData = provincias.find((c) => c.label === selectedState);
		console.log({ selectedStateData });
		if (selectedStateData) {
			credentials.BillingState = selectedStateData;
		}
	}

	async function onCoverageSelect(event) {
		console.log("salud", { event });
		const selectedCoverage = event.target.value;
		const selectedCoverageData = coberturasList.find((c) => c.label === selectedCoverage);
		if (selectedCoverageData) {
			credentials.coverageId = selectedCoverageData.value;
			credentials.Cobertura_Salud__c = selectedCoverageData.value;
			console.log("Salud", credentials.coverageId);
		}
	}

	/**
	 * @description
	 */
	const validate = () => {
		errors = {};
		let message = "Este campo es obligatorio";
		if (!credentials.FirstName) {
			errors.FirstName = message;
		}
		if (!credentials.LastName) {
			errors.LastName = message;
		}
		if (!credentials.HealthCloudGA__Gender__pc) {
			errors.HealthCloudGA__Gender__pc = message;
		}
		if (!credentials.DNI__c) {
			errors.DNI__c = message;
		}
		if (!credentials.PersonMobilePhone) {
			errors.PersonMobilePhone = message;
		}
		if (!credentials.BillingState) {
			errors.BillingState = message;
		}
		if (!credentials.Cobertura_Salud__c) {
			errors.prepaid = message;
		}
		// if (!credentials.Nro_de_afiliado__c) {
		// 	errors.Nro_de_afiliado__c = message;
		// }
		if (!credentials.PersonBirthdate) {
			errors.PersonBirthdate = message;
		}
		if (!credentials.country) {
			errors.country = message;
		}

		if (credentials.diseases.length == 0) {
			errors.diseases = message;
		}
		if (!credentials.selectedPlan) {
			errors.selectedPlan = message;
		}
		// if (!credentials.Nro_de_afiliado__c && rol == ROLES.patient) {
		// 	errors.Nro_de_afiliado__c = message;
		// }
		if (!credentials.R1_streetName__c) {
			errors.addres = message;
		}
		if (!credentials.R1_streetNumber__c) {
			errors.addresNumber = message;
		}
		if (!credentials.Cobertura_Salud__c && rol === ROLES.patient) {
			console.log({ rol });
			console.log(ROLES.patient);
			errors.Cobertura_Salud__c = "Este campo es obligatorio";
		}
		if (credentials.PersonBirthdate == "") {
			document.getElementById("startPersonBirthdate").style.color = "red";
			errors.PersonBirthdate = "Este campo es obligatorio";
		}
		console.log({ errors });
		if (Object.keys(errors).length > 0) {
			return false;
		}
		return true;
	};

	/**
	 * @description
	 * Update user status
	 */
	async function updateUserStatus(status) {
		let user;
		await Auth.currentAuthenticatedUser()
			.then(async (resultado) => {
				user = resultado;
				await Auth.updateUserAttributes(user, {
					"custom:StatusReg": status,
				});
			})
			.catch((err) => {
				user = $currentSession;
			});
	}

	/**
	 * @description
	 */
	const handleSubmit = async () => {
		console.log({ selectedPlan });
		credentials.diseases = dataArray;
		credentials.doctorId = $user.Id;
		credentials.selectedPlan = selectedPlan;
		let status = [];
		if (codA1 != "" && Phone1 != "") {
			credentials.Phone = codA1 + "-" + Phone1;
		}
		if (codA2 != "" && Phone2 != "") {
			credentials.PersonMobilePhone = codA2 + "-" + Phone2;
		}
		console.log("Add patients", { credentials });

		if (validate()) {
			loadingModal = true;
			let Id = "";
			let exito = false;

			try {
				const data = await professional_patients_add(credentials);
				if (data.statusCode === 200) {
					console.log("Rta Add", data);
					Id = data.data.Id;
					console.log("professional_patients_add data: ", { data });
					console.log(Id);

					if (credentials.hasOwnProperty("fileAttFotoPerfil") && Id) {
						let fileAttFotoPerfil = credentials.fileAttFotoPerfil.detail.fileData;
						fileAttFotoPerfil.forEach(async (element) => {
							element.creator = Id;
							element.attachment = Id;
							console.log("fileAttFotoPerfil : ", element);
							await utils_files_add(element).then((data) => {
								console.log("utils_files_add : ", data);
								status.push(true);
								window.location.reload(); // Recargar la página en caso de timeout
							});
						});
					} else {
						status.push("none");
						window.location.reload(); 
					}
				} else {
					if (data.statusCode === 504) {
						console.log("Timeout, recargando la página...");
						window.location.reload(); // Recargar la página en caso de 504
					}
					errorMessages = true;
					showAlert = true;
					console.log(data.message);
					loadingModal = false;
					setTimeout(() => {
						showAlert = false;
					}, 5000);
				}
			} catch (error) {
				window.location.reload(); // Recargar la página en caso de timeout
			}
		}
	};

	/**
	 * @description
	 */
	function addPathology(myDiseases, myDiseasesStatus) {
		console.log({ myDiseases });
		if (myDiseasesStatus != "" && myDiseases != "") {
			const nuevoObjeto = {
				label: myDiseases.label,
				code: myDiseases.code,
				Estado_patologia__c: myDiseasesStatus,
			};
			let condi = dataArray.find((elem) => elem.label == nuevoObjeto.label);
			if (!condi) {
				dataArray = [...dataArray, nuevoObjeto];
			}
			console.log({ dataArray });
		} else {
			errorMessages = true;
			setTimeout(function () {
				errorMessages = false;
			}, 3000);
		}
		document.getElementById("patologStatus").value = "";
		document.getElementById("patologID").value = "";
		finish = false;
	}

	/**
	 * @description
	 */
	function deletePathology(datamyDiseases) {
		console.log("delete", datamyDiseases);
		console.log("patologID", document.getElementById("patologID"));
		//console.log("delete", datamyDiseases);
		let result = dataArray.find((elem) => elem.label == datamyDiseases);
		if (result) {
			//document.getElementById("patologStatus").value = "";
			document.getElementById("patologID").text = datamyDiseases;
			//var e = document.getElementById("patologID");
			let newarray = [];
			dataArray.forEach((item) => {
				if (item.label != result.label) {
					newarray.push(item);
				}
			});
			dataArray = newarray;
		}
		console.log("result", result);
		if (dataArray.length != 0) {
			finish = false;
		} else {
			finish = true;
		}
	}

	/**
	 * @description
	 */
	function handleFileChange(e) {
		console.log("handleFileChange: ", e);
		let myObjArray = Object.entries(e.detail);
		let r = myObjArray[0];
		console.log({ r });
		let picture = document.getElementById("photoID");
		picture.setAttribute("src", r[1]?.fileData[0]?.urlPhoto);
		console.log({ picture });
		picture.src = r[1]?.fileData[0]?.urlPhoto;
	}

	/**
	 * @description
	 */
	async function handlePhoto() {
		document.getElementById("photoID").src = photo;
		document.getElementById("fileInput").click();
	}
	//let value;
	async function loadStates(countryId) {
		provincias = [];
		setSelectDisabled(true);
		const resultado = await utils_resources_get("state", langSelect, countryId);
		const filteredData = resultado.filter((item) => item.Id_country === countryId);
		provincias = filteredData;
		if (provincias.length) {
			setSelectDisabled(false);
		}
	}

	function setSelectDisabled(disabled) {
		//console.log({ disabled });
		const selectElement = document.getElementById("billingState");
		if (selectElement) {
			console.log({ disabled });
			selectElement.disabled = disabled;
		}
	}
	/**
	 * @description
	 * onMount
	 */
	onMount(async () => {
		if ($currentSession == {}) {
			replace("/");
		} else {
			setSelectDisabled(true);
			credentials.listLanguage = [];
			credentials.doctorId = $user.Id;
			credentials.country = "Israel";
			await loadStates();
			errors = {};
			success = false;
			photo = "./img/perfil_none.png";
			console.log("utils_resources_get - AddPatient");
			countryList = await utils_resources_get("country", $language);
			coberturasList = await utils_resources_get("prepaidMedicine", $language);
			monitoringPlans = await utils_resources_get("monitoringPlans", $language);
			listLanguage = await utils_resources_get("languages", $language);
			patologias = await utils_resources_get("pathologies", $language);
		}
		setTimeout(() => {
			statusView = true;
		}, 7000);
	});
</script>

<!-- --------------------------------------------------------------------------------------------------------- -->
<!-- --------------------------------------------------------------------------------------------------------- -->
<!-- --------------------------------------------------------------------------------------------------------- -->
<div class="register-info-container">
	{#if coberturasList.length != 0 && listLanguage.length != 0 && !loadingModal}
		<form on:submit|preventDefault={handleSubmit}>
			<div class="container">
				{#if showAlert}
					<div class="alert">
						<p>{$_("AddPatient.text_error_message")}</p>
					</div>
				{/if}
				<!-- Personal Information -->
				<div class="border shadow-sm m-3 p-4 rounded bg-white">
					<h1 class="title">{$_("AddPatient.text_title_information")}</h1>
					<div class="dataPersonaldiv row">
						<!-- {#if !edit} -->
						<div class="img_perfil">
							<div class="perfilIMG my-3">
								<img
									id="photoID"
									class="imgPerfil cursor-pointer"
									style="width:150px; height:150px;top:8; left:1;"
									src={photo}
									on:click={handlePhoto}
									alt="" />
								<img
									id="photoID"
									class="imgPerfil cursor-pointer"
									style="width:70px; height:65px; transform: translate(55px, -50px);"
									src="./img/addprofile.jpg"
									on:click={handlePhoto}
									alt="" />

								<!-- svelte-ignore component-name-lowercase -->

								<FileAttach
									{idTypeAttachment}
									multipleState={false}
									photoState={false}
									formatState={true}
									file_type="Foto de perfil"
									on:dataFile={async (e) => {
										credentials.fileAttFotoPerfil = e.detail;
										console.log({ e });
										handleFileChange(e);
									}} />
							</div>

							{#if errors.photo}
								<small class="error">{errors.photo}</small>
							{/if}
						</div>
						<!-- {/if} -->
						<div class="data-personal d-flex">
							<div class="row">
								<!-- FirstName -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("AddPatient.text_name")}</small>
									<small class="error">*</small>
									<input
										type="text"
										class="form-control"
										bind:value={credentials.FirstName}
										on:keypress={validateOnlyLetters} />
									{#if errors.FirstName}
										<small class="error">{errors.FirstName}</small>
									{/if}
								</div>
								<!-- LastName -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("AddPatient.text_lastname")}</small><small
										class="error">*</small>
									<input
										type="text"
										class="form-control"
										bind:value={credentials.LastName}
										on:keypress={validateOnlyLetters} />
									{#if errors.LastName}
										<small class="error">{errors.LastName}</small>
									{/if}
								</div>
							</div>
							<div class="row">
								<!-- DNI -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("AddPatient.credential")}</small><small class="error"
										>*</small>
									<input
										class="input-log"
										type="text"
										placeholder="xx.xxx.xxx"
										bind:value={credentials.DNI__c}
										on:keypress={validateOnlyNumbers}
										disabled={edit} />
									{#if errors.DNI__c}
										<small class="error">{errors.DNI__c}</small>
									{/if}
								</div>
								<!-- Birthday -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("AddPatient.text_birthdate")}</small><small
										class="error">*</small>
									<input
										class="input-log"
										type="date"
										id="startPersonBirthdate"
										pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
										name="trip-start"
										bind:value={credentials.PersonBirthdate}
										on:blur={() => {
											credentials.PersonBirthdate = validateOnlyDate(credentials.PersonBirthdate);
										}} />
									{#if errors.PersonBirthdate}
										<small class="error">{errors.PersonBirthdate}</small>
									{/if}
								</div>
							</div>
							<div class="" style="width: 106%;">
								<!-- Gender -->
								<div>
									<small class="text-muted">{$_("AddPatient.text_gender")}</small><small class="error"
										>*</small>
									<select class="gender" bind:value={credentials.HealthCloudGA__Gender__pc}>
										{#each questions as question}
											<option value={question.text}>
												{question.text}
											</option>
										{/each}
									</select>
									{#if errors.HealthCloudGA__Gender__pc}
										<small class="error">{errors.HealthCloudGA__Gender__pc}</small>
									{/if}
								</div>
							</div>
							<div class="row">
								<!-- Phonenumber -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("AddPatient.text_phone")}</small>
									<div>
										<input
											class="codigoArea"
											type="text"
											bind:value={codA1}
											on:keypress={validateOnlyNumbers}
											onpaste="return false;" />
										<small class="text-muted"> - </small>
										<input
											type="text"
											class="form-control-cel"
											bind:value={Phone1}
											on:keypress={validateOnlyNumbers}
											onpaste="return false;" />
									</div>
								</div>
								<!-- Cellphone -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("AddPatient.text_cell")}</small><small class="error"
										>*</small>
									<div>
										<input
											class="codigoArea"
											type="text"
											bind:value={codA2}
											on:keypress={validateOnlyNumbers}
											onpaste="return false;" />
										<small class="text-muted"> - </small>
										<input
											type="text"
											class="form-control-cel"
											bind:value={Phone2}
											on:keypress={validateOnlyNumbers}
											onpaste="return false;" />
									</div>
									{#if errors.PersonMobilePhone}
										<p class="error">{errors.PersonMobilePhone}</p>
									{/if}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Address information -->
				<div class="border shadow-sm m-3 p-4 rounded bg-white">
					<h1 class="title">{$_("AddPatient.text_title_addres")}</h1>
					<div class="row">
						<div class="data-domicilio d-flex">
							<div class="domicilioID">
								<!-- Street -->
								<div class="col-sm-7">
									<small class="text-muted">{$_("AddPatient.text_addres")}</small><small class="error"
										>*</small>
									<input type="text" class="form-control" bind:value={credentials.R1_streetName__c} />
									{#if errors.addres}
										<small class="error">{errors.addres}</small>
									{/if}
								</div>
								<!-- Number -->
								<div class="col-sm-2">
									<small class="text-muted">{$_("AddPatient.text_addresNumber")}</small><small
										class="error">*</small>
									<input
										type="text"
										class="form-control"
										bind:value={credentials.R1_streetNumber__c} />
									{#if errors.addresNumber}
										<small class="error">{errors.addresNumber}</small>
									{/if}
								</div>
								<!-- Aparment -->
								<div class="col-sm-3">
									<small class="text-muted">{$_("AddPatient.text_addresApartment")}</small>
									<input
										type="text"
										class="form-control"
										bind:value={credentials.R1_streetDetail__c} />
									{#if errors.addres}
										<small class="error" />
									{/if}
								</div>
							</div>

							<div class="paisID">
								<!-- Country -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_country")}</small><small
										class="error">*</small>
									<select class="gender" bind:value={countryName} on:change={onCountrySelect}>
										{#each countryList as country}
											<option value={country.label}>
												{country.label}
											</option>
										{/each}
									</select>
									<!-- <input type="text" class="form-control" bind:value={credentials.country} /> -->
									{#if errors.country}
										<p class="error">{errors.country}</p>
									{/if}
								</div>
								<!-- City / State -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_BillingState")}</small><small
										class="error">*</small>
									<select
										id="billingState"
										class="gender"
										disabled
										bind:value={stateName}
										on:change={onStateSelect}>
										{#each provincias as provincia}
											<option value={provincia.label}>
												{provincia.label}
											</option>
										{/each}
									</select>
									{#if errors.BillingState}
										<p class="error">{errors.BillingState}</p>
									{/if}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Prepaid medicine information -->
				<div class="border shadow-sm m-3 p-4 rounded bg-white">
					<h1 class="title">{$_("AddPatient.text_title_Coverage_data")}</h1>
					<div>
						<div class="data-cobertura d-flex">
							<div class="coberturaID">
								<!-- Prepaid_name -->
								<div class="col-sm-8">
									<small class="text-muted">{$_("AddPatient.text_Coverage_data")}</small>
									{#if covergaeName !== 'Particular'} 
										<small class="error">*</small>
									{/if}
									<select class="gender" bind:value={covergaeName} on:change={onCoverageSelect}>
										{#each coberturasList as prepaid}
											<option value={prepaid.label}>
												{prepaid.label}
											</option>
										{/each}
									</select>
									{#if errors.prepaid}
										<p class="error">{errors.prepaid}</p>
									{/if}
								</div>
								<!-- Prepaid_number -->
								<div class="col-sm-4">
									<small class="text-muted">{$_("AddPatient.text_affiliateNumber")}</small>
									<input
										type="text"
										class="form-control"
										on:keypress={validateOnlyNumbers}
										bind:value={credentials.Nro_de_afiliado__c}/>										
									{#if covergaeName !== 'Particular' && errors.Nro_de_afiliado__c}
										<p class="error">{errors.Nro_de_afiliado__c}</p>
									{/if}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="border shadow-sm m-3 p-4 rounded bg-white">
					<h1 class="title">{$_("AddPatient.text_title_pathology")}</h1>
					<div class="row">
						<div id="patologiaID" class="data-patologia d-flex">
							<!-- Diagnostico -->
							<div class="diagnostico" style="d-flex justify-content-center">
								<small class="titulo">{$_("AddPatient.text_diagnosis")}:</small>
								<select bind:value={myDiseases} class="form-control" name="" id="patologID">
									{#if Array.isArray(patologias)}
										{#each patologias as myDisease}
											<option value={myDisease}>{myDisease.label}</option>
										{/each}
									{/if}
								</select>
							</div>

							<div class="statusDi" style="d-flex justify-content-center">
								<small class="titulo">{$_("AddPatient.text_State")}</small>
								<select
									bind:value={myDiseasesStatus}
									on:change={addPathology(myDiseases, myDiseasesStatus)}
									class="form-control"
									name=""
									id="patologStatus">
									{#if Array.isArray(complexItems)}
										{#each complexItems as myDiseaseState}
											<option value={myDiseaseState.value}>{myDiseaseState.label}</option>
										{/each}
									{/if}
								</select>
							</div>
						</div>
						{#if errors.diseases}
							<small class="error" style="margin-left: 3%;">{errors.diseases}</small>
						{/if}
					</div>
					{#if dataArray.length > 0}
						<div id="tabla-patologias">
							<table style="width: 100%;">
								<thead>
									<tr>
										<th>{$_("AddPatient.text_th_pathology")}</th>
										<th>{$_("AddPatient.text_th_State")}</th>
										<th>{$_("AddPatient.text_th_action")}</th>
									</tr>
								</thead>
								<tbody>
									{#each dataArray as diseases}
										<tr>
											<td>{diseases.label}</td>
											{#if Array.isArray(complexItems)}
												{#each complexItems as myDiseaseState}
													{#if myDiseaseState.value == diseases.Estado_patologia__c}
														<td>{myDiseaseState.label}</td>
													{/if}
												{/each}
											{/if}

											<td
												><button
													class="btnDelete"
													on:click={() => {
														deletePathology(diseases.label);
													}}
													>{$_("AddPatient.button_delete")}
												</button></td>
										</tr>
									{/each}
								</tbody>
							</table>
						</div>
						<br />
						<div class="adjuntos" style="d-flex justify-content-center">
							<small class="titulo">{$_("AddPatient.text_attach")}:</small>
							<FileAttach
								{idTypeAttachment}
								file_type="Certificado patologia"
								multipleState={true}
								photoState={true}
								on:dataFile={async (e) => {
									credentials.fileAttPatologia = e.detail;
								}} />
							<!-- svelte-ignore a11y-missing-attribute -->
						</div>
					{/if}
					<!-- File -->
				</div>
				<div class="border shadow-sm m-3 p-4 rounded bg-white">
					<h1 class="title">{$_("AddPatient.text_title_plan")}</h1>
					{#if monitoringPlans.length == 0}
						<div class="">
							<Loader text={false} />
							<p class="text-muted">{$_("AddPatient.text_not_plan")}</p>
						</div>
					{:else if assignCall}
						<div class="">
							<Loader text={false} />
							<p class="text-muted">{$_("AddPatient.text_loading_plan")}</p>
						</div>
					{:else}
						<div class="">
							<div class="my-4">
								<!-- Monitoring Plans List -->
								{#if monitoringPlans.length == 0 && !apiDone}
									<Loader text={false} />
									<p class="text-muted">{$_("AddPatient.loading")}</p>
								{:else if monitoringPlans.length == 0 && apiDone}
									<p class="text-muted">{$_("AddPatient.text_not_plan")}</p>
								{:else}
									<p class="m-0">{$_("AddPatient.text_select_plan")}</p>
									<select
										bind:value={selectedPlan}
										class="form-control rounded-0"
										name="plan-select"
										id="plan-select">
										<option value="none"
											><span class="text-muted">{$_("AddPatient.text_select")}... </span></option>
										{#each monitoringPlans as plan}
											<option class="py-0" value={plan.Id}>{plan.label}</option>
										{/each}
									</select>
								{/if}
							</div>
						</div>
					{/if}
					{#if errors.selectedPlan}
						<small class="error">{errors.selectedPlan}</small>
					{/if}
				</div>
			</div>
			<div class="button">
				<button
					type="button"
					class="btnWhite"
					on:click={() => {
						dispatch("closeModal");
					}}>
					{$_("AddPatient.button_return")}
				</button>
				<!-- Submit -->
				<button id="nextButton" class="btnBlue">{$_("AddPatient.button_save")}</button>
			</div>
		</form>
	{:else if !edit}
		<div class="text-center py-3">
			<Loader text={false} />
			<p class="text-muted">{$_("AddPatient.loading")}</p>
		</div>
	{:else if edit}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("AddPatient.save")}</p>
		</div>
	{/if}
</div>

<!-- --------------------------------------------------------------------------------------------------------- -->
<!-- --------------------------------------------------------------------------------------------------------- -->

<!-- Modal Success -->
<!-- <LayoutModal tam="30" isOpenModal={modalError}>
	<div class="p-2 text-center">
		<label for="">{$_("AddPatient.text_error_message")}</label>
		<div>
			<button
				on:click={() => {
					modalError = false;
				}}>{$_("AddPatient.button_ok")}</button>
		</div>
	</div>
</LayoutModal> -->

<!-- --------------------------------------------------------------------------------------------------------- -->
<style>
	.imgPerfil {
		width: 150px;
		border-radius: 50%;
		background-size: 120%;
	}
	.alert {
		position: fixed;
		top: 10px;
		right: 10px;
		background-color: #f8d7da;
		color: #721c24;
		padding: 10px 15px;
		border: 1px solid #f5c6cb;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		z-index: 1000;
	}
	.button {
		text-align: right;
		width: 95%;
		margin-block: 5%;
	}
	.input-log {
		width: 100%;
	}

	.data-personal {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: column;
		align-content: center;
		margin-left: 5%;
		margin-right: 5%;
		text-align: initial;
	}
	.img_perfil {
		width: 35%;
	}

	.dataPersonaldiv {
		flex-wrap: initial;
		text-align: center;
	}
	.data-domicilio {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: column;
		align-content: space-between;
		align-items: baseline;
		width: 100%;
	}
	.gender {
		width: 100%;
	}

	.btnDelete {
		letter-spacing: 1px;
		color: #fff;
		border-radius: 10px;
		background-color: #fa0900;
	}
	.codigoArea {
		width: 30%;
	}
	.form-control-cel {
		width: 60%;
	}
	/* .form-control-tel {
		width: 60%;
	} */
	.domicilioID {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
	.paisID {
		display: flex;
		width: 100%;
	}
	.coberturaID {
		display: flex;
		width: 100%;
	}
	.btnBlue {
		height: 45px;
		width: 90px;
		text-align-last: center;
	}
	.statusDi {
		width: 30%;
		margin: 1%;
	}
	.btnWhite {
		height: 45px;
		width: 90px;
		text-align-last: center;
	}
	.data-cobertura {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: column;
		align-content: space-between;
		align-items: baseline;
		width: 100%;
	}
	.register-info-container {
		margin: 3%;
	}
	.data-patologia {
		width: 100%;
		margin: 2%;
	}
	.title {
		font-size: 20px;
		text-align: center;
		color: #0c0c0c;
	}
	.form-control {
		/* padding: 10px; */
		/* margin-bottom: 10px; */
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.perfilIMG {
		margin-left: 5%;
		text-align: center;
	}
	.diagnostico {
		width: 100%;
		color: #0c0c0c;
		font-weight: 600;
		margin: 1%;
	}
	.error {
		color: red;
	}
	/* .success {
		color: green;
	} */
	@media screen and (max-width: 1000px) {
		.row {
			display: flex;
			flex-wrap: wrap;
		}
		.perfilIMG {
			text-align: center;
		}
		.img_perfil {
			width: 100%;
		}
		.container {
			width: 100%;
			margin-right: auto;
			margin-left: auto;
		}
		.domicilioID {
			display: contents;
			width: 100%;
		}
		.paisID {
			display: contents;
			width: 100%;
		}
		.coberturaID {
			display: contents;
			width: 100%;
		}
		.data-personal {
			display: flex !important;
			flex-wrap: wrap;
			justify-content: space-between;
			flex-direction: column;
			align-content: space-between;
			align-items: initial;
			margin-left: 0%;
		}
		.button {
			text-align: center;
			margin-block: 5%;
		}
	}
	@media screen and (max-width: 1200px) {
		.button {
			text-align: center;
			margin-block: 5%;
		}
	}
</style>
