import { generic_account_get } from "./apiBackend.js";
import { user,dataPatient } from "./storage/stores.js";
import { get } from "svelte/store";
import { _ } from "svelte-i18n";

/**
 * @description
 */
export const getErrorsMessages = (errors, posibles) => {
	console.log({ errors });
	console.log({ posibles });
	let errorMessages = {};
	errors.forEach((error) => {
		posibles.forEach((posible) => {
			if (error.includes(posible)) errorMessages[posible] = "";
		});
	});
	return errorMessages;
};

/**
 * @description
 */
export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @description
 */
export const filterArray = (searchterm, myList) => {
	console.log({ searchterm });
	console.log({ myList });
	let newLsit = [];
	if (searchterm == "") {
		newLsit = myList;
	} else {
		try {
			newLsit = myList.filter((record) => {
				return record.Cuenta__r.Name.toLowerCase().includes(searchterm.toLowerCase());
			});
			console.log("filtrando: ", newLsit);
		} catch (error) {
			newLsit = [];
		}
	}
	return newLsit;
};

/**
 * @description
 * [TEST]
 * - Replaced hardcoded values with constant ROLES
 * from apiBackend
 */
export const getUserInfo = async (rol, username, dni = "", lang = "es") => {
	console.log({ rol, username, dni });
	let resultado = await generic_account_get(username, "", rol, dni, lang);
	console.log("Resultado del generic_account_get - helpers.js", resultado)

	user.set(resultado.data.data[0]);
	dataPatient.set(resultado);
		
	if (Object.keys(get(user)).length == 0) {
		console.log("1");
		user.set({});
		return false;
	}
	return true;
};
/**
 * @description
 * [TEST]
 * - Replaced hardcoded values with constant ROLES
 * from apiBackend
 */
export const getEdad = async (dateString) => {
	console.log("getEdad", dateString);
	let hoy = new Date();
	let [day, month, year] = dateString.split("-");
	let correctedDateString = `${year}-${month}-${day}`;
	let fechaNacimiento = new Date(correctedDateString);
	console.log("getEdad", dateString);
	let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
	let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
	if (diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
		edad--;
	}
	return edad;
};

/**
 * @description
 * [TEST]
 * -
 * from apiBackend
 */
export const getProfilePhoto = async (archivos_s3) => {
	console.log({ archivos_s3 });
	let status = false;
	let link = "";
	let fileAd = archivos_s3.records;
	fileAd.forEach((element) => {
		console.log({ element });
		if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
			link = element.Link__c;
			status = true;
		}
	});

	return { status, link };
};

/**
 * @description
 * [formatDate]
 * -
 *
 */
export const formatDate = (date, language) => {
	if (date != null) {
		const formatos = {
			es: { day: "2-digit", month: "2-digit", year: "numeric" },
			en: { month: "2-digit", day: "2-digit", year: "numeric" },
		};
		const opcionesFecha = formatos[language];

		if (opcionesFecha) {
			let parsedDate;

			// Intenta analizar la fecha en formato "dd/mm/yyyy"
			const parts = date.split("/");
			if (parts.length === 3) {
				parsedDate = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`);
			}

			// Si no se pudo analizar en "dd/mm/yyyy", intenta en "yyyy-mm-dd"
			if (!parsedDate) {
				const [year, month, day] = date.split("-");
				parsedDate = new Date(`${year}/${month}/${day}`);
			}

			if (!isNaN(parsedDate)) {
				let result = parsedDate.toLocaleDateString(language, opcionesFecha);
				return result;
			} else {
				console.log("Formato de fecha no válido");
			}
		} else {
			console.log("Idioma no soportado");
		}
	}
	return null;
};

// export const formatDate = (date, language) => {
// 	console.log({ date });
// 	console.log({ language });
// 	const formatos = {
// 		es: { day: "2-digit", month: "2-digit", year: "numeric" },
// 		en: { month: "2-digit", day: "2-digit", year: "numeric" },
// 	};
// 	const opcionesFecha = formatos[language];
// 	if (opcionesFecha) {
// 		// Divide la fecha en día, mes y año y agrega ceros si es necesario
// 		const [day, month, year] = date.split("/").map((component) => component.padStart(2, "0"));
// 		date = `${month}/${day}/${year}`;

// 		console.log({ date });
// 		console.log({ language });
// 		let result = new Date(date).toLocaleDateString(language, opcionesFecha);
// 		console.log({ result });
// 		return result;
// 	} else {
// 		console.log("Idioma no soportado");
// 		return null;
// 	}
// };
