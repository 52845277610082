<!-- 
    IMPORTANT - TO USE ON PATIENTS PLATFORM
 -->
<script>
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../components/Loader.svelte";
	import AddDetailsMedications from "../../components/addDetailsMedications.svelte";
	import { generic_medication_delete } from "../../helpers/apiBackend";
	import { language } from "../../helpers/storage/stores";
	import { formatDate } from "../../helpers/helpers";
	import { _ } from "svelte-i18n";
	//import { loadingBar } from "aws-amplify";

	const dispatch = createEventDispatcher();
	export let theRecord = undefined;
	//console.log({ theRecord });
	let collapsed = true;
	let loadingDelete = false;
	let modaladd = false;
	let modaldelete = false;
	let name = "";
	let patientId;
	let planId;
	let detailMedication = [];
	let actividadCabeceraId;
	let structMed = false;

	const deleteHeadM = async (id) => {
		loadingDelete = true;
		console.log("Llamar a Borrar : ", id);
		await generic_medication_delete(id).then((result) => {
			if (result.statusCode == 200) {
				window.location.reload();
			} else {
				loadingDelete = false;
				console.log("error");
			}
		});
	};
	function handleCloseModal() {
		console.log("handleCloseModal");
		modaladd = !modaladd;
	}

	onMount(async () => {
		console.log(theRecord);
		detailMedication = theRecord.details;
		actividadCabeceraId = theRecord.medicationId;
		planId = theRecord.careplanId;
		patientId = theRecord.patientId;
		name = theRecord.drug;
		structMed = true;
		console.log({ detailMedication });
	});
</script>

<!-- VISTA DESDE EL PACIENTE Citas/Entrevistas-->

<section>
	{#if theRecord["active"]}
		<div class="myCustom-box">
			<div
				class="{collapsed
					? 'myCustom-header-closed'
					: 'myCustom-header-open'} d-flex justify-content-between align-items-center p-2">
				<div class="d-flex myCustom-name">
					{#if structMed}
						{#if theRecord.hasOwnProperty("Actividad_Detalles__r") && theRecord.Actividad_Detalles__r.totalSize > 0}
							<i class="gg-remove" style="margin-right: 2%; color:lightslategrey" />
						{:else}
							<i
								class="gg-remove"
								on:click={() => {
									modaldelete = true;
								}}
								style="margin-right: 2%;" />
						{/if}
					{/if}
					<p class="m-0 variableA">{name}</p>
					{#if theRecord.Modalidad__c}
						<p class="m-0 modalidad">({theRecord.Modalidad__c ? theRecord.Modalidad__c : ""})</p>
					{/if}
					&nbsp;
				</div>

				<!-- <div>
					{#if theRecord.hasOwnProperty("Picklist_Frequency__r") && theRecord["Picklist_Frequency__r"].hasOwnProperty("Name__c")}
						<p class="variableA m-0">{theRecord.Picklist_Frequency__r.Name__c}</p>
					{:else}
						<p class="variableA m-0">{$_("List_Records_prof.text_frequency")}</p>
					{/if}
				</div> -->

				<!-- Activo -->
				{#if theRecord.active}
					<div id="circle" class="circle" style="background-color: green;" />
				{:else}
					<div id="circle" class="circle" style="background-color:grey;" />
				{/if}

				<div
					on:click={() => {
						collapsed = !collapsed;
					}}
					class="d-flex myCustom-collapse"
					data-toggle="collapse"
					data-target="#{actividadCabeceraId}"
					aria-expanded="false"
					aria-controls={actividadCabeceraId}>
					<!-- <p class="m-0 mx-2">Mas</p> -->
					<i class="gg-arrow-down-o" />
				</div>
			</div>

			<!-- Detail -->
			<div class="collapse" id={actividadCabeceraId}>
				<table class="myCustom-table">
					<thead>
						<tr>
							<!-- Detail Medicamento -->
							<th>{$_("List_Record_Medication_patient.text_date")}</th>
							<th>{$_("List_Record_Medication_patient.text_dose")}</th>
							<th>{$_("List_Record_Medication_patient.text_frequency_info")} </th>
							<th>{$_("List_Record_Medication_patient.text_Order")} </th>
							{#if theRecord["active"]}
								<th
									><button
										on:click={() => {
											modaladd = true;
										}}
										class="btnWhite">{$_("List_Record_Medication_patient.button_add")}</button
									></th>
							{/if}
						</tr>
					</thead>
					<tbody class="py-2">
						{#if detailMedication.length > 0}
							{#each detailMedication as myDetail}
								<tr class="bg-white py-2" style="height:35px;">
									<td>
										{#if myDetail.hasOwnProperty("detailDate")}
											<span>{formatDate(myDetail["detailDate"], $language)}</span>
										{:else}
											<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
										{/if}
									</td>

									<td>
										{#if myDetail.hasOwnProperty("dose")}
											<span>{myDetail["dose"]}</span>
										{:else}
											<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
										{/if}
									</td>

									<td>
										{#if myDetail.hasOwnProperty("frecuency") && myDetail["frecuency"] != null}
											<span>{myDetail["frecuency"]}</span>
										{:else}
											<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
										{/if}
									</td>
									<td>
										{#if myDetail.hasOwnProperty("Link__c")}
											<a href={myDetail.Link__c} download
												>{$_("List_Records_prof.button_download")}</a>
										{:else}
											<span class="text-muted">{$_("List_Records_prof.text_Undefined")}</span>
										{/if}
									</td>

									<td>
										<button
											on:click={() => {
												dispatch("editDetail", { myDetail });
											}}
											class="btnBlue"
											>{$_("List_Record_Medication_patient.button_detail")}</button>
									</td>
								</tr>
							{:else}
								<tr>
									<td>
										<span>{$_("List_Record_Medication_patient.not_medication")}</span>
									</td>
								</tr>
							{/each}
							<!-- {#if theRecord.hasOwnProperty("Actividad_Detalles__r")}
							{#if theRecord["Actividad_Detalles__r"].hasOwnProperty("records")}
								{#if theRecord["Actividad_Detalles__r"]["records"].length > 0}
									{#each theRecord["Actividad_Detalles__r"]["records"] as myDetail}
										<tr class="bg-white py-2" style="height:35px;">
											{#if !structMed}
												
												<td>
													{#if myDetail.hasOwnProperty("Creado_Por__r")}
														{#if myDetail["Creado_Por__r"].hasOwnProperty("Name")}
															<span>{myDetail["Creado_Por__r"]["Name"]}</span>
														{:else}
															<span class="text-muted"
																>{$_("List_Record_Medication_patient.text_Undefined")}</span>
														{/if}
													{:else}
														<span class="text-muted"
															>{$_("List_Record_Medication_patient.text_Undefined")}</span>
													{/if}
												</td>
												{#if theRecord.Tipo_de_Actividad__c && theRecord.Tipo_de_Actividad__c !== "Estudios"}
													
													<td>
														{#if myDetail.hasOwnProperty("Profesional_sugerido__c")}
															<span>{myDetail["Profesional_sugerido__c"]}</span>
														{:else}
															<span class="text-muted"
																>{$_("List_Record_Medication_patient.text_Undefined")}</span>
														{/if}
													</td>

													
													<td>
														{#if myDetail.hasOwnProperty("Profesional_Asignado__r")}
															<span>{myDetail["Profesional_Asignado__r"]["Name"]}</span>
														{:else}
															<span class="text-muted"
																>{$_("List_Record_Medication_patient.text_Undefined")}</span>
														{/if}
													</td>
												{/if}
												
												{#if myDetail.hasOwnProperty("Fecha_Sugerida__c")}
													<td
														>{myDetail["Fecha_Sugerida__c"]
															.split("-")
															.reverse()
															.join("-")}</td>
												{:else}
													<span class="text-muted">{$_("List_Record_Medication_patient.text_Undefined")}</span>
												{/if}

												
												{#if myDetail.hasOwnProperty("Fecha_de_sesion__c")}
													<td
														>{myDetail["Fecha_de_sesion__c"]
															.split("-")
															.reverse()
															.join("-")}</td>
												{:else}
													<span class="text-muted">{$_("List_Record_Medication_patient.text_Undefined")}</span>
												{/if}

												
												<td>
													{#if myDetail["Solicitado__c"]}
														<i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" />
													{:else}
														<i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" />
													{/if}
												</td>
												
												<td>
													{#if myDetail["Completado__c"]}
														<i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" />
													{:else}
														<i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" />
													{/if}
												</td>

												
												<td>
													<button
														on:click={() => {
															dispatch("editDetail", { myDetail });
														}}
														class="btn btn-info py-1 myCustom-button"
														>{$_("List_Record_Medication_patient.button_detail")}</button>
												</td>
											{:else}
												<td>
													{#if myDetail.hasOwnProperty("Fecha_de_hoy__c")}
														<span
															>{myDetail["Fecha_de_hoy__c"]
																.split("-")
																.reverse()
																.join("-")}</span>
													{:else}
														<span class="text-muted"
															>{$_("List_Record_Medication_patient.text_Undefined")}</span>
													{/if}
												</td>

												<td>
													{#if myDetail.hasOwnProperty("Dosis__c")}
														<span>{myDetail["Dosis__c"]}</span>
													{:else}
														<span class="text-muted"
															>{$_("List_Record_Medication_patient.text_Undefined")}</span>
													{/if}
												</td>

												<td>
													{#if myDetail.hasOwnProperty("Frecuencia_texto__c")}
														<span>{myDetail["Frecuencia_texto__c"]}</span>
													{:else}
														<span class="text-muted"
															>{$_("List_Record_Medication_patient.text_Undefined")}</span>
													{/if}
												</td>
												<td>
													{#if myDetail.hasOwnProperty("Link__c")}
														<a href={myDetail.Link__c} download
															>{$_("List_Record_Medication_patient.button_download")}</a>
													{/if}
												</td>

												<td>
													<button
														on:click={() => {
															dispatch("editDetail", { myDetail });
														}}
														class="btnBlue">{$_("List_Record_Medication_patient.button_detail")}</button>
												</td>
											{/if}
										</tr>
									{/each}
								{:else}
									<small class="text-muted">{$_("List_Record_Medication_patient.text_not_details")}...</small>{/if}
							{:else}
								<small class="text-muted">{$_("List_Record_Medication_patient.text_not_details")}...</small>{/if}
						{:else}
							<small class="text-muted">{$_("List_Record_Medication_patient.text_not_details")}...</small>{/if} -->
						{/if}
					</tbody>
				</table>
			</div>
		</div>
	{/if}
</section>

<LayoutModal
	tam="40"
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<AddDetailsMedications
		nameMed={name}
		{patientId}
		{planId}
		{actividadCabeceraId}
		detailEdit={false}
		on:cancel={() => {
			console.log("modaladd");
			modaladd = false;
		}} />
</LayoutModal>

<LayoutModal
	isOpenModal={modaldelete}
	on:closeModal={() => {
		modaldelete = false;
	}}>
	{#if !loadingDelete}
		<div class="conteiner">
			<p>{$_("List_Record_Medication_patient.text_message_medication")}</p>
			<div class="Block">
				<div class="text-center">
					<button
						class="btnBlue"
						on:click={() => {
							deleteHeadM(actividadCabeceraId);
						}}>{$_("List_Record_Medication_patient.button_accept")}</button>
					<button
						on:click={() => {
							modaldelete = false;
						}}
						class="btnWhite">{$_("List_Record_Medication_patient.button_cancel")}</button>
				</div>
			</div>
		</div>
	{:else}
		<div class="text-center py-3">
			<Loader text={false} />
			<p class="text-muted">{$_("List_Record_Medication_patient.button_removing")}...</p>
		</div>
	{/if}
</LayoutModal>

<style>
	:root {
		--color1: #0C0C0C;
	}

	/* CONTAINER CSS */
	.myCustom-box {
		border: solid 1px var(--color1);
		border-radius: 5px;
	}

	/* HEADER CSS */
	.myCustom-header-closed {
		color: var(--color1);
		background-color: #fbfbfb;
	}

	.myCustom-header-open {
		background-color: #6a7e93;
		color: #ffffff;
	}

	.myCustom-name {
		min-width: 30vw;
	}

	/* .myCustom-toggle {
		transform: scale(1.25);
	} */

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
		/* transform: scale(1.25);*/
	}

	.myCustom-collapse:hover {
		cursor: pointer;
	}

	.circle {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: green;
	}

	/* DETAIL CSS */
	.collapse {
		padding: 5px;
		/* background-color: #6a91ab; */
	}
	/* .modalidad {
		padding-left: 30%;
	} */

	.myCustom-table {
		width: 100%;
		color: var(--color1);
	}

	.myCustom-table tbody tr {
		width: 100%;
	}

	.myCustom-table thead tr th {
		font-size: 15px;
		font-weight: 5;
	}

	.variableA {
		width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	/* .myCustom-button {
		background-color: var(--color1);
		border: none;
	} */
</style>
