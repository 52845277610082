<script>
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import { generic_questionnaire_detail_add, generic_medication_delete } from "../../helpers/apiBackend";
	import Loader from "../../components/Loader.svelte";
	import { user, registerDetail, language } from "./../../helpers/storage/stores";
	import { formatDate } from "./../../helpers/helpers";
	import { _ } from "svelte-i18n";

	const dispatch = createEventDispatcher();
	export let theRecord = undefined;
	export let idpatient;
	//console.log({ idpatient });
	let collapsed = true;
	let status_green = 0;
	let status_red = 0;
	let status_blue = 0;
	let status_gray = 0;
	let status_record = true;
	let message = "Agregando...";
	let loadingDelete = false;
	let modaladd = false;
	let modalInstruccion = false;
	let modaldelete = false;
	let indice = 0;
	let idQuestion = "";
	let name = "";
	// let patientId;
	// let planId;
	// let actividadCabeceraId;
	let instrucction = [];

	const addDetailQuestion = async (id) => {
		if (!$user.hasOwnProperty("Especialidades__r")) {
			idpatient = $user.Id;
		}
		console.log(theRecord.Id, $user.Id, idpatient);
		await generic_questionnaire_detail_add(theRecord.Id, $user.Id, idpatient).then((res) => {
			console.log({ res });
			if (res.statusCode == 201) {
				let detail = {
					Id: res.Id,
				};
				console.log({ detail });
				$registerDetail = detail;
				window.location.reload();
			} else {
				message = res.message;
			}
		});
		setTimeout(() => {
			modaladd = false;
		}, 4000);
	};

	const deleteHeadM = async (id) => {
		loadingDelete = true;
		console.log("Llamar a Borrar : ", id);
		await generic_medication_delete(id).then((result) => {
			if (result.statusCode == 200) {
				window.location.reload();
			} else {
				loadingDelete = false;
				console.log("error");
			}
		});
	};

	onMount(async () => {
		console.log(theRecord);
		name = theRecord?.name;
		status_record = theRecord.completed == null ? false : theRecord.completed;
		status_green = theRecord.requestedDetailsOnTime ? theRecord.requestedDetailsOnTime : 0;
		status_red = theRecord.requestedDetailsExpired ? theRecord.requestedDetailsExpired : 0;
		status_blue = theRecord.completedDetails ? theRecord.completedDetails : 0;
		status_gray = theRecord.requestedDetailsInactive ? theRecord.requestedDetailsInactive : 0;
		if (theRecord.details.length == theRecord.completedDetails) {
			status_record = true;
		}
		let inst = theRecord?.Instrucciones__c ? theRecord?.Instrucciones__c : "Instrucciones";
		console.log({ inst });
		instrucction = inst.split("/");
	});
</script>

<section>
	<div class="myCustom-box">
		<!-- 
        Header Display Data
        - Name
        - Modalidad
        - Frecuencia
        - Activo
        - Acciones
        -->
		<div
			class="{collapsed
				? 'myCustom-header-closed'
				: 'myCustom-header-open'} d-flex justify-content-between align-items-center p-2">
			<div class="d-flex myCustom-name">
				<!-- {#if theRecord.hasOwnProperty("Actividad_Detalles__r") && theRecord.Actividad_Detalles__r.totalSize > 0}
          <i class="gg-remove" style="margin-right: 2%; color:lightslategrey" />
        {:else}
          <i
            class="gg-remove"
            on:click={() => {
              modaldelete = true;
            }}
            style="margin-right: 2%;"
          />
        {/if} -->
				<p class="variableName m-0" title={name}>{name}</p>
				&nbsp;
				{#if theRecord.Modalidad__c}
					<small class="">({theRecord.Modalidad__c})</small>
				{/if}
			</div>

			<!-- Area -->
			<div class="">
				{#if theRecord.hasOwnProperty("frecuency") && theRecord.frecuency != null}
					<p class="variableA m-0" title={theRecord.frecuency}>{theRecord.frecuency}</p>
				{:else}
					<p class="variableA m-0">{$_("List_Question_patient.text_undefined")}</p>
				{/if}
			</div>

			<!-- Variable -->
			<!-- <div class="">
				{#if theRecord.variable}
					<p class="variableA m-0" title={theRecord.variable}>
						{theRecord.variable}
					</p>
				{:else}
					<p class="variableA m-0">{$_("List_Question_patient.text_undefined")}</p>
				{/if}
			</div> -->

			<!-- Administracion__c -->
			<!-- <div class="">
				{#if theRecord.toWhom}
					<p class="variableA m-0">{theRecord.toWhom}</p>
				{:else}
					<p class="variableA m-0">{$_("List_Question_patient.text_undefined")}</p>
				{/if}
			</div> -->

			<!-- Activo -->
			<!-- <div class="">
				{#if theRecord.Activo__c}
					<i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" />
				{:else}
					<i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" />
				{/if}
			</div> -->
			<!-- {#if theRecord.active}
				<div id="circle" class="circle" style="background-color: green;" />
			{:else}
				<div id="circle" class="circle" style="background-color:grey;" />
			{/if} -->
			<div class="cabeceraID">
				{#if theRecord.details.length > 0 && theRecord.active && status_record}
					<small class="headDetailC py-0">{$_("List_Records_prof.text_completed")}</small>
				{:else if !theRecord.active}
					<small class="headDetailI py-0">{$_("List_Records_prof.text_inactive")}</small>
				{/if}

				{#if theRecord.details.length > 0 && theRecord.active && !status_record}
					{#if status_green > 0}
						<span class="badge_green cursor-pointer" placeholder="Solicitado">{status_green}</span>
					{/if}
					{#if status_red > 0}
						<span class="badge cursor-pointer">{status_red}</span>
					{/if}
					{#if status_blue > 0}
						<span class="badge_blue cursor-pointer">{status_blue}</span>
					{/if}
					{#if status_gray > 0}
						<span class="badge_gray cursor-pointer">{status_gray}</span>
					{/if}
				{/if}
			</div>

			<div
				on:click={() => {
					collapsed = !collapsed;
				}}
				class="d-flex myCustom-collapse"
				data-toggle="collapse"
				data-target="#{theRecord.Id}"
				aria-expanded="false"
				aria-controls={theRecord.Id}>
				<p class="m-0 mx-2">{$_("List_Question_patient.text_more")}</p>
				<i class="gg-arrow-down-o" />
			</div>
		</div>

		<!-- Detail -->
		<div class="collapse" id={theRecord.Id}>
			<table class="myCustom-table">
				<thead>
					<tr>
						<th>{$_("List_Question_patient.text_createdby")} </th>
						<th>{$_("List_Question_patient.text_date_planned")}</th>
						<th>{$_("List_Question_patient.text_session_date")} </th>
						<th>{$_("List_Question_patient.text_complete")} </th>
						<th>
							<!-- <button
								on:click={() => {
									modaladd = true;
									addDetailQuestion();
								}}
								class="btnWhite">{$_("List_Question_patient.button_add")}</button
							>-->
						</th>
					</tr>
				</thead>
				<tbody class="py-2">
					{#if theRecord.hasOwnProperty("details")}
						{#if theRecord["details"].length > 0}
							{#each theRecord["details"] as myDetail}
								<tr class="bg-white py-2" style="height:35px;">
									<!-- Frecuencia -->
									<td>
										{#if myDetail.hasOwnProperty("createdByName")}
											<span>{myDetail["createdByName"]}</span>
										{:else}
											<span class="text-muted"
												>{$_("List_Question_patient.text_not_details")}</span>
										{/if}
									</td>
									<td>
										{#if myDetail.hasOwnProperty("dateSuggested")}
											<span>{formatDate(myDetail.dateSuggested, $language)}</span>
										{:else}
											<span class="text-muted"
												>{$_("List_Question_patient.text_not_details")}</span>
										{/if}
									</td>

									<td>
										{#if myDetail.hasOwnProperty("dateCompleted") && myDetail.dateCompleted != null}
											<span>{formatDate(myDetail.dateCompleted, $language)}</span>
										{:else}
											<span class="text-muted"
												>{$_("List_Question_patient.text_not_details")}</span>
										{/if}
									</td>

									<!-- Completado__c -->
									<td>
										{#if myDetail["completed"]}
											<!-- <i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
										{:else}
											<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
										{/if}
									</td>

									<!-- Button Detalles -->
									<td>
										<button
											on:click={() => {
												console.log({ myDetail });
												dispatch("editDetail", { myDetail });
											}}
											class="btn btn-info py-1 myCustom-button">
											{myDetail["completed"]
												? $_("List_Question_patient.button_answers")
												: $_("List_Question_patient.button_Tocomplete")}
										</button>
									</td>
								</tr>
							{/each}
						{:else}
							<small class="text-muted">{$_("List_Question_patient.text_not_details")}...</small>{/if}
					{:else}
						<small class="text-muted">{$_("List_Question_patient.text_not_details")}...</small>{/if}
				</tbody>
			</table>
		</div>
	</div>
</section>

<LayoutModal
	tam="30"
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<div class="text-center">
		<small class="text-muted">{message}</small>
	</div>
</LayoutModal>

<LayoutModal
	isOpenModal={modaldelete}
	on:closeModal={() => {
		modaldelete = false;
	}}>
	{#if !loadingDelete}
		<div class="conteiner">
			<p>{$_("List_Question_patient.text_message_medication")}</p>
			<div class="Block">
				<div class="text-center">
					<button
						class="btnBlue"
						on:click={() => {
							deleteHeadM(theRecord.Id);
						}}>{$_("List_Question_patient.button_accept")}</button>
					<button
						on:click={() => {
							modaldelete = false;
						}}
						class="btnWhite">{$_("List_Question_patient.button_cancel")}</button>
				</div>
			</div>
		</div>
	{:else}
		<div class="text-center py-3">
			<Loader text={false} />
			<p class="text-muted">{$_("List_Question_patient.button_removing")}...</p>
		</div>
	{/if}
</LayoutModal>

<LayoutModal
	isOpenModal={modalInstruccion}
	on:closeModal={() => {
		modalInstruccion = false;
	}}>
	<p>{instrucction[indice]}</p>
	<div class="buttonNext">
		{#if indice != 0}
			<button
				class="btnWhite"
				on:click={() => {
					if (indice != 0) {
						indice--;
					}
				}}>{$_("List_Question_patient.button_return")}</button>
		{/if}
		<button
			class="btnBlue"
			on:click={() => {
				if (indice + 2 < instrucction.length) {
					indice++;
				} else {
					window.location.href = `#/FormInit?Detail=${idQuestion}`;
				}
			}}>{$_("List_Question_patient.button_Next")}</button>
	</div>
</LayoutModal>

<style>
	:root {
		--color1: #0C0C0C;
	}
	.variableA {
		width: 150px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.variableName {
		width: 550px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	/* .circle {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: green;
	} */

	/* CONTAINER CSS */
	.myCustom-box {
		border: solid 1px var(--color1);
		border-radius: 5px;
	}
	.cabeceraID {
		width: 8%;
		display: flex;
		justify-content: space-between;
	}

	/* HEADER CSS */
	.myCustom-header-closed {
		color: var(--color1);
		background-color: #fbfbfb;
	}

	.myCustom-header-open {
		background-color: #6a7e93;
		color: #ffffff;
	}

	.myCustom-name {
		min-width: 30vw;
	}

	.buttonNext {
		text-align: center;
	}

	.myCustom-toggle {
		transform: scale(1.25);
	}
	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
		/* transform: scale(1.25);*/
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-collapse:hover {
		cursor: pointer;
	}

	/* DETAIL CSS */
	.collapse {
		padding: 5px;
	}

	.myCustom-table {
		width: 100%;
		color: var(--color1);
	}

	.myCustom-table {
		width: 100%;
		color: var(--color1);
	}

	.myCustom-table thead tr th {
		font-size: 15px;
		font-weight: 700;
	}

	.myCustom-button {
		background-color: var(--color1);
		border: none;
	}

	.badge {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(255, 0, 0, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_green {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(28, 187, 7, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_blue {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(16, 48, 234, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_gray {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(146, 146, 146, 0.8);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.headDetailC {
		background-color: cornflowerblue;
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.headDetailI {
		background-color: rgb(132, 132, 132);
		border: none;
		color: azure;
		border-radius: 10px;
		padding: 25%;
		text-align: center;
	}
</style>
