<!-- 
  Campos
  https://docs.google.com/spreadsheets/d/1YqntIvLV1kA61a1R_l4sZZ23Fmyv5_dG6rDtvKJA4G8/edit#gid=0
 -->
<script>
	import { onMount } from "svelte";
	//TODODELETE import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import { _ } from "svelte-i18n";
	import AddDetailsMedications from "../../../components/addDetailsMedications.svelte";
	import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { registerDetail, language } from "../../../helpers/storage/stores";
	import { currentSession } from "../../../helpers/storage/sessions.js";
	import { formatDate } from "../../../helpers/helpers";
	import ModalComplete from "../../../components/ModalComplete.svelte";
	import {
		generic_medication_detail_get,
		//update_activity_detail,
		professional_exam_pdf_add,
		//utils_files_get,
	} from "../../../helpers/apiBackend";

	//import { user } from "../../../helpers/storage/stores";

	//let type = "";
	//let patching = true;
	let modalCompleteLog = false;
	let modalPatch = false;
	let modalComplete = false;
	let detailRegister = [];
	//let byFile = false;
	let dataAdjunto = [];
	let complexItems = [];
	let myType, type, Link__c, originalRecord, myHeaders, myToken, myRecomendedDoctor;
	let [modalLoadingAnimation, modalSuccess] = [false, false];
	let modalEdit = false;
	let dataEdit = {};
	let aclaraciones = "";
	let diagnostico = "";

	if ($currentSession.hasOwnProperty("signInUserSession")) {
		myToken = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
	} else {
		alert("Error!");
	}

	const completeSession = async (e) => {
		modalCompleteLog = true;
		//modalComplete = false;
		if (e.detail.hasOwnProperty("body")) {
			let myBody = e.detail.body;
			myBody["EvolucionadoPor__c"] = "Profesional";
			//modalLoading = true;
			//console.log(e.detail)
			// await update_activity_detail(myBody, $registerDetail["detail"].Id).then((data) => {
			// 	console.log({ data });
			// 	if (data.status == 204) {
			// 		modalComplete = false;
			// 		modalSuccess = true;
			// 	} else {
			// 		//modalError = true;
			// 	}
			// 	// modalLoading = false;
			// });
		}
	};

	const updateRecord = async () => {
		// await update_activity_detail(myBody, $registerDetail["detail"].Id).then((data) => {
		// 	console.log({ data });
		// 	if (data.status == 204) {
		// 		modalSuccess = true;
		// 	} else {
		// 		modalError = true;
		// 	}
		// 	modalLoading = false;
		// });
	};

	// Solicitar
	let [panelSolicitar, modalSolicitar] = [false, false];

	//let solicitarUrl = `${AWS_HOST}/generic/update_activity_detail?activity_id=${$registerDetail["detail"]["Id"]}`;

	const solicitarCall = async () => {};

	onMount(async () => {
		complexItems = $_("RegisterDetailMedicationProf.select_activity");
		let id = $registerDetail.detailId || "";
		await generic_medication_detail_get(id).then((result) => {
			if (result.statusCode == 200) {
				detailRegister = result.data;
				console.log({ detailRegister });
				Link__c = detailRegister["files"];
				console.log('RegisterDetailMedicationProf');
				console.log(detailRegister["files"]);

				type = detailRegister["files"].split("/")[3];
				type = type.split(".")[1];
				console.log(detailRegister["files"]);
				console.log({ type });
				console.log({ Link__c });
			}
		});
		//dataAdjunto = await utils_files_get($registerDetail["file"], $currentSession);
	});
	// console.log({ $registerDetail });
	// if (Object.keys($registerDetail).length > 0) {
	// 	//Busca si tienen adjuntos
	// 	dataAdjunto = await utils_files_get($registerDetail["detail"]["Id"], $currentSession);
	// 	console.log({ dataAdjunto });
	// 	if (dataAdjunto.data.length > 0 && dataAdjunto.data[0].hasOwnProperty("Link__c")) {
	// 		type = dataAdjunto.data[0].Link__c.split("/")[3];
	// 		type = type.split(".")[1];
	// 		console.log({ type });
	// 		dataAdjunto = dataAdjunto.data;
	// 	}
	// 	// Validate Parameters (RecordType / Scope)
	// 	delete $registerDetail["Actividad_Detalles__r"];
	// 	myParams = document.location.href.split("?")[1];
	// 	if (myParams.indexOf("&") > -1) {
	// 		myParams = myParams.split("&");
	// 		myType = myParams[0].split("=")[1];
	// 		myScope = myParams[1].split("=")[1];
	// 	}
	// 	if (myScope == undefined || myScope == "" || myType == undefined || myType == "") {
	// 		window.location.href = "#/GeneralError";
	// 	}

	// 	// Delete nulls
	// 	Object.keys($registerDetail["register"]).forEach((key) => {
	// 		if ($registerDetail["register"][key] == null || $registerDetail["register"][key] == undefined) {
	// 			$registerDetail["register"][key] = "";
	// 		}
	// 	});
	// 	if ($registerDetail.hasOwnProperty("detail")) {
	// 		Object.keys($registerDetail["detail"]).forEach((key) => {
	// 			if ($registerDetail["detail"][key] == null || $registerDetail["detail"][key] == undefined) {
	// 				$registerDetail["detail"][key] = "";
	// 			}
	// 		});
	// 	}

	// 	await utils_files_get($user?.DNI__c).then((data) => {
	// 		console.log("utils_files_get");
	// 		console.log({ data });
	// 		byFile = data.exists;
	// 		console.log({ byFile });
	// 	});

	// 	// Get resources and back up original record
	// 	originalRecord = JSON.parse(JSON.stringify($registerDetail));
	// 	patching = false;
	// 	console.log({ myType });
	// }

	// $: {
	// 	if (originalRecord != undefined) {
	// 		if (JSON.stringify(originalRecord) != JSON.stringify($registerDetail)) {
	// 			sendChanges = true;
	// 		} else {
	// 			sendChanges = false;
	// 		}
	// 	}
	// 	if (myScope == "detail") {
	// 		if (dateSession !== "") {
	// 			$registerDetail["detail"]["Fecha_de_sesion__c"] = dateSession;
	// 		}
	// 	}
	// }
</script>

<section>
	{#if true}
		<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
		<!-- <LayoutPatient> -->
		{#if detailRegister.hasOwnProperty("drug")}
			<div class="conteiner-detail border rounded shadow-sm my-3 bg-white">
				<div class="Title-detail">
					<p class="title">
						{detailRegister["commertialName"]} - [{detailRegister["drug"]}]
					</p>
				</div>
				<div class="form-detail">
					<div class="row">
						<div class="column">
							<p class="Completado_p">{$_("RegisterDetailMedicationProf.text_required")}</p>
							{#if detailRegister["active"]}
								<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
								<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

							{:else}
								<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
								<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

							{/if}
						</div>
						<div class="column">
							<p class="Completado_p">
								{$_("RegisterDetailMedicationProf.text_duplicated")}
							</p>
							{#if detailRegister["duplicated"]}
								<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
								<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

							{:else}
								<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
								<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

							{/if}
						</div>
						<div class="column">
							<p class="Creado_p">
								{$_("RegisterDetailMedicationProf.text_prolongedTreatment")}
							</p>
							{#if detailRegister["prolongedTreatment"]}
							<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

							{:else}
								<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
								<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

							{/if}
						</div>
					</div>
				</div>
				<!-- panel -->
				<div class="form-detail">
					<div class="row">
						<div class="column">
							<p class="Completado_p">
								{$_("RegisterDetailMedicationProf.text_lab")}
							</p>
							{#if detailRegister["lab"]}
								{detailRegister["lab"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
							{/if}
						</div>
						<div class="column">
							<p class="Completado_p">
								{$_("RegisterDetailMedicationProf.text_presentation")}
							</p>
							{#if detailRegister["presentation"]}
								{detailRegister["presentation"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
							{/if}
						</div>
						<div class="column">
							<p class="Creado_p">
								{$_("RegisterDetailMedicationProf.text_boxAmmount")}
							</p>
							{#if detailRegister["boxAmmount"]}
								{detailRegister["boxAmmount"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
							{/if}
						</div>
					</div>
				</div>
				<div class="form-detail">
					<div class="row">
						<div class="column">
							<p class="Completado_p">
								{$_("RegisterDetailMedicationProf.text_dose")}
							</p>
							{#if detailRegister["dose"]}
								{detailRegister["dose"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
							{/if}
						</div>
						<div class="column">
							<p class="Completado_p">
								{$_("RegisterDetailMedicationProf.text_frecuency")}
							</p>
							{#if $registerDetail.frecuency}
								{$registerDetail.frecuency}
							{:else}
								<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
							{/if}
						</div>
						<div class="column">
							<p class="Creado_p">
								{$_("RegisterDetailMedicationProf.text_prescribedBy")}
							</p>
							{#if detailRegister["prescribedBy"]}
								{detailRegister["prescribedBy"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
							{/if}
						</div>
					</div>
				</div>
				<div class="form-detail">
					<div class="row">
						<div class="column">
							<p class="Completado_p">
								{$_("RegisterDetailMedicationProf.text_medicationDate")}
							</p>
							{#if detailRegister["medicationDate"]}
								<span class="text-muted">
									{formatDate(detailRegister["medicationDate"], $language)}</span>
							{:else}
								<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
							{/if}
						</div>

						<div class="column">
							<p class="Creado_p">{$_("RegisterDetailMedicationProf.text_th_createdby")}</p>
							{#if detailRegister.hasOwnProperty("createdById")}
								{detailRegister["createdById"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
							{/if}
						</div>
					</div>
				</div>
				<div class="form-detail-diag">
					{#if Link__c != ""}
						<div class="d-flex justify-content-flex-end">
							<p class="adjuntos_p">{$_("RegisterDetailPatient.text_order_medical")}</p>
							{#if type == "pdf"}
								<img
									class="cursor pointer"
									on:click={() => {}}
									src={Link__c}
									alt="Document"
									style="width: 32px; height: 32px;" /><a href={Link__c} download
									>{$_("RegisterDetailPatient.button_download")}</a>
							{:else}
								<img
									class="cursor pointer"
									on:click={() => {}}
									src="img/pdfIcon.png"
									alt="PDF"
									style="width: 32px; height: 32px;" /><a href={Link__c} download
									>{$_("RegisterDetailPatient.button_download")}</a>
							{/if}
						</div>
					{/if}
					<div class="d-flex">
						<p class="diagnosis_p">
							{$_("RegisterDetailMedicationProf.text_diagnosis")}
						</p>
						{#if detailRegister["diagnosis"]}
							<p class="txt_marg">{detailRegister["diagnosis"]}</p>
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
					<div class="d-flex justify-content-flex-end">
						<p class="diagnosis_p">
							{$_("RegisterDetailMedicationProf.text_comsumptionInstructions")}
						</p>
						{#if detailRegister["comsumptionInstructions"]}
						<p class="txt_marg">{detailRegister["comsumptionInstructions"]}</p>
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
					<div class="d-flex justify-content-flex-end">
						<p class="diagnosis_p">
							{$_("RegisterDetailMedicationProf.text_justification")}
						</p>
						{#if detailRegister["justification"]}
							<p class="txt_marg">{detailRegister["justification"]}</p>
						{:else}
							<span class="text-muted">{$_("RegisterDetailMedicationProf.text_not_name")}...</span>
						{/if}
					</div>
				</div>
				<div class="form-detail">
					{#if true}
						<div class="d-flex justify-content-center">
							<button
								on:click={() => {
									dataEdit = detailRegister;
									modalEdit = true;
								}}
								class="btnEdit">{$_("RegisterDetailMedicationProf.button_Edit")}</button>
						</div>
					{/if}
				</div>
			</div>

			<div class="text-center">
				<a class="mx-2" href="#/RecordsMedicationProf"
					>{$_("RegisterDetailMedicationProf.button_return_plan")}</a>
				<div />
			</div>
		{:else}
			<div class="p-5 text-center">
				<Loader text={false} />
				<p class="text-muted">{$_("RegisterDetailMedicationProf.loading")}</p>
			</div>
		{/if}
		<!-- </LayoutPatient> -->
		<!-- </LayoutNav> -->
	{/if}
</section>

<!-- Confirm -->
<LayoutModal isOpenModal={modalPatch} on:closeModal={(modalPatch = false)}>
	<div class="p-3 text-center">
		<p class="m-0">{$_("RegisterDetailMedicationProf.text_request_change")}</p>
		<div class="d-flex justify-content-between my-3">
			<!-- svelte-ignore component-name-lowercase -->
			<button
				on:click={() => {
					updateRecord();
				}}
				class="mx-2 btnBlue py-0">{$_("RegisterDetailMedicationProf.button_Send")}</button>
			<!-- svelte-ignore component-name-lowercase -->
			<button
				on:click={() => {
					modalPatch = false;
				}}
				class="mx-2 btnWhite py-0">{$_("RegisterDetailMedicationProf.button_Cancel")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Modal Solicitar -->
<!-- IN USE!! -->
<LayoutModal
	tam="30"
	isOpenModal={modalSolicitar}
	on:closeModal={() => {
		modalSolicitar = false;
	}}>
	<div class="p-3 text-center">
		<div>
			<!-- <h2>{$_("RegisterDetailMedicationProf.button_Request")} {$registerDetail["detail"]["Name"]}</h2> -->
		</div>
		<div class="my-2">
			<div class="form-group">
				{#if myType !== "Estudios"}
					<label for="sugerir-doctor">{$_("RegisterDetailMedicationProf.text_prof_suggested")}:</label>
					<input
						name="sugerir-doctor"
						bind:value={myRecomendedDoctor}
						class="form-control form-control-sm"
						type="text" />
				{:else}
					<!-- <div>
						<label for="sugerir-doctor">{$_("RegisterDetailMedicationProf.text_Suggest_medical")}:</label>
						<input
							name="sugerir-doctor"
							bind:value={myRecomendedDoctor}
							class="form-control form-control-sm"
							type="text" />
					</div>
					<div>
						<label for="profesionalSugerido"
							>{$_("RegisterDetailMedicationProf.text_clarifications")}:</label>
						<input
							name="sugerir-doctor"
							bind:value={aclaraciones}
							class="form-control form-control-sm"
							type="text" />
						<span class="text-muted">{$_("RegisterDetailMedicationProf.text_mandatory")}</span>
					</div>
					<br />
					<div>
						<label for="profesionalSugerido">{$_("RegisterDetailMedicationProf.button_diagnosis")}:</label>
						<input
							name="sugerir-doctor"
							bind:value={diagnostico}
							class="form-control form-control-sm"
							type="text" />
						<span class="text-muted">{$_("RegisterDetailMedicationProf.text_mandatory")}</span>
						
					</div> -->
					<br />
				{/if}

				{#if myType == "Estudios" && JSON.parse(localStorage.getItem("user"))["Matricula__c"] != null}
					<label for="aclaraciones">{$_("RegisterDetailMedicationProf.text_clarifications")}:</label>
					<textarea bind:value={aclaraciones} placeholder="" name="aclaraciones" cols="40" rows="2" />

					<label for="diagnostico">{$_("RegisterDetailMedicationProf.button_diagnosis")}:</label>
					<textarea
						class="form-control form-control-sm"
						bind:value={diagnostico}
						placeholder=""
						name="diagnostico"
						cols="40"
						rows="2"
						maxlength="10000" />
				{/if}
			</div>

			<!-- <div class="form-group d-flex justify-content-between m-0">
        <label for="sugerir-fecha">Fecha Sugerida</label>
        <input style="width: 5vw;" class="form-control form-control-sm" type="date" name="sugerir-fecha" id="" bind:value={fechaSugerida}>
      </div> 
    aclaraciones != '' && diagnostico != ''-->
		</div>
		<br />
		<div class="text-centrar">
			<br />

			{#if true}
				<button
					on:click={() => {
						solicitarCall();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("RegisterDetailMedicationProf.button_Send")}</button>
			{/if}
			<button
				on:click={() => {
					modalSolicitar = false;
				}}
				class="btnWhite py-0 mx-2 py-2">{$_("RegisterDetailMedicationProf.button_Cancel")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Modal Cargando -->
<LayoutModal
	tam="30"
	isOpenModal={modalLoadingAnimation}
	on:closeModal={() => {
		modalLoadingAnimation = false;
	}}>
	<div class="p-5 text-center">
		<Loader text={false} />
		<p class="text-muted">{$_("RegisterDetailMedicationProf.loading")}</p>
	</div>
</LayoutModal>

<!-- Modal Success -->
<LayoutModal tam="30" isOpenModal={modalSuccess}>
	<div class="p-2 text-center">
		{#if myType}
			<p>{$_("RegisterDetailMedicationProf.text_success")}</p>
			<a class="mx-2" href="#/Records?type={myType}"
				>{$_("RegisterDetailMedicationProf.button_return")} {myType}</a>
		{/if}
	</div>
</LayoutModal>

<LayoutModal
	tam="50"
	isOpenModal={modalEdit}
	on:closeModal={() => {
		modalEdit = false;
	}}>
	{#if modalEdit && dataEdit}
		<AddDetailsMedications
			nameMed={detailRegister.drug}
			patientId={detailRegister.patientId}
			planId={detailRegister.careplanId}
			actividadCabeceraId={detailRegister.medicationId}
			actividadDetalleId={detailRegister.id}
			detailEdit={dataEdit}
			on:cancel={() => {
				console.log("edit");
				modalEdit = false;
			}} />
	{/if}
</LayoutModal>

<LayoutModal
	tam="30"
	isOpenModal={modalComplete}
	on:closeModal={() => {
		modalComplete = false;
	}}>
	{#if modalCompleteLog}
		<div class="p-5 text-center">
			<Loader text={false} />
			<p class="text-muted">{$_("RegisterDetailMedicationProf.loading")}</p>
		</div>
	{:else}
		<!-- <ModalComplete
			idTypeAttachment={$registerDetail["detail"]["Id"]}
			on:complete={(e) => {
				completeSession(e);
			}}
			on:cancel={() => {
				modalComplete = false;
			}} /> -->
	{/if}
</LayoutModal>

<style>
	.diagnosis_p {
		margin: 1%;
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.error {
		color: red;
	}
	.txt_marg {
		margin: 1%;
	}
	.form-detail {
		margin: 1%;
		margin-left: 3%;
		width: 100%;
	}
	.form-detail-diag {
		margin: 1%;
		margin-left: 2%;
		width: 100%;
	}
	.conteiner-detail {
		display: flex;
		flex-wrap: wrap;
		margin-left: 5%;
		width: 90%;
		max-height: 50vh;
		overflow: auto;
	}

	.row {
		display: flex;
		width: 100%;
	}

	.column {
		flex-basis: 33.33%; /* Para dividir equitativamente en tres columnas */
		padding: 10px;
		box-sizing: border-box;
	}

	/* Estilos adicionales para visualización */

	@media (max-width: 768px) {
		/* Estilos para pantallas más pequeñas */
		.column {
			flex-basis: 50%; /* Para dividir en dos columnas en pantallas más pequeñas */
		}
	}

	@media (max-width: 480px) {
		/* Estilos para pantallas aún más pequeñas */
		.column {
			flex-basis: 100%; /* Para ocupar todo el ancho en pantallas muy pequeñas */
		}
	}

	.myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	}

	.btnEdit {
		margin: 10px;
		width: 10%;
		height: 3rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		color: #ffff;
		border-radius: 10px;
		background-color: #25085e;
		font-family: "Roboto", sans-serif;
	}

	/* .my-record {
		max-height: 55vh;
		overflow: auto;
	} */
	.Title-detail {
		color: #215273;
		margin-left: 2%;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 25px;
	}
	.title {
		font-size: 25px;
	}

	.Completado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.adjuntos_p {
		margin: 1%;
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}

	.Creado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}

	@media only screen and (min-width: 960px) {
		/* styles for browsers larger than 960px; */
		.my-record {
			max-height: 35vh;
			overflow: auto;
		}
	}
	@media only screen and (min-width: 1440px) {
		/* styles for browsers larger than 1440px; */
		.my-record {
			max-height: 45vh;
			overflow: auto;
		}
	}
</style>
