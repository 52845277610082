<script>
	import { onMount } from "svelte";
	import { createEventDispatcher } from "svelte";
	import { user, doctor } from "../helpers/storage/stores";
	import LayoutModal from "../components/Layouts/Layout_Modal.svelte";
	import ImagEdit from "../components/imagEdit.svelte";
	import Modal from "../components/Modal.svelte";
	import { _ } from "svelte-i18n";

	//export let typeAttachment;
	export let idTypeAttachment;
	//export let fileData;
	export let file_type;
	export let multipleState;
	export let photoState;
	export let object_name = "default";
	export let formatState = false;

	const dispatch = createEventDispatcher();

	let modalView = false;
	//let urlPhoto;
	let datosPrueba = {
		LastNameDoctor: "Argento",
		FirstNameDoctor: "Pepe",
		LastNamePatient: "Carlos",
		FirstNamePatient: "Acatraz",
		Especialidades: "Cardiologo",
		MatriculaNacional: "454544-5454-4",
		MatriculaProvincial: "25-2525-2522",
		PrincipioActivo: "Ibuprofeno",
		ObraSocial: "MediRds",
		NroAfiliado: "1112121121",
		Dosis: "50 ml",
		Fecha: "01/12/2022",
		CantidadDeCajas: "5",
		TratamientoProlongado: "Tratamiento Prolongado",
		Diagnostico: "Por dolor de cabeza",
	};
	let modalViewPhoto = false;

	let files = [];
	let outputsrc = "";
	let dataFileAd = "";

	const deleteFile = (fileName) => {
		console.log({ fileName });
		console.log({ files });
		if (files.length == 1) {
			files = [];
		} else {
			//files = files.filter((item) => item.name !== fileName); // Filtrar archivos, excluyendo el que se va a eliminar
			const filesArray = Object.values(files);
			// Filtrar archivos, excluyendo el que se va a eliminar
			files = Object.fromEntries(Object.entries(filesArray).filter(([key, value]) => value.name !== fileName));
		}
		console.log({ files });
	};

	const validarExt = (e) => {
		let myObjArray = [];
		return new Promise(function (resolve, reject) {
			// Código asíncrono aquí
			let fileData = [];

			if (files.length === 0) {
				files = e.target.files;
			} else {
				// Si ya hay archivos, agregar los nuevos a la matriz existente
				files = [...files, ...e.target.files];
			}

			console.log({ files });
			myObjArray = Object.entries(files);
			console.log({ myObjArray });

			if (myObjArray.length > 0) {
				console.log('$user.Id ', $user.Id);
				myObjArray.map((element) => {
					let dataFile = {
						creator: $user.Id,
						attachment: idTypeAttachment,
						file_type: file_type, // "Foto de perfil", "Certificado patologia", "Matricula", "Documento"
						file_format: "", // "jpg", "jpeg", "pdf"
						data: "",
						object_name,
						name: element[1].name,
					};

					let filenamep = element[1].name;

					let imageFormatsRegex = /\.(pdf|png|jpe?g|img|doc|docx)$/i;
					let messageType = $_("Subit.FileAttach.message_invalid_file");
					if (formatState) {
						imageFormatsRegex = /\.(png|jpe?g|img)$/i;
						messageType = $_("Subit.FileAttach.message_imageFormatsRegex");
						fileData = [];
					}

					const isValidFormat = imageFormatsRegex.test(filenamep.toLowerCase());
					if (!isValidFormat) {
						alert(messageType);
						document.getElementById("fileInput").value = "";
						dataFile.data = "";
						dataFile.file_format = "";
						fileData = [];
					} else {
						if (element[1].size > 5242880) {
							alert($_("Subit.FileAttachment.SizeExceeded"));
							document.getElementById("fileInput").value = "";
							files = [];
						} else {
							let image = element[1];
							let view = new FileReader();
							view.readAsDataURL(image);
							view.onload = function (e) {
								let B64 = e.target.result.split(",")[1];
								dataFile.data = B64;
								// dataFile.file_format = image.name.split(".")[1];
								dataFile.file_format = image.name.split('.').pop();
								dataFile.urlPhoto = URL.createObjectURL(image);
								dataFile.oldPictureUrl = "";
								console.log("se carga: ", dataFile);
								fileData.push(dataFile);
								outputsrc = URL.createObjectURL(image);
								resolve(fileData);
							};
						}
						dispatch("dataFile", { detail: { fileData } });
					}

					if (formatState) {
						files = [];
					}
				});
			}
			myObjArray = [];
		})
			.then(function (fileData) {
				console.log("manda el result ", file_type);
				if (file_type == "Foto de perfil") {
					dataFileAd = fileData;
					outputsrc = fileData[0].urlPhoto;
					modalViewPhoto = true;
				}

				myObjArray = [];
			})
			.catch(function (error) {
				console.log("error ", error);
			});
	};
	onMount(async () => {
		outputsrc = "";
		// No necesitas limpiar files aquí
	});
</script>

<div class="adjuntar">
	<section>
		<input
			class="input-log"
			type="file"
			id="fileInput"
			name="fileInput"
			style={photoState ? "" : "display:none"}
			multiple={multipleState ? true : false}
			on:change={(e) => {
				validarExt(e);
			}} />
		<div id="fileView" />

		{#if files.length > 0 && (multipleState || photoState)}
			<br />
			<small>{$_("Subit.FileAttach_Attachments")}:</small>
			<div class="border shadow-sm rounded bg-white">
				<ul>
					{#each files as attachment}
						<li>
							<small>{attachment.name}</small>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								on:click={() => deleteFile(attachment.name)}>
								<path
									d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
							</svg>
						</li>
					{/each}
				</ul>
			</div>
			<br />
		{/if}

		{#if file_type == "Firma" && outputsrc != ""}
			<button
				class="btnview"
				on:click={(e) => {
					e.preventDefault();
					console.log(outputsrc);
					modalView = true;
				}}>
				<!-- svelte-ignore a11y-missing-attribute -->
				<img id="passworImag" src="/img/show-password1.png" class="showPassword" width="20" height="20" /><span
					class="text-muted">Vista previa</span
				></button>
		{/if}
	</section>
</div>

<LayoutModal
	isOpenModal={modalView}
	on:closeModal={() => {
		modalView = false;
	}}>
	<div class="p-3 text-center">
		<section>
			<p class="text-demo" align="center">
				{$doctor.LastName}, {$doctor.FirstName}
			</p>

			<p class="text-demo" align="center">
				Matricula: {$doctor.Matricula__c}
			</p>
		</section>
		<section>
			<p class="text-demo2" align="left">Rp/</p>
			<p class="text-demo2" align="left">
				{datosPrueba.LastNamePatient}, {datosPrueba.FirstNamePatient}
			</p>
			<p class="text-demo2" align="left">{datosPrueba.ObraSocial}</p>
			<p class="text-demo2" align="left">{datosPrueba.NroAfiliado}</p>
			<br />
			<p class="text-demo2" align="left">{datosPrueba.PrincipioActivo}</p>
			<p class="text-demo2" align="left">{datosPrueba.Dosis}</p>
			<p class="text-demo2" align="left">
				Cantidad de cajas: {datosPrueba.CantidadDeCajas}
			</p>
			<p class="text-demo2" align="left">{datosPrueba.TratamientoProlongado}</p>
			<p class="text-demo2" align="left">Receta duplicada.</p>
			<p class="text-demo2" align="left">
				Diagnostico: {datosPrueba.Diagnostico}
			</p>
			<br />
			<table width="100%">
				<thead>
					<tr />

					<tr>
						<th width="50%"><p class="text-demo2" align="left">Fecha</p></th>
						<th width="50%"><p class="text-demo2" align="left">Firma y sello</p></th>
					</tr>
					<tr>
						<td colspan="2"><hr style="border: 1px solid #000;" /></td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td align-content="initial">
							<p class="text-demo2">{datosPrueba.Fecha}</p>
						</td>
						<td>
							<center>
								<img src={outputsrc} width="120" height="70" />
							</center>
						</td>
					</tr>
				</tbody>
			</table>
		</section>
		<div class="text-center">
            <button class="btnWhite" on:click={() => modalView = false}>
                Volver
            </button>
        </div>
	</div>
</LayoutModal>

<LayoutModal
	tam="60"
	isOpenModal={modalViewPhoto}
	on:closeModal={() => {
		modalViewPhoto = false;
		outputsrc = "";
	}}>
	{#if outputsrc && dataFileAd}
		<div class="p-3 text-center">
			<ImagEdit
				imageSrc={outputsrc}
				{dataFileAd}
				on:dataFileAd={async (e) => {
					console.log("dataFileAd: ", e);
					modalViewPhoto = false;
					dispatch("dataFile", { detail: { fileData: e.detail } });
					outputsrc = "";
				}} />
		</div>
	{/if}
</LayoutModal>

<style>
	.adjuntar {
		color: #0C0C0C;
		font-weight: 600;
	}
	.btnview {
		border: none;
		background-color: white;
	}
	.input-log {
		width: 100%;
		height: var(--height, 42px);
		padding-left: 9px;
		border-radius: 5px;
		letter-spacing: 1px;
	}
	.text-demo {
		color: #000;
		font-family: "Helvetica", sans-serif;
		font-size: 11pt;
		margin: 1%;
	}
	.text-demo2 {
		color: #000;
		font-family: "Roboto", sans-serif;
		font-size: 9pt;
		margin: 1%;
		text-align: initial;
	}
</style>
