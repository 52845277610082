<script>
	import { currentSession } from "../helpers/storage/sessions";
	import { patient, user, language, registerDetail, patientLayout,dataPatient } from "../helpers/storage/stores";
	import Loader from "../components/Loader.svelte";
	import LayoutModal from "../components/Layouts/Layout_Modal.svelte";
	import HelpFomr from "../components/HelpFomr.svelte";
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";
	import {
		generic_account_get
	} from "../helpers/apiBackend";
	// Vars
	let loading = true;
	let modalSuccessHelp = false;
	let viewSelectLang = false;
	let fotoPerfil = "";
	let fotoPerfilProf = "";
	$: messageMedicoC = $_("Menu.text_messageMedicoC");
	$: medicoCabecera = "";
	let [specialities, dataEsp, esp] = [[], [], []];
	let [nombreMedico, paciente, nombre, rol] = ["", null, "", ""];
	rol = $currentSession.attributes.profile;
	let langSelect = "es";

	if ($language == "es" || $language == "en") {
		langSelect = $language;
	}

	function closeHelpForm() {
		modalSuccessHelp = false;
	}

	function removeResources() {
		const keys = [
			"pathologies",
			"monitoringPlans",
			"prepaidMedicine",
			"specialties",
			"frecuencies",
			"languages",
			"country",
			"state"
		];
		keys.forEach(key => localStorage.removeItem(key));
	}

	function changeLanguage(lang) {
		$language = lang;
		removeResources();
		window.location.reload();
	}

	onMount(async () => {
		console.log("Abre el menu", $user);

		// Verifica si los datos del usuario ya están cargados en dataPatient
		if (Object.keys($user).length > 0) {
			dataPatient.set($user); // Carga los datos en el store

			if ($user.FirstName) {
				nombre = $user.FirstName + " " + $user.LastName;
			}

			if (rol == "Profesional_de_Salud") {
				if ($dataPatient.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = $dataPatient.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							fotoPerfil = element.Link__c;
						}
					});
				}

				dataEsp = $user.Especialidades__r.records;
				dataEsp.forEach((element) => {
					if (dataEsp.length > 0) {
						if ($language == "es") {
							esp.push(element.Name_ES__c);
						} else {
							esp.push(element.Name_EN__c);
						}
					} else if (element.Name) {
						esp.push(element.Name);
					}
				});
				specialities = esp;
			}
			console.log('Menu rol ' , rol);
			if (rol == "Paciente") {
				console.log('element rol Patient ', $user );
				if ($user.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = $user.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							fotoPerfil = element.Link__c;
						}
					});
				}

				medicoCabecera = $user["Estado_Medico_de_cabecera__c"];
				nombreMedico = $user?.Medico_cabecera?.Name;
				if (medicoCabecera == "Pendiente de aprobación") {
					messageMedicoC = `${medicoCabecera}`;
				} else {
					messageMedicoC = $_("Menu.text_messageMedicoC");
				}

				if ($user.Medico_cabecera && $user.Medico_cabecera.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = $user.Medico_cabecera.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							fotoPerfilProf = element.Link__c;
						}
					});
				}
			}

			if (rol == "Familiar_Cuidador") {
				if ($user.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = $user.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							fotoPerfil = element.Link__c;
						}
					});
				}
			}
		} else {
			console.log("Explota aca");
			window.location.href = "#/LoginError";
		}

		// Nueva lógica para cargar dataPatient
		const email = $patient.PersonEmail || '';
		const dni = $patient.DNI__c || '';
		console.log({email});
		console.log($patient);
		// const patientData = await Promise.all([generic_account_get(email, $patient.Id, "Patient", dni)]);
		// $dataPatient.set(patientData); // Establece los datos en el store dataPatient

		loading = false;
	});

	// Cerrar Sesion
	const closeSession = () => {
		window.sessionStorage.clear();
		[$currentSession, $user, $patient, $registerDetail] = [{}, {}, {}, {}];
		dataPatient.set({}); // Limpia el store
		window.location.href = "/";
	};
</script>

<!-- Component HTML -->
<section class="background" style="height: 100vh;">
	<div
		class="help-icon"
		data-title={$_("Menu.button_help")}
		on:click={() => {
			modalSuccessHelp = true;
		}}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="1"
			stroke-linecap="round"
			stroke-linejoin="round">
			<circle cx="12" cy="12" r="10" fill="none" />
			<text x="12" y="15" text-anchor="middle" font-size="12">?</text>
		</svg>
	</div>

	{#if loading}
		<div class="text-center py-3">
			<Loader text={false} />
			<p class="text-muted">{$_("Menu.loading")}</p>
		</div>
	{:else}
		<!-- Usuario -->
		<div class="container flex justify-center items-center h-screen w-screen mx-auto">
			<div class="p-2">
				<!-- <div class="border rounded shadow py-2 mt-4"> -->
				<div class="logo text-center">
					<!-- <img src="./img/Frame 106.png" alt="logo" width="15%" height="15%" /> -->
					<img src="./img/new_logo_redondo.png" alt="logo" width="15%" height="15%" />
				</div>
				<div class="menu-info">
					<!-- <img src="./img/perfilNone.png" alt="logo" width="35%" height="35%"/> -->
					<!-- <p class="m-0">Bienvenido:</p> -->

					<div id="perfilIMGMenu" class="perfilIMG my-3">
						<!-- <img
					
							 on:click={() => {
								// tiene que llamar a la vista de perfil de acuerdo al rol
								if (rol == "Paciente") {
									console.log("Ver perfil");
									$patient = $user;
									window.location.href = "#/Patient";
								} else {
									$patient = {};
									window.location.href = "#/ProfessionalProfile";
								}
							}}
							class="imgPerfil cursor-pointer"
							style="width:108px; height:108px;"
							src={fotoPerfil ? fotoPerfil : "./img/perfil_none.png"}
							alt="" /> -->
							<img
							class="imgPerfil cursor-pointer"
							style="width:108px; height:108px;"
							src={fotoPerfil ? fotoPerfil : "./img/perfil_none.png"}
							alt="" />
					</div>
					<div class="roles">
						<p class="m-0">{nombre}</p>
						{#if rol == "Cuidador"}
							<p class="m-0">{$_("Menu.rolSelect_Carier")}</p>
						{/if}
						{#if rol == "Paciente"}
							<p class="m-0">{$_("Menu.rolSelect_Pat")}</p>
						{/if}
						{#if rol == "Profesional_de_Salud"}
							<p class="m-0">{$_("Menu.rolSelect_Prof")}</p>
							<br />
							{#each specialities as spe}
								<p class="m-0">{spe}</p>
							{/each}
						{/if}
					</div>

					<!-- Medico Cabecera -->
					<br />
					{#if (medicoCabecera == "Aún no se eligió medico" || medicoCabecera == "Rechazado") && rol == "Paciente"}
						<div class="medicoCabecera">
							<p class="m-0 text-danger">{messageMedicoC}</p>
						</div>
					{:else if rol == "Paciente" && medicoCabecera == "Aprobado"}
						<p class="m-0">{$_("Menu.chief_medic")}:</p>
						<div class="medicoCabecera">
							<img
								on:click={() => {
									console.log("Ver perfil");
									//window.location.href = "#/Patient";
								}}
								class="imgPerfil cursor-pointer"
								style="width:70px; height:70px;"
								src={fotoPerfilProf ? fotoPerfilProf : "./img/perfil_none.png"}
								alt="" />
							<p class="m-0">{nombreMedico}</p>
						</div>
					{/if}
					{#if rol == "Paciente" && medicoCabecera == "Pendiente de aprobación"}
						<div class="medicoCabecera">
							<p class="m-0 text-danger">{messageMedicoC}</p>
						</div>
					{/if}
					{#if rol == "Familiar_Cuidador"}
						<div class="py-2 m-2">
							<div style="color: #25085e;">
								<!-- Familiar Cuidador -->
								<div class="p-2">
									<div class=" py-2">
										<p class="m-0" style="color: #ffff;">{$_("Menu.text_patient")}:</p>
										{#if true}
											<p class="m-0" style="color: #ffff;">
												({$_("Menu.text_notselectPatient")})
											</p>
										{:else}
											<p class="m-0">{paciente}</p>
											<div class="d-flex justify-content-center">
												<a href="/account" class="btnBlue py-0 m-1">{$_("Menu.text_Change")}</a>
												{#if true}
													<button class="btnWhite py-0 m-1"
														>{$_("Menu.button_remove")}</button>
												{/if}
											</div>
										{/if}
									</div>
								</div>
							</div>
						</div>
					{/if}
				</div>
				<!-- </div> -->
			</div>
		</div>

		<!-- Opciones -->
		<div class="text-center">
			{#if rol == "Paciente" && medicoCabecera == "Aprobado"}
				<br />
				<button
					class="btnPaciente"
					on:click={() => {
						window.location.href = "#/Patient/Plan";
					}}>{$_("Menu.button_plan")}</button>
			{:else if rol == "Profesional_de_Salud"}
				<div class="botones_paciente">
					<button
						class="btnPaciente"
						on:click={() => {
							$patient = {};
							$patientLayout = {};
							window.location.href = "#/Doctor";
						}}>{$_("Menu.button_patient")}</button>
				</div>
				<div class="botones_altas">
					<button
						class="btnAltas"
						on:click={() => {
							window.location.href = "#/Altas";
						}}>{$_("Menu.button_altas")}</button>
				</div>
			{/if}
			{#if rol == "Familiar_Cuidador"}
				<button class="btnMisPacientes"><span>{$_("Menu.button_my_patients")} </span></button>
			{/if}
			{#if false}
				<div class="conteiner-setting">
					<button
						class="btnSetting"
						on:click={() => {
							//closeSession();
						}}>{$_("Menu.button_setting")}</button>
				</div>
			{/if}
			{#if false}
				<div class="conteiner-help">
					<button
						class="btnHelp"
						on:click={() => {
							//closeSession();
						}}>{$_("Menu.button_help")}</button>
				</div>
			{/if}

			<div class="cerrar_S">
				<button
					class="btnCerrar"
					on:click={() => {
						closeSession();
					}}>{$_("Menu.button_logout")}</button>
			</div>
			<br /><br /><br />
			<div class="language-selector mt-3">
				<img
					src="./img/globo.png"
					alt="Icono del mundo"
					style="width: 24px; height: 24px; cursor: pointer;"
					 />
		
					<select
						bind:value={langSelect}
						on:change={() => {
							changeLanguage(langSelect);
						}}>
						<option value="es">{$_("Login.spanish")}</option>
						<option value="en">{$_("Login.englis")}</option>
					</select>
			
			</div>
			
		</div>
		
	{/if}
</section>
<LayoutModal
	tam="30"
	isOpenModal={modalSuccessHelp}
	on:closeModal={() => {
		modalSuccessHelp = false;
	}}>
	<HelpFomr on:closeHelpForm={closeHelpForm} />
</LayoutModal>

<style>
	.conteiner-help {
		padding-top: 5%;
		text-align: center;
	}
	.conteiner-setting {
		padding-top: 5%;
		text-align: center;
	}
	.btnHelp {
		width: 150px;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #25085e;
	}
	.btnSetting {
		width: 150px;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #25085e;
	}
	.help-icon {
		position: absolute;
		top: -5px;
		cursor: pointer;
		color: white;
		font-size: 24px;
		margin-left: 5px;
	}

	.help-icon[data-title]:hover::after {
		content: attr(data-title);
		background-color: #a6c8de;
		color: white;
		padding: 2px 6px; /* Valores más pequeños para el relleno */
		border-radius: 2px; /* Valores más pequeños para el radio del borde */
		position: absolute;
		bottom: -20px; /* Distancia desde abajo más pequeña */
		left: 50px;
		transform: translateX(-50%);
		font-size: 13px;
		z-index: 1;
	}

	.languege-icon[data-title]:hover::after {
		content: attr(data-title);
		background-color: #a6c8de;
		color: white;
		padding: 2px 6px; /* Valores más pequeños para el relleno */
		border-radius: 2px; /* Valores más pequeños para el radio del borde */
		bottom: -20px; /* Distancia desde abajo más pequeña */
		left: 50px;
		transform: translateX(-50%);
		font-size: 13px;
		z-index: 1;
	}
	.cerrar_S {
		padding-top: 15%;
	}
	.botones_paciente {
		padding-top: 10%;
		text-align: center;
	}
	.botones_altas {
		padding-top: 5%;
		text-align: center;
	}
	.btnMisPacientes {
		width: 40%;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #25085e;
	}
	.btnCerrar {
		/* width: 40%; */
		width: 150px;
		border-radius: 35px;
		background-color: #25085e;
		color: #fff;
	}
	.btnPaciente {
		width: 150px;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #25085e;
	}
	.btnAltas {
		width: 150px;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #25085e;
	}
	.background {
		color: #fff;
		background: #25085e;
		width: 100%;
	}
	.logo {
		position: relative;
	}
	.perfilIMG {
		right: 10%;
	}
	.imgPerfil {
		width: 150px;
		border-radius: 50%;
		background-size: 120%;
	}
	.imgPerfil:hover {
		transform: scale(1.05);
		transition: 0.2s;
	}
	.roles {
		text-align: center;
	}
	.medicoCabecera {
		align-items: center;
		text-align: center;
	}
	.menu-info {
		align-items: center;
		text-align: center;
	}

	.language-selector {
  
	border-radius: 20px;
	border: 2px solid;
	margin: 25%;
	background-color: white;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.language-selector select {
    padding: 0.5rem;
    border: 1px solid #cccccc00;
    border-radius: 5px;
    font-size: 1rem;
}

	
</style>
