<script>
	import { onDestroy, onMount } from "svelte";
	import { writable } from "svelte/store";
	import { currentSession } from "./helpers/storage/sessions";
	import Router from "svelte-spa-router";
	import routes from "./routes";
	import LayoutNav from "./components/Layouts/Layout_Nav.svelte";
	import LayoutPatient from "./components/Layouts/Layout_Patient.svelte";
	import { language, patientLayout, user } from "./helpers/storage/stores";
	import 'carbon-components-svelte/css/all.css';

	let isLoggedIn = false;
	let viewPatientLayout = false;
	let rol = "general";
	let valisLoggeIn = false;
	let statusSF = false;
	let userLogged = false;
	let isVisible = false;
	let isBackgroundVisible = true;

	// Almacenar la sesión actual en un store writable
	const sessionStore = writable(null);

	// Suscribirse a cambios en la sesión
	currentSession.subscribe((session) => {
		sessionStore.set(session);
	});

	onMount(async () => {
		
		console.log({ $user });
		console.log({ $patientLayout });
		console.log({ isLoggedIn });
		//isLoggedIn = false;
		const storedSession = localStorage.getItem("currentSession");
		console.log({ storedSession });

		if (storedSession !== null) {
			currentSession.set(JSON.parse(storedSession));
		}

		const myUser = localStorage.getItem("user");
		if (myUser !== "") {
			userLogged = true;
		}

		const value = localStorage.getItem("user");
		const data = JSON.parse(value);
		const userRegis = data.attributes["custom:StatusReg"];
		if (userRegis == "false") {
			userLogged = false;
		} else {
			userLogged = true;
		}
	});

	onDestroy(() => {
		sessionStore.set(null); // Limpiar el store al salir del componente
	});

	// Función para verificar si una clave tiene un valor en el local storage - SALI 080724
	function checkLocalStorageValue(key) {
		const value = localStorage.getItem(key);
		return value !== null && value !== "" && value !== "{}";
	}

	

	// Observar cambios en el store para actualizar el estado de isLoggedIn y rol
	$: {
		
		
		const session = $sessionStore;
		isVisible = false;
		if ($user.hasOwnProperty("Especialidades__r")) {
			isVisible = checkLocalStorageValue("patient");
		}

		if (Object.keys(session).length !== 0) {
			isLoggedIn = true;
			if (session.attributes) {
				rol = session ? session.attributes.profile : "general";
				statusSF = session ? session.attributes["custom:StatusReg"] : false;

				if (statusSF == "true") {
					valisLoggeIn = true;
				}
			}
		}
		// console.log($patientLayout);
		if (Object.keys($patientLayout).length !== 0) {
			viewPatientLayout = true;
		}
	}
</script>

<svelte:head>
	<link rel="icon" href="/img/new_favicon.png" />
</svelte:head>
<main class={isBackgroundVisible ? 'main-router' : ''}>
	<!-- <h1>{valisLoggeIn}-{isLoggedIn}-{viewPatientLayout}</h1> -->
	{#if userLogged}
		<LayoutNav secure={true} {rol} {valisLoggeIn}>
			<LayoutPatient {isVisible} />
			<Router {routes} />
		</LayoutNav>
	{:else}
		<Router {routes} />
	{/if}

	<!-- {#if userLogged}
		{console.log("Recarga en el html")}
		<LayoutNav secure={true} {rol} {valisLoggeIn}>
			{#if viewPatientLayout}
			    {console.log("Recarga en el html-dentro del if")}
				<LayoutPatient isVisible={"TEST PARAMS"} />
			{/if}
			<Router {routes} />
		</LayoutNav>
	{:else}
		<Router {routes} />
	{/if} -->
</main>

<style>
	.main-router {
		background-image: url("/img/backrdcom1.png");
		background-size: cover; /* Ajusta la imagen para que cubra todo el fondo */
		background-position: bottom center; /* Centra la imagen */
		background-repeat: no-repeat; /* Evita que la imagen se repita */
		min-height: 100vh; /* Asegura que el contenedor cubra toda la pantalla */
	}

	:global(body){
        margin: 0;
		background-color: aqua;
    }
</style>
