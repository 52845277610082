<script>
	import { onMount } from "svelte";
	//import { writable } from "svelte/store";
	import {
		saveCaregiver,
		utils_resources_get,
		patient_patient_update,
		professional_account_update,
		ROLES,
		utils_files_update,
		utils_files_add,
	} from "../../helpers/apiBackend";
	import Loader from "../../components/Loader.svelte";
	import MultiSelect from "../../components/MultiSelect.svelte";
	import { currentSession } from "../../helpers/storage/sessions";
	import { patient, doctor, carer, user, language, dataPatient } from "../../helpers/storage/stores";
	import { validateOnlyLetters, validateOnlyNumbers, validateOnlyDate } from "../../helpers/validation";
	import { getProfilePhoto } from "../../helpers/helpers";
	import { replace } from "svelte-spa-router";
	import { Auth, a } from "aws-amplify";
	import awsconfig from "../../aws-exports";
	import FileAttach from "../../components/FileAttach.svelte";
	import { _ } from "svelte-i18n";

	export let edit = false;
	let langSelect = "es";
	//let errorMessages;

	if ($language == "es" || $language == "en") {
		langSelect = $language;
	} else {
		console.log("Errorcito");
		console.log({ $language });
		langSelect = "es";
	}

	Auth.configure(awsconfig);

	let photo = "./img/perfil_none.png";
	let errors = {};
	let success = false;
	let modalLoadingAnimation = false;
	let statusView = false;
	let rol = "";
	let codA1 = "";
	let Phone1 = "";
	let codA2 = "";
	let Phone2 = "";
	let username = "";
	let provincias = [];
	let coberturasList = [];
	let countryList = [];
	let countryId = "";
	let countryName = "";
	let stateName = "";
	let genderOptions = [];
	let listLanguage = [];
	let rolItems = [];
	let credentials = {};
	let idTypeAttachment = "";
	let file_type = "Foto de perfil";
	let selectLanguage = [];
	let loadingModal = false;

	async function loadStates(id) {
		provincias = [];
		setSelectDisabled(true);
		const resultado = await utils_resources_get("state", langSelect, id);
		const filteredData = resultado.filter(item => item.Id_country === id);
		provincias = filteredData;
		setSelectDisabled(false);
	}

	async function onCountrySelect(event) {
		console.log({ event });
		//credentials.BillingState = null;
		const selectedCountry = event.target.value;
		console.log({ selectedCountry });
		if (selectedCountry) {
			const selectedCountryData = countryList.find((c) => c.label === selectedCountry);
			console.log({ selectedCountryData });
			if (selectedCountryData) {
				credentials.BillingCountry = selectedCountryData;
				countryId = selectedCountryData.Id;
				countryName = selectedCountryData.label;
				credentials.BillingState = null;
				stateName = "";
				console.log("ACA se fue al 1", countryId);
				await loadStates(countryId);
			}
		}
	}

	// async function onSelectcountry(selectedCountry, countryList) {
	// 	console.log({ selectedCountry });
	// 	if (selectedCountry) {
	// 		const selectedCountryData = countryList.find((c) => c.label === selectedCountry);
	// 		if (selectedCountryData) {
	// 			credentials.BillingCountry = selectedCountryData;
	// 		}
	// 		return selectedCountryData;
	// 	}
	// }

	const getDateFormat = () => {
		// Detectar el idioma del navegador o la configuración del usuario
		const userLanguage = $language;
		console.log({ userLanguage });

		// Definir formatos de fecha para diferentes idiomas
		if ($language == "en") {
			// Inglés (mm-dd-yyyy)
			return "MM-DD-YYYY";
		} else {
			// Otros idiomas (dd-mm-yyyy)
			return "DD-MM-YYYY";
		}
	};

	async function onStateSelect(event) {
		//console.log({ event });
		const selectedState = event.target.value;
		console.log({ selectedState });
		const selectedStateData = provincias.find((c) => c.label === selectedState);
		console.log({ selectedStateData });
		if (selectedStateData != "" && selectedStateData != undefined) {
			credentials.BillingState = selectedStateData;
			stateName = selectedStateData.label;
		} else {
			stateName = credentials.BillingState;
		}
	}

	function setSelectDisabled(disabled) {
		console.log({ disabled });
		const selectElement = document.getElementById("billingState");
		if (selectElement) {
			selectElement.disabled = disabled;
		}
	}

	/**
	 * @description
	 */
	const validate = () => {
		errors = {};
		let message = $_("PersonalData.text_mandatory");
		if (!credentials.FirstName) {
			errors.FirstName = message;
		}
		if (!credentials.LastName) {
			errors.LastName = message;
		}
		if (!credentials.HealthCloudGA__Gender__pc) {
			errors.HealthCloudGA__Gender__pc = message;
		}
		if (!credentials.DNI__c) {
			errors.DNI__c = message;
		}
		if (!credentials.PersonMobilePhone) {
			errors.PersonMobilePhone = message;
		}
		if (!credentials.BillingState) {
			errors.BillingState = message;
		}
		if (!credentials.PersonBirthdate) {
			errors.PersonBirthdate = message;
		}

		if (!credentials.BillingCountry) {
			errors.BillingCountry = message;
		}
		if (!credentials.Nro_de_afiliado__c && rol == ROLES.patient && credentials.Cobertura_Salud__c != "Particular") {
			errors.Nro_de_afiliado__c = message;
		}
		if (credentials.nameaddress == "") {
			errors.addres = message;
		}
		if (!credentials.R1_streetNumber__c) {
			errors.addresNumber = message;
		}
		if (!credentials.Nro_de_afiliado__c && !credentials.Cobertura_Salud__c && rol === ROLES.patient) {
			console.log({ rol });
			console.log(ROLES.patient);
			errors.Cobertura_Salud__c = $_("PersonalData.text_mandatory");
		}
		if (credentials.PersonBirthdate == "") {
			document.getElementById("startPersonBirthdate").style.color = "red";
			errors.PersonBirthdate = $_("PersonalData.text_mandatory");
		}
		console.log({ errors });
		if (Object.keys(errors).length > 0) {
			return false;
		}
		return true;
	};

	/**
	 * @description
	 * Update user status
	 */
	async function updateUserStatus(status) {
		let user;
		await Auth.currentAuthenticatedUser()
			.then(async (resultado) => {
				user = resultado;
				await Auth.updateUserAttributes(user, {
					"custom:StatusReg": status,
				});
			})
			.catch((err) => {
				user = $currentSession;
			});
	}
	function buscarDatos(data, listLanguage) {
		console.log({ data });
		console.log({ listLanguage });
		const objetosEncontrados = [];
		for (const item of listLanguage) {
			const objetoEncontrado = data.find((obj) => obj.value === item);
			if (objetoEncontrado) {
				objetosEncontrados.push(objetoEncontrado);
			}
		}
		console.log({ objetosEncontrados });
		return objetosEncontrados;
	}
	/**
	 * @description
	 */
	const handleSubmit = async () => {
		console.log("handleSubmit");
		credentials.structlistLanguage = buscarDatos(listLanguage, selectLanguage);
		//console.log({ structlistLanguage });

		if (codA1 != "" && Phone1 != "") {
			credentials.Phone = codA1 + "-" + Phone1;
		}
		if (codA2 != "" && Phone2 != "") {
			credentials.PersonMobilePhone = codA2 + "-" + Phone2;
		}
		credentials.photo = photo;
		console.log({ credentials });
		if (validate()) {
			success = true;
			try {
				console.log("handleSubmit inicio");
				const dataUser = $currentSession;
				if (rol === ROLES.patient) {
					if (edit) {
					// COMENTO PARA QUE NO SALGA MODAL LUEGO DE VOLVER, NI GUARDAR EN REGISTER	loadingModal = true;
						await patient_patient_update(credentials, "Patient").then(async (data) => {
							if (data.statusCode == 201) {
								$patient = credentials;
								$user = credentials;
								if (credentials.hasOwnProperty("Archivos_S3__r")) {
									if (!credentials.hasOwnProperty("fileAttFotoPerfil")) {
										window.location.reload();
									}
									let resultado = await getProfilePhoto(credentials.Archivos_S3__r);
									console.log({ resultado });
									console.log('#Personal-Information credentials.fileAttFotoPerfil: ', credentials.fileAttFotoPerfil);
									if (resultado.status) {
										let fileAttFotoPerfil = credentials.fileAttFotoPerfil.detail.fileData;
										fileAttFotoPerfil.forEach(async (element) => {
											element.creator = credentials.Id;
											console.log("entro 2");
											credentials.Archivos_S3__r.records.map((item) => {
												console.log(item);
												if (item.Link__c && item.Tipo__c == "Foto de perfil") {
													element.attachment = item.Account__c;
													element.oldPictureUrl = item.Link__c;
												}
											});
											await utils_files_update(element).then((data) => {
												if (data.error) {
													$patient = credentials;
													window.location.reload();
												} else {
													window.location.reload();
												}
											});
										});
									}
								}

								if (credentials.hasOwnProperty("fileAttFotoPerfil")) {
									console.log("fileAttFotoPerfil ingreso: ");
									let fileAttFotoPerfil = credentials.fileAttFotoPerfil.detail.fileData;
									fileAttFotoPerfil.forEach(async (element) => {
										element.creator = credentials.Id;
										element.attachment = credentials.Id;
										console.log("fileAttFotoPerfil : ", element);
										await utils_files_add(element).then((data) => {
											if (data.error) {
												$patient = credentials;
												window.location.reload();
											} else {
												window.location.reload();
											}
										});
									});
								}
								console.log('#Personal-Information patient_patient_update final $user ', $user );
								console.log('#Personal-Information patient_patient_update final $patient ', $patient );
								console.log('#Personal-Information patient_patient_update final credentials ', credentials );
								window.location.reload();
							}
						});
					} else {
						replace("/PatientRecord");
					}
				}
				if (rol === ROLES.professional) {
					credentials.listLanguage = selectLanguage;
					if (edit) {
						loadingModal = true;
						// carga photo perfil
						await professional_account_update(credentials, "Professional").then(async (data) => {
							if (data.statusCode == 200) {
								$doctor = credentials;
								$user = credentials;
								console.log('handleSubmit await professional_account_update data ', data);

								if (credentials.hasOwnProperty("Archivos_S3__r")) {
									if (!credentials.hasOwnProperty("fileAttFotoPerfil")) {
										console.log("await professional_account_update if (!credentials.hasOwnProperty(fileAttFotoPerfil ");
										window.location.reload();
									}
									let resultado = await getProfilePhoto(credentials.Archivos_S3__r);
									console.log({ resultado });
									if (resultado.status) {
										console.log('#Personal-Information pre fileAttFotoPerfil ', credentials.fileAttFotoPerfil );
										let fileAttFotoPerfil = credentials.fileAttFotoPerfil.detail.fileData;
										fileAttFotoPerfil.forEach(async (element) => {
											element.creator = credentials.Id;
											console.log("entro 2");
											credentials.Archivos_S3__r.records.map((item) => {
												console.log(item);
												if (item.Link__c && item.Tipo__c == "Foto de perfil") {
													element.attachment = item.Account__c;
													element.oldPictureUrl = item.Link__c;
												}
											});
											await utils_files_update(element).then((data) => {
												if (data.error) {
													$patient = credentials;
													console.log("await utils_files_update error: ", data);
													window.location.reload();
												} else {
													console.log("await utils_files_update else: ", data);
													
													window.location.reload();
												}
											});
										});
									}
								}

								if (credentials.hasOwnProperty("fileAttFotoPerfil")) {
									console.log('#Personal-Information pre fileAttFotoPerfil if fileAttFotoPerfil  ',credentials.fileAttFotoPerfil );
									let fileAttFotoPerfil = credentials.fileAttFotoPerfil.detail.fileData;
									fileAttFotoPerfil.forEach(async (element) => {
										element.creator = credentials.Id;
										element.attachment = credentials.Id;
										console.log("fileAttFotoPerfil : ", element);
										await utils_files_add(element).then((data) => {
											if (data.error) {
												$patient = credentials;
												console.log('ERROR CARGA IMG data');
												console.log({data});
												window.location.reload();
												
											} else {
												console.log('ELSE CARGA IMG data');
												console.log({data});
												window.location.reload();

											// 	credentials.Archivos_S3__r.records.map((item) => {
											// 	console.log(item);
											// 	if (item.Tipo__c == "Foto de perfil") {
											// 		element.attachment = item.Account__c;
											// 		element.oldPictureUrl = item.Link__c;
											// 	}
											// });
												//  window.location.reload();
											}
										});
									});
								}

								window.location.reload();
							}
						});
					} else {
						console.log('#Personal-Information else : ', credentials.fileAttFotoPerfil);
						credentials.photo = credentials.fileAttFotoPerfil
							? credentials.fileAttFotoPerfil.detail.fileData[0].urlPhoto
							: "";
						$doctor = credentials;
						replace("/ProfessionalRecord");
					}
				}
				// if (rol === ROLES.caregiver) {
				// 	console.log("Llamando a saveCaregiver");
				// 	modalLoadingAnimation = true;
				// 	rol = $currentSession.attributes.profile;
				// 	username = $currentSession.attributes.email;
				// 	$carer = credentials;

				// 	await saveCaregiver(credentials, dataUser)
				// 		.then((data) => {
				// 			return data;
				// 		})
				// 		.then(async (result) => {
				// 			if (result.statusCode == 201) {
				// 				updateUserStatus("true");
				// 				let result = await getUserInfo(rol, username);
				// 				if (result) {
				// 					replace(`/Home`);
				// 				} else {
				// 					window.location.href = "#/LoginError";
				// 				}
				// 			} else {
				// 				openModal(() => import("../../components/ModalValidationsForm.svelte"), {
				// 					title: "Error",
				// 					message: result.message,
				// 				});
				// 			}
				// 		});
				// }
				console.log("handleSubmit fin");
			} catch (error) {
				console.log({ error });
			}
		}
	};
	/**
	 * @description
	 */
	function handleFileChange(e) {
		console.log("handleFileChange: ", e);
		let myObjArray = Object.entries(e.detail);
		let r = myObjArray[0];
		console.log({ r });
		let picture = document.getElementById("photoID");
		picture.setAttribute("src", r[1]?.fileData[0]?.urlPhoto);
		console.log({ picture });
		picture.src = r[1]?.fileData[0]?.urlPhoto;
	}
	/**
	 * @description
	 */
	async function handlePhoto() {
		document.getElementById("photoID").src = photo;
		document.getElementById("fileInput").click();
	}
	//let value;
	/**
	 * @description
	 */
	function getGender(firstLetter) {
		if (firstLetter != "") {
			if (firstLetter.substring(0, 1) === "M") {
				return "M";
			} else if (firstLetter.substring(0, 1) === "F") {
				return "F";
			} else {
				return "";
			}
		}
	}
	/**
	 * @description
	 * onMount
	 */
	onMount(async () => {
		console.time("miTiempo");
		errors = {};
		success = false;
		coberturasList = await utils_resources_get("prepaidMedicine");
		countryList = await utils_resources_get("country", langSelect);
		listLanguage = await utils_resources_get("languages", langSelect);
		genderOptions = $_("PersonalData.select_gender");
		//setSelectDisabled(provincias.length === 0);

		if ($currentSession == {}) {
			replace("/");
		} else {
			console.log({ credentials });
			let dataUser = $currentSession;
			rol = dataUser["attributes"] ? dataUser.attributes["profile"] : "";
			if (credentials.listLanguage == undefined) credentials.listLanguage = [];
			if (credentials.structlistLanguage == undefined) credentials.structlistLanguage = [];
			// if (rol === ROLES.caregiver) {
			// 	console.log("Rol:caregiverRoles");
			// 	//rolItems = await utils_resources_get("caregiverRoles");
			// }
			console.log("ROLES.patient ", ROLES.patient , " rol ", rol);
			if (rol === ROLES.patient || rol === "Patient" || rol === "Paciente") {
				console.log("Rol:patient $patient", $patient );
				console.log("Rol:patient $dataPatient ", $dataPatient );
				if (!edit) {
					credentials = $patient;
					console.log("credentials not Edit: ", credentials);
				} else {
					credentials = $user;
					console.log("credentials Edit: ", credentials);
				}

				if (credentials && credentials.hasOwnProperty("BillingState")) {
					stateName = credentials.BillingState.label;
					credentials.BillingState = { label: credentials.BillingState.label, id: credentials.BillingState.Id };
				}
				console.log("stateName: ", stateName);

				if (credentials && credentials.hasOwnProperty("BillingCountry")) {
					console.log("credentials: ", credentials.BillingCountry);
					countryName = credentials.BillingCountry.label
						? credentials.BillingCountry.label
						: credentials.BillingCountry;
					countryId = credentials.BillingCountry.Id;
					console.log("countryName: ", countryName);
				}
				console.log(' credentials ountryName == undefined ' );
				console.log({ credentials });
				if (countryName == undefined) {
					// if ( credentials.Country__r.length() > 0)  { 
						countryName = credentials.Country__r.Name_ES__c;						
					// } else {
					// 	countryName = $user.BillingCountry
					// }
					countryId = credentials.Country__r.Id;
				}
				console.log('#Personal-Information $dataPatient ', $dataPatient);
				if (credentials.hasOwnProperty("Archivos_S3__r")) {
					console.log("photo Archivos_S3__r: ", credentials.Archivos_S3__r.records);
					let fileAd = credentials.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							photo = element.Link__c;
						}
					});
				} 
				// if ($dataPatient.hasOwnProperty("Archivos_S3__r")) {
				// 	let fileAd = $dataPatient.Archivos_S3__r.records;
				// 	fileAd.forEach((element) => {
				// 		if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
				// 			fotoPerfil = element.Link__c;
				// 		}
				// 	});
				// }
				
				if (credentials.hasOwnProperty("fileAttFotoPerfil")) {
					console.log("photo fileAttFotoPerfil: ", credentials.fileAttFotoPerfil.detail.fileData );
					let fileAd = credentials.fileAttFotoPerfil.detail.fileData;
					//console.log({ fileAd });
					fileAd.forEach((element) => {
						//console.log({ element });
						if (element.hasOwnProperty("urlPhoto")) {
							photo = element.urlPhoto;
						}
					});
				}
				codA1 = credentials.Phone ? credentials.Phone.split("-")[0] : "";
				Phone1 = credentials.Phone ? credentials.Phone.split("-")[1] : "";
				codA2 = credentials.PersonMobilePhone ? credentials.PersonMobilePhone.split("-")[0] : "";
				Phone2 = credentials.PersonMobilePhone ? credentials.PersonMobilePhone.split("-")[1] : "";

				// if (credentials.BillingCountry) {
				// 	countryName = credentials.BillingCountry;
				// 	countryId = credentials.BillingCountry.Id;
				// }
				// if (credentials.BillingState) {
				// 	stateName = credentials.BillingState;
				// }
				console.log("Rol:patient Fin");
			}
			if (rol === ROLES.professional || rol === "Professional") {
				console.log("Rol:professional");
				if (!edit) {
					let existingData = localStorage.getItem("doctor");
					let parsedData = JSON.parse(existingData);
					credentials = parsedData ? parsedData : {};
				} else {
					credentials = $user;
					if (credentials == {}) {
						credentials = $doctor;
					}
				}

				if (credentials && credentials.hasOwnProperty("State__r")) {
					stateName = credentials.State__r.Name_ES__c;
					credentials.BillingState = { label: credentials.State__r.Name_ES__c, id: credentials.State__r.Id };
				} else if (credentials.hasOwnProperty("BillingState")) {
					console.log("credentials: ", credentials);
					// stateName = credentials.BillingState.label;
					stateName = credentials.BillingState;
				}

				if (credentials && credentials.hasOwnProperty("BillingCountry")) {
					console.log("credentials: ", credentials.BillingCountry);
					// countryName = credentials.BillingCountry.label;
					countryName = credentials.BillingCountry
					countryId = credentials.BillingCountry.Id;
					console.log("countryName: ", countryName);
				}
				if (countryName == undefined) {
					countryName = credentials.Country__r.Name_ES__c;
					countryId = credentials.Country__r.Id;
				}

				if (credentials.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = credentials.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							photo = element.Link__c;
						}
					});
				}

				codA1 = credentials.Phone ? credentials.Phone.split("-")[0] : "";
				Phone1 = credentials.Phone ? credentials.Phone.split("-")[1] : "";
				codA2 = credentials.PersonMobilePhone ? credentials.PersonMobilePhone.split("-")[0] : "";
				Phone2 = credentials.PersonMobilePhone ? credentials.PersonMobilePhone.split("-")[1] : "";

				if (credentials.listLanguage == undefined) credentials.listLanguage = [];
				if (credentials.Idioma__c && credentials.Idioma__c.length > 0) {
					let searchValues = credentials.Idioma__c;
					console.log({ listLanguage });
					let result = listLanguage.filter((item) => searchValues.includes(item.id));

					credentials.structlistLanguage = result;
				}

				// if (credentials.hasOwnProperty("BillingCountry")) {
				// 	provincias = await utils_resources_get("state", langSelect, credentials.BillingCountry.id);

				// }
				console.log({ countryList });
				console.log({ listLanguage });
			}
		}

		credentials.HealthCloudGA__Gender__pc = getGender(credentials.HealthCloudGA__Gender__pc || "M");
		//credentials.BillingState = provincias.find((c) => c.id === credentials.State__c);
		//credentials.BillingCountry = countryList.find((c) => c.id === credentials.Country__c);
		//console.log("credentials.BillingState: ", credentials.BillingState);
		if ($currentSession["attributes"]["custom:dni"] && credentials.DNI__c == "") {
			credentials.DNI__c = $currentSession["attributes"]["custom:dni"].split("-")[1];
		}
		if (credentials.DNI__c == undefined) {
			credentials.DNI__c = $currentSession["attributes"]["custom:dni"].split("-")[1];
		}

		if (photo == "./img/perfil_none.png") {
			photo = credentials.photo ? credentials.photo : "./img/perfil_none.png";
		}

		console.log("ACA se fue al 2", countryId);

		await loadStates(countryId);
		setTimeout(() => {
			statusView = true;
		}, 7000);
		console.log("credentials final: ", credentials);
		console.timeEnd("miTiempo");
	});
</script>

<!-- --------------------------------------------------------------------------------------------------------- -->
<!-- --------------------------------------------------------------------------------------------------------- -->
<!-- --------------------------------------------------------------------------------------------------------- -->
<div class="register-info-container">
	{#if coberturasList.length != 0 && listLanguage.length != 0 && !loadingModal}
		<form on:submit|preventDefault={handleSubmit}>
			<div class="container">
				<!-- Personal Information -->
				<div class="border shadow-sm m-3 p-4 rounded bg-white">
					<h1 class="title">{$_("PersonalData.text_title_information")}</h1>
					<div class="dataPersonaldiv row">
						<!-- {#if !edit} -->
						<div class="img_perfil">
							<div class="perfilIMG my-3">
								<img
									id="photoID"
									class="imgPerfil cursor-pointer"
									style="width:150px; height:150px;top:8; left:1;"
									src={photo}
									on:click={handlePhoto}
									alt="-photo-personal-information" />
								<img
									id="photoID"
									class="imgPerfil-cam cursor-pointer"
									style="width:70px; height:65px; transform: translate(50px, -20px);"
									src="./img/addprofile.jpg"
									on:click={handlePhoto}
									alt="no-photo-personal-information" />

								<!-- svelte-ignore component-name-lowercase -->

								<FileAttach
									{idTypeAttachment}
									multipleState={false}
									photoState={false}
									formatState={true}
									{file_type}
									on:dataFile={async (e) => {
										credentials.fileAttFotoPerfil = e.detail;
										console.log({ e });
										handleFileChange(e);
									}} />
							</div>

							{#if errors.photo}
								<small class="error">{errors.photo}</small>
							{/if}
						</div>
						<!-- {/if} -->
						<div class="data-personal d-flex">
							<div class="row">
								<!-- FirstName -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_name")}</small>
									<small class="error">*</small>
									<input
										type="text"
										class="form-control"
										bind:value={credentials.FirstName}
										on:keypress={validateOnlyLetters} />
									{#if errors.FirstName}
										<small class="error">{errors.FirstName}</small>
									{/if}
								</div>
								<!-- LastName -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_lastname")}</small><small
										class="error">*</small>
									<input
										type="text"
										class="form-control"
										bind:value={credentials.LastName}
										on:keypress={validateOnlyLetters} />
									{#if errors.LastName}
										<small class="error">{errors.LastName}</small>
									{/if}
								</div>
							</div>
							<div class="row">
								<!-- DNI -->
								<div class="col-sm-6">
									<small class="text-muted">DNI</small><small class="error">*</small>
									<input
										class="input-log"
										type="text"
										placeholder="xx.xxx.xxx"
										bind:value={credentials.DNI__c}
										on:keypress={validateOnlyNumbers}
										disabled={credentials.DNI__c ? true : false} />
									{#if errors.DNI__c}
										<small class="error">{errors.DNI__c}</small>
									{/if}
								</div>
								<!-- Birthday -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_birthdate")}</small><small
										class="error">*</small>
									<input
										class="input-log"
										type="date"
										id="startPersonBirthdate"
										pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
										name="trip-start"
										placeholder={getDateFormat()}
										bind:value={credentials.PersonBirthdate}
										on:blur={() => {
											credentials.PersonBirthdate = validateOnlyDate(credentials.PersonBirthdate);
										}} />
									{#if errors.PersonBirthdate}
										<small class="error">{errors.PersonBirthdate}</small>
									{/if}
								</div>
							</div>
							<div class="" style="width: 106%;">
								<!-- Gender -->
								<div>
									<small class="text-muted">{$_("PersonalData.text_gender")}</small><small
										class="error">*</small>
									<select class="gender" bind:value={credentials.HealthCloudGA__Gender__pc}>
										{#each genderOptions as question}
											<option value={question.id}>
												{question.text}
											</option>
										{/each}
									</select>
									{#if errors.HealthCloudGA__Gender__pc}
										<small class="error">{errors.HealthCloudGA__Gender__pc}</small>
									{/if}
								</div>
							</div>
							<div class="row">
								<!-- Phonenumber -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_phone")}</small>
									<div>
										<input
											class="codigoArea"
											type="text"
											bind:value={codA1}
											on:keypress={validateOnlyNumbers}
											onpaste="return false;" />
										<small class="text-muted"> - </small>
										<input
											type="text"
											class="form-control-cel"
											bind:value={Phone1}
											on:keypress={validateOnlyNumbers}
											onpaste="return false;" />
									</div>

									<!-- {#if errors.phone}
										<small class="error">{errors.phone}</small>
									{/if} -->
								</div>
								<!-- Cellphone -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_cell")}</small><small class="error"
										>*</small>
									<div>
										<input
											class="codigoArea"
											type="text"
											bind:value={codA2}
											on:keypress={validateOnlyNumbers}
											onpaste="return false;" />
										<small class="text-muted"> - </small>
										<input
											type="text"
											class="form-control-cel"
											bind:value={Phone2}
											on:keypress={validateOnlyNumbers}
											onpaste="return false;" />
									</div>
									{#if errors.PersonMobilePhone}
										<p class="error">{errors.PersonMobilePhone}</p>
									{/if}
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Address information -->
				<div class="border shadow-sm m-3 p-4 rounded bg-white">
					<h1 class="title">{$_("PersonalData.text_title_addres")}</h1>
					<div class="row">
						<div class="data-domicilio d-flex">
							<div class="domicilioID">
								<!-- Street -->
								<div class="col-sm-7">
									<small class="text-muted">{$_("PersonalData.text_addres")}</small><small
										class="error">*</small>
									<input type="text" class="form-control" bind:value={credentials.R1_streetName__c} />
									{#if errors.addres}
										<small class="error">{errors.addres}</small>
									{/if}
								</div>
								<!-- Number -->
								<div class="col-sm-2">
									<small class="text-muted">{$_("PersonalData.text_addresNumber")}</small>
									<input
										type="text"
										class="form-control"
										bind:value={credentials.R1_streetNumber__c}
										on:keypress={validateOnlyNumbers} />
										{#if errors.addresNumber}
										<small class="error">{errors.addresNumber}</small>
									{/if}
								</div>
								<!-- Aparment -->
								<div class="col-sm-3">
									<small class="text-muted">{$_("PersonalData.text_addresApartment")}</small>
									<input
										type="text"
										class="form-control"
										bind:value={credentials.R1_streetDetail__c} />
									{#if errors.addres}
										<small class="error" />
									{/if}
								</div>
							</div>

							<div class="paisID">
								<!-- Country -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_country")}</small><small
										class="error">*</small>
									<select class="gender" bind:value={countryName} on:change={onCountrySelect}>
										{#each countryList as country}
											<option value={country.label}>
												{country.label}
											</option>
										{/each}
									</select>
									<!-- <input type="text" class="form-control" bind:value={credentials.country} /> -->
									{#if errors.BillingCountry}
										<p class="error">{errors.BillingCountry}</p>
									{/if}
								</div>
								<!-- City / State -->
								<div class="col-sm-6">
									<small class="text-muted">{$_("PersonalData.text_BillingState")}</small><small
										class="error">*</small>
									<select
										id="billingState"
										class="gender"
										bind:value={stateName}
										on:change={onStateSelect}
										on:blur={onStateSelect}>
										{#each provincias as provincia}
											<option value={provincia.label}>
												{provincia.label}
											</option>
										{/each}
									</select>
									{#if errors.BillingState}
										<p class="error">{errors.BillingState}</p>
									{/if}
								</div>
							</div>
						</div>
					</div>
				</div>
				{#if (rol == ROLES.patient || rol == "Paciente" )|| rol == ROLES.caregiver}
					<!-- Prepaid medicine information -->
					<div class="border shadow-sm m-3 p-4 rounded bg-white">
						<h1 class="title">{$_("PersonalData.text_title_Coverage_data")}</h1>
						<div>
							<div class="data-cobertura d-flex">
								<div class="coberturaID">
									<!-- Prepaid_name -->
									<div class="col-sm-8">
										<small class="text-muted">{$_("PersonalData.text_Coverage_data")}</small><small
											class="error">*</small>
										<select class="gender" bind:value={credentials.Cobertura_Salud__c}>
											{#each coberturasList as prepaid}
												<option value={prepaid.label}>
													{prepaid.label}
												</option>
											{/each}
										</select>
										{#if errors.prepaid}
											<p class="error">{errors.prepaid}</p>
										{/if}
									</div>
									<!-- Prepaid_number -->
									<div class="col-sm-4">
										<small class="text-muted">{$_("PersonalData.text_affiliateNumber")}</small>
										{#if credentials.Cobertura_Salud__c !== "Particular"}
											<small class="error">*</small>
										{/if}

										<input
											type="text"
											class="form-control"
											bind:value={credentials.Nro_de_afiliado__c}
											on:keypress={validateOnlyNumbers} />
										{#if errors.Nro_de_afiliado__c}
											<p class="error">{errors.Nro_de_afiliado__c}</p>
										{/if}
									</div>
								</div>
							</div>
						</div>
					</div>
				{/if}
				{#if rol === ROLES.professional}
					<!-- Language information -->
					<div class="border shadow-sm m-3 p-4 rounded bg-white">
						<h1 class="title">{$_("PersonalData.text_title_language")}</h1>
						<div>
							<div class="data-cobertura d-flex">
								<div class="coberturaID">
									<!-- language -->
									<div class="col-sm-12">
										<MultiSelect
											id="lang"
											bind:value={selectLanguage}
											editSelect={credentials.structlistLanguage}>
											{#each listLanguage as lan}
												<option value={lan.value}>{lan.label}</option>
											{/each}
										</MultiSelect>
									</div>
								</div>
							</div>
						</div>
					</div>
				{/if}
			</div>

			<!-- Submit -->
			<div class="button">
				{#if !edit}
					{#if rol !== "Familiar_Cuidador"}
						<button on:click={() => replace("/")} id="nextButton" class="btnWhite"
							>{$_("PersonalData.button_return")}</button>
					{/if}
					<button id="nextButton" class="btnBlue">{$_("PersonalData.button_Next")}</button>
				{:else}
	<!-----------------------------AÑADI BOTON VOLVER AL FORM----------------------------------------------->
				<button on:click={() => replace("#/Patient")} id="nextButton" class="btnWhite"
					>{$_("PersonalData.button_return")}</button>
					<button id="nextButton" class="btnBlue">{$_("PersonalData.button_save")}</button>
					
				{/if}
			</div>
		</form>
	{:else if !edit}
		<div class="text-center py-3">
			<Loader text={false} />
			<p class="text-muted">{$_("PersonalData.loading")}</p>
		</div>
		<!-- SE COMENTA PARA QUE NO SALGA ICONO CARGANDO DENTRO DE MODAL  ----------->
	{:else if edit}
		<div class="text-center p-3">
			<Loader text={false} />
			<!-- CAMBIE PORQUE DECIA SALVANDO Y DEBE SER CARGANDO-->
			<p class="text-muted">{$_("PersonalData.loading")}</p>
		</div>  
	{/if}
</div>

<!-- --------------------------------------------------------------------------------------------------------- -->
<!-- --------------------------------------------------------------------------------------------------------- -->

<!-- --------------------------------------------------------------------------------------------------------- -->
<style>
	.imgPerfil {
		width: 150px;
		border-radius: 50%;
		background-size: 120%;
	}
	.button {
		text-align: right;
		width: 95%;
		margin-block: 5%;
	}
	.input-log {
		width: 100%;
	}
	/* .langExtra {
		width: 90%;
	} */
	.data-personal {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: column;
		align-content: center;
		margin-left: 5%;
		margin-right: 5%;
		text-align: initial;
	}
	.img_perfil {
		width: 350px;
		border-radius: 50%;
		background-size: 120%;
	}

	.dataPersonaldiv {
		flex-wrap: initial;
		text-align: center;
	}
	.data-domicilio {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: row;
		align-content: space-between;
		align-items: baseline;
		width: 100%;
	}
	.gender {
		width: 100%;
	}

	.codigoArea {
		width: 30%;
	}
	.form-control-cel {
		width: 63%;
	}
	/* .form-control-tel {
		width: 60%;
	} */
	.domicilioID {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
	.paisID {
		display: flex;
		width: 100%;
	}
	.coberturaID {
		display: flex;
		width: 100%;
	}
	.btnBlue {
		height: 45px;
		width: 90px;
		text-align-last: center;
	}
	.btnWhite {
		height: 45px;
		width: 90px;
		text-align-last: center;
	}
	.data-cobertura {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: space-between;
		align-items: baseline;
		width: 100%;
	}
	.register-info-container {
		margin: 3%;
	}
	.title {
		font-size: 20px;
		text-align: center;
		color: #0C0C0C;
	}
	.form-control {
		padding: 10px;
		/* margin-bottom: 10px; */
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.perfilIMG {
		margin-left: 5%;
		text-align: center;
	}
	.error {
		color: red;
	}

	/* Estilos para la versión de escritorio (Web) */
	@media screen and (min-width: 1200px) {
		/* Agrega aquí los estilos para la versión de escritorio */
		.row {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
		}
		.perfilIMG {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-wrap: wrap;
			align-content: center;
		}
		.img_perfil {
			/* width: 350px; */
			border-radius: 50%;
			background-size: 120%;
		}
		.imgPerfil-cam {
			width: 150px;
			border-radius: 50%;
			background-size: 120%;
		}
		.container {
			width: 100%;
			margin-right: auto;
			margin-left: auto;
		}
		.domicilioID {
			display: contents;
			width: 100%;
		}
		.paisID {
			display: contents;
			width: 100%;
		}
		.coberturaID {
			display: contents;
			width: 100%;
		}

		.data-personal {
			display: flex !important;
			flex-wrap: wrap;
			justify-content: space-between;
			flex-direction: column;
			align-content: space-between;
			align-items: initial;
			margin-left: 0%;
		}
		.button {
			text-align: center;
			margin-block: 5%;
		}
	}

	/* Estilos para tablets */
	@media screen and (max-width: 1024px) {
		/* Agrega aquí los estilos para tablets en orientación horizontal */
		.row {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: column;
		}
		.perfilIMG {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-wrap: wrap;
			align-content: center;
		}
		.img_perfil {
			width: 100%;
		}
		.container {
			width: 100%;
			margin-right: auto;
			margin-left: auto;
		}
		.domicilioID {
			display: contents;
			width: 100%;
		}
		.paisID {
			display: contents;
			width: 100%;
		}
		.coberturaID {
			display: contents;
			width: 100%;
		}
		.data-personal {
			display: flex !important;
			flex-wrap: wrap;
			justify-content: space-between;
			flex-direction: column;
			align-content: space-between;
			align-items: initial;
			margin-left: 0%;
		}
		.button {
			text-align: center;
			margin-block: 5%;
		}
	}

	/* Estilos para dispositivos móviles */
	@media screen and (max-width: 480px) {
		/* Agrega aquí los estilos para dispositivos móviles */
		.imgPerfil-cam {
			opacity: 0;
		}
	}

	/* Estilos adicionales para tablets en orientación vertical */
	@media screen and (max-width: 768px) {
		/* Agrega aquí los estilos para tablets en orientación vertical */
	}
	.row {
		display: flex;
		flex-wrap: nowrap;
	}
	.perfilIMG {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: wrap;
		align-content: center;
	}
	.img_perfil {
		/* width: 350px; */
		border-radius: 50%;
		background-size: 120%;
	}
	.container {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
	.domicilioID {
		display: contents;
		width: 100%;
	}
	.paisID {
		display: contents;
		width: 100%;
	}
	.coberturaID {
		display: contents;
		width: 100%;
	}
	.data-personal {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: space-between;
		align-items: initial;
		margin-left: 0%;
	}
	.button {
		text-align: center;
		margin-block: 5%;
	}
</style>
