<script>
	/**
	 * Pass Component as parameter
	 * https://linguinecode.com/post/how-to-pass-a-svelte-component-to-another-svelte-component
	 *
	 * Modal Source Original Code
	 * https://blog.hdks.org/Modal-Window-in-Svelte/
	 */

	import { createEventDispatcher, onMount } from "svelte";
	export let isOpenModal;
	export let title = "";
	export let block = false;
	export let tam = "";

	//console.log({ tam });
	let statusTam = true;
	// Close Modal
	const dispatch = createEventDispatcher();
	function closeModal() {
		console.log({ isOpenModal });
		if (!block) {
			isOpenModal = false;
			dispatch("closeModal", {
				isOpenModal,
				confirm: false,
			});
		}
	}
</script>

<div id="background" style="--display: {isOpenModal ? 'block' : 'none'};" />
<div
	id="modal"
	style="--display: {isOpenModal ? 'block' : 'none'}; width:{tam != '' ? tam : '40'}%"
	class="modal_conteiner p-5 rounded">
	<div
		class="cursor pointer"
		on:click={() => {
			isOpenModal = false;
			closeModal();
		}}>
		<!----------------COMENTE PARA LUEGO ELIMINARLO
		<span class="close">x</span>
		-->
	</div>

	<p class="title-error text-center">{title}</p>
	<slot />
</div>

<style>
	#background {
		background-color: rgba(0, 0, 0, 0.8);
		display: var(--display);
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	#modal {
		display: var(--display);
		position: fixed;
		max-height: 600px;
		overflow-y: auto;
		padding: 1rem !important;
		z-index: 2;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 8px !important;
		background: #fff;
	}
	.modal_conteiner {
		width: 30%;
	}
	.title-error {
		color: #0C0C0C;
		font-weight: bold;
		width: 100%;
		text-align: initial;
		margin: initial;
	}
	.close {
		color: #aaa;
		position: absolute;
		top: 10px;
		right: 20px;
		font-size: 28px;
		font-weight: bold;
		cursor: pointer;
		z-index: 9999;
	}
	@media screen and (max-width: 1000px) {
		.modal_conteiner {
			width: 40%;
		}
	}
</style>
