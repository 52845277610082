<script>
	import { user } from "../../helpers/storage/stores.js";
	export let myPage = "default";
	export let buttomHC = "";
	export let buttomHCHref = "";
	export let buttomPlan = "";
	export let buttomPlanHref = "";
	export let buttomDiag = "";
	export let buttomDiagHref = "";
	export let selected = "";

	if ($user.hasOwnProperty("Especialidades__r")) {
		buttomPlanHref = "#/Plan";
		buttomDiag = "";
	} else {
		buttomPlanHref = "#/Patient/Plan";
	}
</script>

<section class="">
	<div>
		<div class="myCustom-tittle text-center">
			{#if myPage}
				<h3 class="m-0 p-3">{myPage}</h3>
			{/if}
		</div>
		<div class="d-flex justify-content-between">
			{#if buttomDiag == "Info"}
				<button
					on:click={() => {
						window.location.href = `${buttomDiagHref}`;
					}}
					class="{selected == 'Info' ? 'myCustom-selected' : 'myCustom-doctorHeaderButtom'} text-white"
					style="width: 100%;">Informacion</button>
			{/if}
			{#if buttomDiag != ""}
				<button
					on:click={() => {
						window.location.href = `${buttomDiagHref}`;
					}}
					class="{selected == 'DiagnosisByProf'
						? 'myCustom-selected'
						: 'myCustom-doctorHeaderButtom'} text-white"
					style="width: 100%;">{buttomDiag}</button>
			{/if}
			{#if buttomPlan != ""}
				<button
					on:click={() => {
						window.location.href = `${buttomPlanHref}`;
					}}
					class="{selected == 'Plan' ? 'myCustom-selected' : 'myCustom-doctorHeaderButtom'} text-white"
					style="width: 100%;">{buttomPlan}</button>
			{/if}
			{#if buttomHC != ""}
				<button
					on:click={() => {
						window.location.href = `${buttomHCHref}`;
					}}
					class="{selected == 'ClinicHistory'
						? 'myCustom-selected'
						: 'myCustom-doctorHeaderButtom'} text-white"
					style="width: 100%;">{buttomHC}</button>
			{/if}
		</div>
	</div>
	<slot />
</section>

<style>
	.myCustom-tittle {
		background-color: #420EAA;
		color: #ffffff;
	}
	.myCustom-selected {
		background-color: #6a91ab;
		border: none;
	}
	.myCustom-doctorHeaderButtom {
		border: none;
		background-color: #a6c8de;
	}
	.myCustom-doctorHeaderButtom:hover {
		background-color: #6a91ab;
	}
</style>
