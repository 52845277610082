<script>
	// Components / Layouts
	import Loader from "../../../components/Loader.svelte";

	// Helpers
	import { language, patient, user } from "../../../helpers/storage/stores";
	import {
		professional_questionnaire_add,
		professional_questionnaires_master_get,
		utils_resources_get,
	} from "../../../helpers/apiBackend.js";

	// Svelte
	import { createEventDispatcher, onMount } from "svelte";
	import { _ } from "svelte-i18n";
	
	const dispatch = createEventDispatcher();

	let listMaster = ["actividad 1", "actividad 2"],
		listFrequencies = [],
		listPersons = [],
        listWhoms = [],
        listVariables = [],
        listAdministrations = [];
	let careplan_id = $patient.careplan_id;
	let selectedActivity, selectedActivityName;
	let selectedFrequency, selectedPerson, selectedWhom, selectedVariable, selectedAdministration, selectedExternalId, selectedExternalLink;
	let loader = true;
	let [checkConfirm, modalError] = [false, false];
	let messageError = "";
	let myBodyCreate = {
		masterId: 0,
		patientId: $patient.Id,
		planId: "",
		doctorId: $user.Id,
		frequencyId: 0,
		personId: 0,
        whomId: 0,
        variableId: 0,
        administrationId: 0,
        externalId: 0,
        externalLink: "",
		name: "",
	};

	/**
	 * @description
	 */
	const NewActivity = async (selectedActivity) => {
		// Armo body
		myBodyCreate.masterId = selectedActivity;
		myBodyCreate.planId = careplan_id;
		myBodyCreate.frequencyId = selectedFrequency;
		myBodyCreate.personId = selectedPerson;
        myBodyCreate.whomId = selectedWhom;
        myBodyCreate.variableId = selectedVariable;
        myBodyCreate.administrationId = selectedAdministration;
		myBodyCreate.name = selectedActivityName;
        myBodyCreate.externalId = selectedExternalId;
        myBodyCreate.externalLink = selectedExternalLink;

        console.log("Data a crear",myBodyCreate);
		// Creación
		await professional_questionnaire_add(myBodyCreate).then((result) => {
			if (result.statusCode == 200) {
				loader = false;
				dispatch("questionnaireCreated");
			} else {
				modalError = true;
				messageError = result.message || "Error";
			}
			console.log(result);
		});
	};

	/**
	 * @description
	 */
	onMount(async () => {
		console.log("llego a interview add");
		listMaster = await professional_questionnaires_master_get($language);
		listFrequencies = await utils_resources_get("frecuencies", $language);
		listPersons = await utils_resources_get("Person",$language)
        listWhoms = await utils_resources_get("Whom",$language)
        listVariables = await utils_resources_get("Variable",$language)
        listAdministrations = await utils_resources_get("Administration",$language)
		loader = false;
	});
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<!-- <LayoutPatient> -->
	<div class="container border p-3 shadow-sm rounded my-2 bg-white">
		<div class="text-muted border-bottom">
			<h3>{$_("CreateActivity.text_new_activity")}</h3>
		</div>
		{#if loader}
			<div class="text-center p-3">
				<Loader text={false} />
				<p class="text-muted">{$_("CreateActivity.loading")}</p>
			</div>
		{:else}
			<div class="py-3">
				{#if listMaster.length == 0}
					<div class="text-muted">
						<p>{$_("CreateActivity.text_not_activities_to_add")}...</p>
					</div>
				{:else}
					<div class="form-group d-flex">
						<label style="width: 250px;" for="activity-type"
							>{$_("CreateActivity.text_type_activities")}</label>
						<select
							bind:value={selectedActivity}
							class="form-control"
							name="activity-type"
							id=""
							on:change={() => {
								const selectedOption = listMaster.find((item) => item.Id == selectedActivity);
                                console.log("Selected Activity", selectedOption);
								selectedActivityName = selectedOption?.label; // Actualiza el label
                                selectedExternalId = selectedOption.externalId; // Actualiza ExternalId
			                    selectedExternalLink = selectedOption.externalLink; // Actualiza ExternalLink
							}}>
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listMaster as myActivity}
								<option value={myActivity.Id}>{myActivity.label}</option>
							{/each}
						</select>
					</div>
					<div class="form-group d-flex">
						<label style="width: 250px;" for="activity-type">Frecuencia</label>
						<select bind:value={selectedFrequency} class="form-control" name="activity-type" id="">
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listFrequencies as myItem}
								<option value={myItem.Id}>{myItem.label}</option>
							{/each}
						</select>
					</div>
					<div class="form-group d-flex">
						<label style="width: 250px;" for="activity-type">Persona que encuesta</label>
						<select bind:value={selectedPerson} class="form-control" name="activity-type" id="">
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listPersons as myItem}
								<option value={myItem.Id}>{myItem.label}</option>
							{/each}
						</select>
					</div>
                    <div class="form-group d-flex">
						<label style="width: 250px;" for="activity-type">A quien</label>
						<select bind:value={selectedWhom} class="form-control" name="activity-type" id="">
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listWhoms as myItem}
								<option value={myItem.Id}>{myItem.label}</option>
							{/each}
						</select>
					</div>
                    <div class="form-group d-flex">
						<label style="width: 250px;" for="activity-type">Variable</label>
						<select bind:value={selectedVariable} class="form-control" name="activity-type" id="">
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listVariables as myItem}
								<option value={myItem.Id}>{myItem.label}</option>
							{/each}
						</select>
					</div>
                    <div class="form-group d-flex">
						<label style="width: 250px;" for="activity-type">Administración</label>
						<select bind:value={selectedAdministration} class="form-control" name="activity-type" id="">
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listAdministrations as myItem}
								<option value={myItem.Id}>{myItem.label}</option>
							{/each}
						</select>
					</div>
					<div>
						<div class="mx-2">
							<input class="" type="checkbox" bind:checked={checkConfirm} />
							<label class="" for="">{$_("CreateActivity.text_message_activity")}</label>
						</div>
					</div>
				{/if}
			</div>
			<div class="my-3 border-top">
				<div class="my-3 d-flex justify-content-center">
				
					{#if checkConfirm && selectedActivity.id != "*" && selectedFrequency != "*" && selectedPerson != "*" && selectedWhom != "*" && selectedVariable != "*" && selectedAdministration != "*"}
						<button
							class="btnBlue ms-2"
							on:click={() => {
								loader = true;
								NewActivity(selectedActivity);
							}}>
							{$_("CreateActivity.button_Create")}
						</button>
					{/if}
					<button
					class="btnWhite me-2"
					style="margin-left: 15px;"
					on:click={() => {
						dispatch("closeModal");
					}}>
					{$_("CreateActivity.button_return")}
				</button>
				</div>
			</div>
		{/if}
		
	</div>
	<!-- </LayoutPatient> -->
	<!-- </LayoutNav> -->
</section>


