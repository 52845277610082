<script>
	// Components / Layouts
	import Loader from "../../../components/Loader.svelte";

	// Helpers
	import { language, patient, user } from "../../../helpers/storage/stores";
	import {
		professional_exam_add,
		professional_exams_master_get,
		utils_resources_get,
	} from "../../../helpers/apiBackend.js";

	// Svelte
	import { createEventDispatcher, onMount } from "svelte";
	import { _ } from "svelte-i18n";
	
	const dispatch = createEventDispatcher();

	let listMaster = ["actividad 1", "actividad 2"],
		listFrequencies = [],
		listModalities = [];
	let careplan_id = $patient.careplan_id;
	let selectedActivity = { id: "*", label: "Seleccionar..." };
	let selectedFrequency, selectedModalitiy;
	let loader = true;
	let [checkConfirm, modalError] = [false, false];
	let messageError = "";
	let myBodyCreate = {
		masterId: 0,
		patientId: $patient.Id,
		planId: "",
		doctorId: $user.Id,
		frequencyId: 0,
		modalityId: 0,
		name: "",
	};

	/**
	 * @description
	 */
	const NewActivity = async (selectedActivity) => {
		// Armo body
		myBodyCreate.masterId = selectedActivity.id;
		myBodyCreate.planId = careplan_id;
		myBodyCreate.frequencyId = selectedFrequency;
		myBodyCreate.modalityId = selectedModalitiy;
		myBodyCreate.name = selectedActivity.label;

		// Creación
		await professional_exam_add(myBodyCreate).then((result) => {
			if (result.statusCode == 200) {
				loader = false;
				dispatch("examCreated");
			} else {
				modalError = true;
				messageError = result.message || "Error";
			}
			console.log(result);
		});
	};

	/**
	 * @description
	 */
	onMount(async () => {
		console.log("llego a exam add");
		listMaster = await professional_exams_master_get($language);
		listFrequencies = await utils_resources_get("frecuencies", $language);
		listModalities = await utils_resources_get("modalities", $language);
		loader = false;
	});
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<!-- <LayoutPatient> -->
	<div class="container border p-3 shadow-sm rounded my-2 bg-white">
		<div class="text-muted border-bottom">
			<h3>{$_("CreateActivity.text_new_activity")}</h3>
		</div>
		{#if loader}
			<div class="text-center p-3">
				<Loader text={false} />
				<p class="text-muted">{$_("CreateActivity.loading")}</p>
			</div>
		{:else}
			<div class="py-3">
				{#if listMaster.length == 0}
					<div class="text-muted">
						<p>{$_("CreateActivity.text_not_activities_to_add")}...</p>
					</div>
				{:else}
					<div class="form-group d-flex">
						<label style="width: 200px;" for="activity-type"
							>{$_("CreateActivity.text_type_activities")}</label>
						<select
							bind:value={selectedActivity.id}
							class="form-control"
							name="activity-type"
							id=""
							on:change={() => {
								const selectedOption = listMaster.find((item) => item.Id == selectedActivity.id);
								selectedActivity.label = selectedOption?.label; // Actualiza el label
							}}>
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listMaster as myActivity}
								<option value={myActivity.Id}>{myActivity.label}</option>
							{/each}
						</select>
					</div>
					<div class="form-group d-flex">
						<label style="width: 200px;" for="activity-type">{$_("CreateActivity.text_frecuency")}</label>
						<select bind:value={selectedFrequency} class="form-control" name="activity-type" id="">
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listFrequencies as myItem}
								<option value={myItem.Id}>{myItem.label}</option>
							{/each}
						</select>
					</div>
					<div class="form-group d-flex">
						<label style="width: 200px;" for="activity-type">{$_("CreateActivity.text_modality")}</label>
						<select bind:value={selectedModalitiy} class="form-control" name="activity-type" id="">
							<option value="*">{$_("CreateActivity.text_select")}...</option>
							{#each listModalities as myItem}
								<option value={myItem.Id}>{myItem.label}</option>
							{/each}
						</select>
					</div>
					<div>
						<div class="mx-2">
							<input class="" type="checkbox" bind:checked={checkConfirm} />
							<label class="" for="">{$_("CreateActivity.text_message_activity")}</label>
						</div>
					</div>
				{/if}
			</div>
			<div class="my-3 border-top">
				<div class="my-3 d-flex justify-content-center">
				
					{#if checkConfirm && selectedActivity.id != "*" && selectedFrequency != "*" && selectedModalitiy != "*"}
						<button
							class="btnBlue ms-2"
							on:click={() => {
								loader = true;
								NewActivity(selectedActivity);
							}}>
							{$_("CreateActivity.button_Create")}
						</button>
					{/if}
					<button
					class="btnWhite me-2"
					style="margin-left: 15px;"
					on:click={() => {
						dispatch("closeModal");
					}}>
					{$_("CreateActivity.button_return")}
				</button>
				</div>
			</div>
		{/if}
		
	</div>
	<!-- </LayoutPatient> -->
	<!-- </LayoutNav> -->
</section>


