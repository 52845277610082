<script>
	//import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import { _ } from "svelte-i18n";

	console.log("Estoy en Patient Pending");
	const refresh = () => {
		window.location.href = "#/Home";
	};
	let emailD = "info@rdcom.global";
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Paciente"> -->
	<div class="d-flex py-3 text-center border rounded shadow-sm my-3 bg-white">
		<div class="imgSolicitud">
			<img class="image-conteiner" src="/img/Frame 92.png" alt="login" />
		</div>
		<div class="messageSolicitud">
			<h1 class="title">{$_("Pending.text_message_congartulation")}</h1>
			<br />
			<h1 class="title">
				{$_("Pending.text_message_wait_approve")}
			</h1>
			<h1 class="title">
				{$_("Pending.text_message_questions")}{emailD}
			</h1>
			<h1 class="title">{$_("Pending.text_message_thanks")}</h1>
		</div>

		<!-- <h3>Debe esperar que el doctor apruebe o rechaze su solicitud...</h3> -->
		<!-- <button on:click={() => { refresh() }}>Actualizar</button> -->
	</div>
	<!-- </LayoutNav> -->
</section>

<style>
	.imgSolicitud {
		width: 20%;
		padding-left: 20%;
	}
	.messageSolicitud {
		width: 50%;
		padding-left: 15%;
	}
	.title {
		color: #0C0C0C;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 20px;
	}
</style>
