<script>
	// Importar las dependencias necesarias
	import { onMount } from "svelte";
	import Select from "svelte-select";
	import { _ } from "svelte-i18n";
	import Loader from "../components/Loader.svelte";
	import { patient_professional_search, utils_resources_get, generic_interview_update } from "../helpers/apiBackend";
	import { createEventDispatcher } from "svelte";
	import { language, registerDetail } from "../helpers/storage/stores";
	import { currentSession } from "../helpers/storage/sessions.js";
	import LayoutModal from "../components/Layouts/Layout_Modal.svelte";
	import { button } from "aws-amplify";

	// Declarar las variables necesarias

	export let specialty = "";
	export let country = "";
	export let state = "";
	// export let stateCabecera = "";

	let NameProfessional = "";
	let ProfilePictureLink = "./img/perfil_none.png";
	let country_id = "";
	let state_id = "";
	let speciality_id = "";
	let state_list = [];
	let country_list = [];
	let speciality_list = [];
	let state_list_filter = [];
	let professionals = [];
	let filteredData = [];
	let professionalData = {};
	let searched = false;
	let assignConfirmModal = false;
	let assignDoctorRequest = false;
	let filters = {
		name: "",
		specialty: "",
		country: "",
		state: "",
	};

	const dispatch = createEventDispatcher();

	const assignDoctor = async (assignedProfessional, interviewDetailId, Name) => {
		console.log("assignDoctor", assignedProfessional, interviewDetailId);
		assignDoctorRequest = true;
		let bodyResponse = {
			action: "request",
			interviewDetailId,
			assignedProfessional,
		};
		if (Object.keys($currentSession).length > 0) {
			console.log("INGRESO EN 1");
			if ($currentSession.hasOwnProperty("attributes")) {
				console.log("INGRESO EN 2");
				if ($currentSession["attributes"]["profile"] == "Paciente") {
					console.log("INGRESO EN 3");
					await generic_interview_update(bodyResponse)
						.then((data) => {
							console.log({ data });
							if (data.statusCode == 201) {
								window.location.reload();
							} else {
								assignDoctorRequest = false;
								assignConfirmModal = false;
								let doctor = {
									Id: assignedProfessional,
									Name,
								};
								dispatch("dateProf", doctor);
							}
						})
						.catch((errorResponseSession) => {
							assignDoctorRequest = false;
							console.log({ errorResponseSession });
						})
						.finally(() => {});
				} else {
					dispatch("dateProf", {
						Id: assignedProfessional,
						Name,
					});
				}
			}
		}

		//console.log("assignDoctor FINALIZO", dotorToAssign);
	};

	// Función para manejar la búsqueda
	const searchProfessionals = async () => {
		professionals = [];
		searched = true;
		console.log("Buscando profesionales... d");
		console.log({ NameProfessional });
		console.log({ country_id });
		console.log({ speciality_id });
		console.log({ state_id });

		let data = {
			IdSpecialization: speciality_id,
			IdState: state_id,
			IdCountry: country_id,
			NameProfessional,
			Cabecera: "",
			lang: $language,
		};
		//console.log("data a buscar",{ data });

		filters = {
			name: NameProfessional,
			specialty: speciality_id,
			country: country_id,
			state: state_id,
		};
		filteredData = applyFilters(filters);
		if (filteredData.length == 0) {
			await patient_professional_search(data).then((result) => {
				console.log({ result });
				if (result.status == 400) {
					errorMessage = "No se encontrado ningun profesional";
					modalError = true;
				} else {
					professionals = result.data;
					filteredData = result.data;
				}
				searched = false;
				console.log(result.data);
			});
		}
	};
	// Función para manejar los filtros y ordenamiento
	function applyFilters(filters) {
		// Obtener una copia de todos los profesionales
		let filteredData = [...professionals];

		// Filtrar por nombre
		if (filters.name) {
			filteredData = filteredData.filter((item) => {
				return item.NameProfessional.toLowerCase().includes(filters.name.toLowerCase());
			});
		}

		// Filtrar por especialidad
		if (filters.specialty) {
			// Verificar si se seleccionaron especialidades
			console.log("Filtrar por especialidades", filters.specialty);
			filteredData = filteredData.filter((item) => {
				return item.Speciality.some((s) => s.Speciality === filters.specialty); // Verificar si al menos una especialidad coincide
			});
		}

		// Filtrar por país
		if (filters.countryLabel) {
			console.log("Filtrar por país", filters.countryLabel);
			filteredData = filteredData.filter((item) => {
				return item.Country === filters.countryLabel;
			});
		}

		// Filtrar por provincia (estado)
		if (filters.state) {
			filteredData = filteredData.filter((item) => {
				return item.State === filters.state;
			});
			console.log("Filtrar por provincia", filters.state);
			console.log({ filteredData });
		}

		console.log({ filteredData });

		return filteredData;
	}

	function handleStateChange(event) {
		state_id = event.target.value;
	}

	function handlespecialityChange(event) {
		console.log("handlespecialityChange");
		//console.log(event.target.value);
		speciality_id = event.target.value;
	}


	const handleCountryChange = async (event) => {
		// Llamada a utils_resources_get para obtener los estados correspondientes al país seleccionado
		country_id = event.target.value;
		if (country_id != "") {
			const filteredData = state_list.filter(item => item.Id_country === country_id);
			state_list_filter = filteredData;
		}
		else {
			state_list_filter = state_list;
		}
		
	};


	// Ejecutar la búsqueda al cargar el componente
	//onMount(searchProfessionals);
	onMount(async () => {
		assignDoctorRequest = false;
		speciality_list = await utils_resources_get("specialties", $language);
		country_list = await utils_resources_get("country", $language);
		state_list = await utils_resources_get("state", $language, country_id);
	});
</script>

<div class="container">
	{#if !assignConfirmModal}
		<div class="filter-container">
			<div class="filter-container-cont">
				<div
					class="name-cont"
					style="
    display: flex;
    flex-direction: column;
">
					<label
						for="nombre"
						style="
    text-align: start;
">{$_("SearchDoctor.text_name")}:</label>
					<input type="text" bind:value={NameProfessional} id="nombre" placeholder="Ej. Juan Pérez" />
				</div>
				<div class="specialty-cont">
					<label for="specialty" style="text-align: start;">{$_("SearchDoctor.text_Specialties")}:</label>
					<select id="specialty" value={speciality_id} on:change={handlespecialityChange}>
						<option value="">{$_("SearchDoctor.text_all")}</option>
						{#each speciality_list as speciality}
							<option value={speciality.Id}>
								{speciality.label}
							</option>
						{/each}
					</select>
				</div>

				<div class="country-cont">
					<label for="country" style="text-align: start;">{$_("SearchDoctor.text_country")}:</label>
					<select id="country" value={country_id} on:change={handleCountryChange}>
						<option value="">{$_("SearchDoctor.text_all")}</option>
						{#each country_list as country}
							<option value={country.Id}>
								{country.label}
							</option>
						{/each}
					</select>
				</div>
				<div class="state-cont">
					<label for="state" style="text-align: start;">{$_("SearchDoctor.text_provinces")}:</label>
					<select id="state" value={state_id} on:change={handleStateChange}>
						<option value="">{$_("SearchDoctor.text_all")}</option>
						{#each state_list_filter as state}
							<option value={state.Id}>
								{state.label}
							</option>
						{/each}
					</select>
					<!-- <Select items={state_list} bind:value={state} id="state" placeholder="Todas las provincias" /> -->
				</div>
				<div class="button-container">
					<!-- svelte-ignore component-name-lowercase -->
					<button class="btn-search" on:click={searchProfessionals}> <img src="./img/search.svg" alt="Buscar" style="width: 24px; height: 24px;"></button>
				</div>
			</div>
			<div class="filter-container-cont"></div>
			<!-- filtros -->
		</div>
		<!-- <div class="filters">
			<div class="filter">
				<input
					class="select-filter"
					type="text"
					id="nameFilter"
					bind:value={filters.name}
					placeholder="Nombre"
					on:blur={() => {
						filteredData = applyFilters(filters);
					}}
					style="
    width: 93%;
" />
			</div>
			<div class="filter">
				
				<select
					class="select-filter"
					id="specialty"
					on:change={handlespecialityFilterChange}
					style="
    width: 96%;
">
					<option value="">Especialidad</option>
					{#each speciality_list as speciality}
						<option value={speciality.label}>
							{speciality.label}
						</option>
					{/each}
				</select>
			</div>
			<div class="filter">
				<select
					class="select-filter"
					id="country"
					value={filters.country}
					on:change={handleCountryFilterChange}
					style="
    width: 100%;
">
					<option value="">Pais</option>
					{#each country_list as country}
						<option value={country.Id}>
							{country.label}
						</option>
					{/each}
				</select>
			</div>
			<div class="filter">
				<select
					class="select-filter"
					id="country"
					value={filters.state}
					on:change={handleStatefilterChange}
					style="
    width: 80%;
">
					<option value="">Estado</option>
					{#each state_list_filter as state}
						<option value={state.label}>
							{state.label}
						</option>
					{/each}
				</select>
			</div>
			<div class="button-container-filter">
				
				 <button
						class="button-primary"
						on:click={() => {
							filteredData = applyFilters(filters);
						}}><i class="gg-play-list-search"></i></button> 
				
				<button
					class="btn-refresh"
					on:click={() => {
						filteredData = professionals;
						filters.country = "";
						filters.state = "";
						filters.specialty = "";
					}}>Restablecer</button>
			</div>
		</div>
		<hr />
		<br /> -->

		<!-- Listado de cards de profesionales -->
		{#if filteredData.length > 0}
			<div class="professional-list">
				{#each filteredData as profesional}
					<div
						class="professional-card"
						on:click={() => {
							assignConfirmModal = true;
							professionalData = profesional;
							console.log("Levanla al profesional:", profesional);
						}}>
						<img
							src={profesional.ProfilePictureLink ? profesional.ProfilePictureLink : ProfilePictureLink}
							alt="Foto de perfil" />
						<div class="professional-info" style="display: flex;justify-content: space-between;">
							<div style="width: 100%;">
								<p class="info-item" style="font-weight: 600;">{profesional.NameProfessional}</p>
								{#if profesional.Speciality && profesional.Speciality.length > 0}
									<p class="info-item text-muted">{profesional.Speciality[0].Speciality}</p>
								{/if}
							</div>

							<p class="info-item">{profesional.Country ? profesional.Country : "Sin datos"}</p>
							<p class="info-item">{profesional.State ? profesional.State : "Sin datos"}</p>

							<!-- Ajusta esta parte según la estructura real de tus datos -->
						</div>
					</div>
				{/each}
			</div>
		{:else}
			<div>
				{#if !searched}
					<div class="text-center">
						<p class="text-muted">{$_("SearchDoctor.text_not_profession")}...</p>
					</div>
				{/if}
				{#if searched}
					<div class="text-center">
						<Loader text={false} />
						<p class="text-muted">{$_("SearchDoctor.text_Searching")}..</p>
					</div>
				{/if}
			</div>
		{/if}
	{:else}
		<div class="professional-card-details">
			<div class="profile-picture">
				<img
					src={professionalData.ProfilePictureLink ? professionalData.ProfilePictureLink : ProfilePictureLink}
					alt="Foto de perfil de {professionalData.NameProfessional}" />
				<div class="professional-details">
					<h2>{professionalData.NameProfessional}</h2>
					<p>{$_("SearchDoctor.text_Specialties")}: {professionalData.Speciality[0].Speciality}</p>
					<p>{$_("SearchDoctor.text_country")}: {professionalData.Country}</p>

					<!-- Aquí puedes agregar más campos como estado, enlace de la foto de perfil, etc. -->
				</div>
			</div>
			<div>
				<!-- Aquí puedes agregar más campos informativos. -->
			</div>
			{#if !assignDoctorRequest}
				<div class="text-center p-3">
					<p>{$_("SearchDoctor.text_message_request")} {professionalData.NameProfessional}?</p>
					<div class="d-flex justify-content-center">
						<!-- svelte-ignore component-name-lowercase -->
						<button
							on:click={() => {
								assignConfirmModal = false;
							}}
							class="btnWhites_not">
							{$_("ProfessionalProfile.text_not")}
						</button>
						<!-- svelte-ignore component-name-lowercase -->
						<button
							on:click={() => {
								assignDoctor(
									professionalData.Id,
									$registerDetail["detail"]["Id"],
									professionalData.NameProfessional
								);
							}}
							class="btnBlues_yes">
							{$_("ProfessionalProfile.text_yes")}
						</button>
					</div>
				</div>
			{:else}
				<div class="text-center">
					<Loader text={false} />
					<p class="text-muted">{$_("SearchDoctor.text_requesting")}..</p>
				</div>
			{/if}
		</div>
	{/if}
	<div class="button">
		<button type="button" class="btnWhite"
		on:click={() => {
			dispatch('closeModal');
		}}
	>
	{$_("ProfessionalProfile.button_return")}
</button>
	</div>
</div>

<style>
	/* Estilos para el componente */
	.container {
		padding: 20px;
	}
	.professional-card-details {
		display: flex;
		border: 1px solid #ccc;
		border-radius: 5px;
		padding: 10px;
		margin-bottom: 20px;
	}

	.profile-picture {
		margin-right: 20px;
	}

	img {
		max-width: 100px;
		max-height: 100px;
		border-radius: 26%;
	}

	.professional-details {
		flex-grow: 1;
	}
	.btnBlues_yes {
		color: #ffffff;
		border-color: #215273;
		font-weight: 500;
		border-radius: 10px;
		background-color: var(--my-dark-blue);
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 70px;
		justify-content: center;
		letter-spacing: 1px;
		border-radius: 10px;
	}
	.btn-search {
		color: #ffffff;
		border-color: #215273;
		font-weight: 500;
		/* border-radius: 10px; */
		background-color: lightslategray;
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		/* width: 70px; */
		height: 37px;
		justify-content: center;
		letter-spacing: 1px;
	}
	.btnWhite {
		height: 45px;
		width: 90px;
		text-align-last: center;
		background-color: #fff; 
		color:#0C0C0C;     
	}
	.btnWhites_not {
		color: #215273;
		border-color: #215273;
		font-weight: 500;
		border-radius: 10px;
		background-color: #ffffff;
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 70px;
		justify-content: center;
		margin-right: 8%;
	}

	button {
		padding: 5px 10px;
		background-color: #007bff;
		color: #fff;

		border-radius: 5px;
		cursor: pointer;
	}

	.filter-container {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		background-color: #f3f6f8;
		padding: 20px;
		border-radius: 8px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		margin-bottom: 20px;
		flex-direction: column;
	}

	/* .filter-option {
		margin-bottom: 10px;
	}
	.select-filter {
		border-radius: 20px;
		background: #56aefa00;
		color: #0C0C0C;
		margin-left: 2%;
		margin-right: 2%;
	} */

	.filter-container-cont {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		width: 100%;
		align-items: flex-end;
	}
	.state-cont {
		width: 20%;
		text-align: start;
		display: flex;
		flex-direction: column;
	}
	.country-cont {
		width: 20%;
		text-align: start;
		display: flex;
		flex-direction: column;
	}
	.name-cont {
		width: 20%;
		text-align: start;
	}
	.specialty-cont {
		width: 30%;
		text-align: start;
		display: flex;
		flex-direction: column;
	}
	.button-container {
		margin-top: 10px;
		text-align: end;
	}

	/* .filters {
		display: flex;
		justify-content: space-around;
	}

	.button-primary {
		background-color: #0073b1;
	}

	.button-secondary {
		background-color: #00a0dc;
	}
	.btn-refresh {
		background-color: transparent;
		color: #215273;
		font-weight: 500;
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
	} */

	.professional-list {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		max-height: 340px;
		overflow: auto;
	}

	.professional-card {
		width: 100%;
		padding: 20px;
		background-color: white;
		border-radius: 8px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.professional-card:hover {
		transform: scale(0.99);
		transition: 0.2s;
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
	}

	.professional-card img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin-right: 10px;
	}

	.professional-info {
		flex: 1;
	}

	.info-item {
		width: 100%;
		text-align: start;
		margin-bottom: 5px;
	}
</style>
