<script>
	import { _ } from "svelte-i18n";
	import { language } from "../helpers/storage/stores";
	export let body;
	let genderOptions = $_("PersonalData.select_gender");
	console.log({ body });

	function formatDate(fecha, idioma) {
		const formatos = {
			es: { day: "2-digit", month: "2-digit", year: "numeric" },
			en: { month: "2-digit", day: "2-digit", year: "numeric" },
		};
		const opcionesFecha = formatos[idioma];
		if (opcionesFecha) {
			return new Date(fecha).toLocaleDateString(idioma, opcionesFecha);
		} else {
			console.log("Idioma no soportado");
			return null;
		}
	}
</script>

<main>
	<div class="title-conf">
		<h2 class="title-confirm">{$_("PersonalData.text_title_confirm")}</h2>
	</div>

	<div class="container py-2 border rounded shadow-sm my-3 bg-white">
		<div class="title">
			<h3 class="title-detail">{$_("PersonalData.text_title_information")}:</h3>
		</div>
		<div class="conteiner-d">
			<div class="FirstName">
				<label for="FirstName" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_name")}:</strong></label>
				<input type="text" id="FirstName" readonly value={body.FirstName} class="base_input" />
			</div>
			<div class="LastName">
				<label for="LastName" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_lastname")}:</strong></label>
				<input type="text" id="LastName" readonly value={body.LastName} class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="DNI__c">
				<label for="DNI__c" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>DNI:</strong></label>
				<input type="text" id="DNI__c" readonly value={body.DNI__c} class="base_input" />
			</div>
			<div class="PersonBirthdate">
				<label for="PersonBirthdate" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_birthdate")}:</strong></label>
				<input
					type="text"
					id="PersonBirthdate"
					readonly
					value={formatDate(body.PersonBirthdate, $language)}
					class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			<div class="HealthCloudGA__Gender__pc">
				<label for="HealthCloudGA__Gender__pc" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_gender")}:</strong></label>
				<input
					type="text"
					id="HealthCloudGA__Gender__pc"
					readonly
					value={genderOptions.find((option) => option.id === body.HealthCloudGA__Gender__pc)?.text || ""}
					class="base_input" />
			</div>
		</div>
		<div class="conteiner-d">
			{#if body.Phone}
				<div class="Phone">
					<label for="Phone" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
						><strong>{$_("PersonalData.text_phone")}:</strong></label>
					<input type="text" id="Phone" readonly value={body.Phone} class="base_input" />
				</div>
			{/if}
			<div class="PersonMobilePhone">
				<label for="PersonMobilePhone" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_cell")}:</strong></label>
				<input type="text" id="PersonMobilePhone" readonly value={body.PersonMobilePhone} class="base_input" />
			</div>
		</div>
	</div>

	<div class="container py-2 border rounded shadow-sm my-3 bg-white">
		<div class="title">
			<h3 class="title-detail">{$_("PersonalData.text_title_addres")}:</h3>
		</div>
		<div class="conteiner-d">
			<div class="R1_streetName__c">
				<label for="R1_streetName__c" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_title_addres")}:</strong></label>
				<input type="text" id="R1_streetName__c" readonly value={body.R1_streetName__c} class="base_input" />
			</div>
			{#if body.R1_streetNumber__c}
				<div class="R1_streetNumber__c">
					<label for="R1_streetNumber__c" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_addres_number")}:</strong></label>
					<input
						type="text"
						id="R1_streetNumber__c"
						readonly
						value={body.R1_streetNumber__c}
						class="base_input" />
				</div>
			{/if}
			{#if body.R1_streetDetail__c}
				<div class="R1_streetDetail__c">
					<label for="R1_streetDetail__c" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
						><strong>{$_("ProfessionalRecord.text_addresApartment")}:</strong></label>
					<input
						type="text"
						id="R1_streetDetail__c"
						readonly
						value={body.R1_streetDetail__c}
						class="base_input" />
				</div>
			{/if}
		</div>
		<div class="conteiner-d">
			<div class="BillingCountry">
				<label for="BillingCountry" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>{$_("PersonalData.text_country")}:</strong></label>
				<input type="text" id="BillingCountry" readonly value={body.BillingCountry.label} class="base_input" />
			</div>
			<div class="BillingState">
				<label for="BillingState" style="font-size: 11px; color: #0C0C0C; margin-bottom: 5px;"
					><strong>{$_("ProfessionalRecord.text_BillingState")}:</strong></label>
				<input type="text" id="BillingState" readonly value={body.BillingState.label} class="base_input" />
			</div>
		</div>
	</div>
	<div class="container py-2 border rounded shadow-sm my-3 bg-white">
		<div class="title">
			<h3 class="title-detail">{$_("PatientPathology.text_th_pathology")}:</h3>
		</div>

		<div class="conteiner-d">
			{#if body.diseases.length > 0}
				<div class="diseases">
					<ul>
						{#each body.diseases as disease (disease.label)}
							<li>
								{disease.label} - {$_("PatientPathology.text_th_State")}: {disease.Estado_patologia__c}
							</li>
						{/each}
					</ul>
				</div>
			{/if}
		</div>
	</div>
</main>

<style>
	.title-conf {
		text-align: center;
		color: #0C0C0C;
	}
	.conteiner-d {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-evenly;
	}

	.FirstName {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.LastName {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}

	.DNI__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.PersonBirthdate {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.HealthCloudGA__Gender__pc {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.Phone {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}

	.PersonMobilePhone {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.BillingState {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.base_input {
		width: 90%;
		padding: 5px;
		border: none;
		border-bottom: 1px solid #0C0C0C;
		color: gray;
	}

	.BillingCountry {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}

	.R1_streetName__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.R1_streetDetail__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.R1_streetNumber__c {
		width: 100%;
		padding: 2%;
		display: flex;
		flex-direction: column;
	}
	.title-detail {
		font-size: 16px;
		color: rgb(17, 44, 74);
		margin-left: 2%;
	}
</style>
