<script>
	import LayoutNav from "../../components/Layouts/Layout_Nav.svelte";
	import { Auth } from "aws-amplify";
	import awsconfig from "../../aws-exports";
	import { currentSession } from "../../helpers/storage/sessions";
	import { onMount } from "svelte";
	import Loader from "../../components/Loader.svelte";
	import { _ } from "svelte-i18n";

	// Config
	Auth.configure(awsconfig);
	let myRol;
	let render = false;


	onMount(async () => {
		if (Object.keys($currentSession).length === 0) {
			window.location.href = "/";
		} else {
			render = true;
			if ($currentSession.hasOwnProperty("attributes")) {
				if ($currentSession["attributes"].hasOwnProperty("profile")) {
					myRol = $currentSession["attributes"]["profile"];
					if (myRol == "Profesional_de_Salud" || myRol == "Professional") {
						// Profesional
						window.location.href = "#/Doctor";
					}
					if (myRol == "Paciente" || myRol == "Patient") {
						// Paciente
						console.log("Home - Soy paciente");
						window.location.href = "#/Patient/Plan";
					}
					if (myRol == "Familiar_Cuidador") {
						// Cuidador
						console.log("Home - Soy Cuidador");
						window.location.href = "#/Carer/Perfil";
					}
				} else {
					console.log("Exploto aca");
					window.location.href = "#/LoginError";
				}
			}
		}
	});
</script>

<!-- HTML -->
<svelte:head>
	<title>RDCOM - HOME</title>
	<link rel="icon" href="/img/new_favicon.png" />
</svelte:head>
<section>
	{#if render}
		<LayoutNav secure={true} rol={myRol}>
			<div class="text-center py-3">
				<Loader text={false} />
				<p class="text-muted">{$_("RecordDoctor.loading")}</p>
			</div>
		</LayoutNav>
	{/if}
</section>
