<script>
	import { createEventDispatcher } from "svelte";
	import { onMount } from "svelte";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import {
		generic_questionnaire_detail_add,
		//TODODELETE generic_medication_delete,
	} from "../../helpers/apiBackend";
	import { formatDate } from "./../../helpers/helpers";
	//import Loader from "../../components/Loader.svelte";
	import { user, registerDetail, language } from "./../../helpers/storage/stores";
	import { _ } from "svelte-i18n";

	const dispatch = createEventDispatcher();
	export let theRecord = undefined;
	export let idpatient;
	let collapsed = true;
	let message = $_("List_Question_patient.text_adding");
	//let loadingDelete = false;
	let modaladd = false;
	let modalInstruccion = false;
	//let modaldelete = false;
	let indice = 0;
	let status_green = 0;
	let status_red = 0;
	let status_blue = 0;
	let status_gray = 0;
	let status_record = true;
	let idQuestion = "";
	let suggestedDate = "";
	let name = "";
	let instrucction = [];
	let modalBibliogra = false;
	const statusbutton = $_("List_Records_prof.statusbutton");

	const addDetailQuestion = async (id) => {
		if (!$user.hasOwnProperty("Especialidades__r")) {
			idpatient = $user.Id;
		}
		console.log(theRecord.Id, $user.Id, idpatient);
		let data = {
			Id: theRecord.Id,
			doctorId: JSON.parse(localStorage.getItem("user"))["Id"],
			suggestedDate,
		};
		await generic_questionnaire_detail_add(data).then((res) => {
			console.log({ res });
			if (res.statusCode == 200) {
				let detail = {
					Id: res.Id,
				};
				console.log({ detail });
				$registerDetail = detail;
				window.location.reload();
			} else {
				message = res.message;
			}
		});
		setTimeout(() => {
			modaladd = false;
		}, 4000);
	};

	// const deleteHeadM = async (id) => {
	// 	loadingDelete = true;
	// 	console.log("Llamar a Borrar : ", id);
	// 	await generic_medication_delete(id).then((result) => {
	// 		if (result.statusCode == 200) {
	// 			window.location.reload();
	// 		} else {
	// 			loadingDelete = false;
	// 			console.log("error");
	// 		}
	// 	});
	// };

	onMount(async () => {
		console.log({ theRecord });
		name = theRecord?.name;
		status_record = theRecord.completed == null ? false : theRecord.completed;
		status_green = theRecord.requestedDetailsOnTime ? theRecord.requestedDetailsOnTime : 0;
		status_red = theRecord.requestedDetailsExpired ? theRecord.requestedDetailsExpired : 0;
		status_blue = theRecord.completedDetails ? theRecord.completedDetails : 0;
		status_gray = theRecord.requestedDetailsInactive ? theRecord.requestedDetailsInactive : 0;
		if (theRecord.details.length == theRecord.completedDetails) {
			status_record = true;
		}
	});
</script>

<section>
	<div class="myCustom-box">
		<div
			class="{collapsed
				? `myCustom-header-closed-${theRecord['active']}`
				: 'myCustom-header-open'} d-flex align-items-center p-2">
			<tr class="d-flex myCustom-name">
				<p class="variableName m-0" title={name}>{name}</p>
				<!-- &nbsp; -->
			</tr>

			<!-- Area -->
			<tr class="myCustom-frecuency">
				{#if theRecord.hasOwnProperty("frecuency") && theRecord.frecuency != null}
					<p class="variableA m-0" title={theRecord.frecuency}>{theRecord.frecuency}</p>
				{:else}
					<p class="variableA m-0">{$_("List_Question_patient.text_undefined")}</p>
				{/if}
			</tr>
			<tr class="myCustom-variable">
				{#if theRecord.hasOwnProperty("variable") && theRecord.variable != null}
					<p class="variableA m-0" title={theRecord.variable}>{theRecord.variable}</p>
				{:else}
					<p class="variableA m-0">{$_("RegisterDetailQuestion.text_variable")}</p>
				{/if}
			</tr>

			<tr class="cabeceraID">
				{#if theRecord.details.length > 0 && theRecord.active && status_record}
					<small class="headDetailC py-0">{$_("List_Records_prof.text_completed")}</small>
				{:else if !theRecord.active}
					<small class="headDetailI py-0">{$_("List_Records_prof.text_inactive")}</small>
				{/if}

				{#if theRecord.details.length > 0 && theRecord.active && !status_record}
					{#if status_green > 0}
						<span class="badge_green cursor-pointer" placeholder="Solicitado">{status_green}</span>
					{/if}
					{#if status_red > 0}
						<span class="badge cursor-pointer">{status_red}</span>
					{/if}
					{#if status_blue > 0}
						<span class="badge_blue cursor-pointer">{status_blue}</span>
					{/if}
					{#if status_gray > 0}
						<span class="badge_gray cursor-pointer">{status_gray}</span>
					{/if}
				{/if}
			</tr>
			

			<tr class="container-action">
				<div
						class="cursor-pointer"
						on:click={() => {
							console.log("llamar modal para ver data");
							modalBibliogra = !modalBibliogra;
						}}>
						<!-- <i data-toggle="tooltip" data-placement="top" title="view" class="text-muted mx-2 gg-eye" /> -->
						<img src="./img/eye.svg" alt="Menú">
					</div>
				<div>
					<!-- on:click={() => {
					 	dispatch("editHeader");
					}}> -->
					<!-- <i
						data-toggle="tooltip"
						data-placement="top"
						title="Editar"
						class="text-muted mx-2 gg-pen cursor-pointer" /> -->
						<button
						on:click={() => {
							dispatch("editHeader");
							// collapsed = false;
						}}
						style="background: none; border: none; padding: 0;">
						<img src="./img/pen.svg" alt="Flecha hacia abajo" style="width: 24px; height: 24px;">
				</div>

				<!-- <div
					on:click={() => {
						collapsed = !collapsed;
					}}
					class="d-flex myCustom-collapse"
					data-toggle="collapse"
					data-target="#{theRecord.Id}"
					aria-expanded="false"
					aria-controls={theRecord.Id}>
					<p class="m-0 mx-2">{$_("List_Question_patient.text_more")}</p> 
					<i class="gg-arrow-down-o" /> 
				</div> -->
				<button
					on:click={() => {
						collapsed = !collapsed;
					}}
					class="d-flex align-items-center myCustom-collapse"
					data-toggle="collapse"
					data-target="#{theRecord.Id}"
					aria-expanded="false"
					aria-controls={theRecord.Id}
					style="background: none; border: none; padding: 0; cursor: pointer;">
					<!-- <p class="m-0 mx-2">{$_("List_Question_patient.text_more")}</p>  -->
					<img src="./img/arrow-down-o.svg" alt="Flecha hacia abajo" style="width: 24px; height: 24px;">
				</button>

			</tr>
		</div>

		<!-- Detail -->
		<div class:collapse={collapsed} id={theRecord.Id}>
			<table class="myCustom-table">
				<thead>
					<tr>
						<th class="createdbyQuestProf">{$_("List_Question_patient.text_createdby")} </th>
						<th class="dateCompletedQuestProf">  
							{#if theRecord ["details"].some(detail => detail.hasOwnProperty("dateCompleted") && detail.dateCompleted != null)}
							{$_("List_Question_patient.text_session_date")}
							{:else}
							{$_("List_Question_patient.text_date_planned")}								
							{/if}
						</th>
						<th class="requestQuestProf">{$_("List_Question_patient.text_requested")} </th>
						<th class="completeQuestProf">{$_("List_Question_patient.text_complete")} </th>
						<th class="buttonQuestProf"
							><button
								on:click={() => {
									modaladd = true;
									addDetailQuestion();
								}}
								class="btnWhiteAdd">{$_("List_Question_patient.button_add")}</button
							></th>
					</tr>
				</thead>
				<tbody class="py-2">
					{#if theRecord.hasOwnProperty("details")}
						{#if theRecord["details"].length > 0}
							{#each theRecord["details"] as myDetail}
								<tr class="bg-white py-2" style="height:35px;">
									<!-- Frecuencia -->
									<td>
										{#if myDetail.hasOwnProperty("createdByName")}
											<span>{myDetail["createdByName"]}</span>
										{:else}
											<span class="text-muted">No definido</span>
										{/if}
									</td>

									<td>
										{#if myDetail.hasOwnProperty("dateCompleted") && myDetail.dateCompleted != null}
											<span>{formatDate(myDetail.dateCompleted, $language)}</span>
										{:else}
											{#if myDetail.hasOwnProperty("dateSuggested")}
												<span>{formatDate(myDetail.dateSuggested, $language)}</span>
											{:else}
												<span class="text-muted"
													>{$_("List_Question_patient.text_not_details")}</span>
											{/if}
										{/if}
										
									</td>
									
									<!-- Completado__c -->
									<td>
										{#if myDetail["requested"]}
											<!-- <i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

											
										{:else}
											<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{/if}
									</td>

									<!-- Completado__c -->
									<td>
										{#if myDetail["completed"]}
											<!-- <i class="myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{:else}
											<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{/if}
									</td>

									<!-- Button Detalles -->
									<td class="buttonQuestProfAcc">
										<button
											on:click={() => {
												dispatch("editDetail", { myDetail });
											}}
											class="btnBlue btn-info py-1 myCustom-button"
											>{#if myDetail["requested"] && myDetail["completed"]}
												{statusbutton.detalle}
											{:else if myDetail["requested"]}
												{statusbutton.evolucionar}
											{:else}
												{statusbutton.solicitar}
											{/if}
										</button>
									</td>
								</tr>
							{/each}
						{:else}
							<small class="text-muted">{$_("List_Question_patient.text_not_details")}...</small>{/if}
					{:else}
						<small class="text-muted">{$_("List_Question_patient.text_not_details")}...</small>{/if}
				</tbody>
			</table>
		</div>
	</div>
</section>

<LayoutModal
	tam="30"
	isOpenModal={modaladd}
	on:closeModal={() => {
		modaladd = false;
	}}>
	<div class="text-center">
		<small class="text-muted">{message}</small>
	</div>
</LayoutModal>

<!-- <LayoutModal
	isOpenModal={modaldelete}
	on:closeModal={() => {
		modaldelete = false;
	}}>
	{#if !loadingDelete}
		<div class="conteiner">
			<p>{$_("List_Question_patient.text_message_medication")}</p>
			<div class="Block">
				<div class="text-center">
					<button
						class="btnBlue"
						on:click={() => {
							deleteHeadM(theRecord.Id);
						}}>{$_("List_Question_patient.button_accept")}</button>
					<button
						on:click={() => {
							modaldelete = false;
						}}
						class="btnWhite">{$_("List_Question_patient.button_cancel")}</button>
				</div>
			</div>
		</div>
	{:else}
		<div class="text-center py-3">
			<Loader text={false} />
			<p class="text-muted">{$_("List_Question_patient.button_removing")}...</p>
		</div>
	{/if}
</LayoutModal> -->

<LayoutModal
	isOpenModal={modalInstruccion}
	on:closeModal={() => {
		modalInstruccion = false;
	}}>
	<p>{instrucction[indice]}</p>
	<div class="buttonNext">
		{#if indice != 0}
			<button
				class="btnWhite"
				on:click={() => {
					if (indice != 0) {
						indice--;
					}
				}}>{$_("List_Question_patient.button_return")}</button>
		{/if}
		<button
			class="btnBlue"
			on:click={() => {
				if (indice + 2 < instrucction.length) {
					indice++;
				} else {
					window.location.href = `#/FormInit?Detail=${idQuestion}`;
				}
			}}>{$_("List_Question_patient.button_Next")}</button>
	</div>
</LayoutModal>

<LayoutModal tam="50" isOpenModal={modalBibliogra}>
	<div class="title">
		<h3>{name}</h3>
	</div>
	<div class="p-2 text-center">
		{#if theRecord.hasOwnProperty("commentsRdcom") && theRecord["commentsRdcom"] != null && theRecord["commentsRdcom"] != ""}
			<div class="class_commentsRdcom">
				<label for="for_commentsRdcom" class="label_commentsRdcom"
					>{$_("RegisterDetailProf.text_commentsRdcom")}:</label>
				<p
					style="
    text-align: initial;
	font-size: 14px;
">
					{theRecord["commentsRdcom"]}
				</p>
			</div>
		{/if}
		{#if theRecord.hasOwnProperty("bibliographicReference") && theRecord["bibliographicReference"] != null && theRecord["bibliographicReference"] != ""}
			<div class="class_bibliographicReference">
				<label for="" class="label_bibliographicReference"
					>{$_("RegisterDetailProf.text_bibliographicReference")}:</label>
				<p
					style="
    text-align: initial;
	    font-size: 14px;
">
					{theRecord["bibliographicReference"]}
				</p>
			</div>
		{/if}
		{#if theRecord.hasOwnProperty("specification") && theRecord["specification"] != null && theRecord["specification"] != ""}
			<div class="class_specification">
				<label for="" class="label_specification">{$_("RegisterDetailProf.text_specification")}:</label>
				<p
					style="
    text-align: initial;
	    font-size: 14px;
">
					{theRecord["specification"]}
				</p>
			</div>
		{/if}
		<div class="text-centrar">
			<button
				on:click={() => {
					modalBibliogra = false;
					//modalSolicitar = true;
				}}
				class="btnWhite_close py-0 mx-2 py-2">{$_("List_Records_prof.button_close")}</button>
		</div>
	</div>
</LayoutModal>

<style>
	:root {
		--color1: #0C0C0C;
	}
	.container-action {
		display: flex;
		justify-content: end;
		align-items: center;
		width: 13vw;
	}
	.btnBlue { 
		background-color: #25085e;
	}
	.btnWhiteAdd {
		border-radius: 5px;
		width: 78px;
	}
	.variableA {
		/* width: 150px; */
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.variableName {
		/* width: 550px; */
		width: 35vw;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.circle {
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: green;
	}
	.class_commentsRdcom {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: flex-start;
	}
	.label_commentsRdcom {
		font-weight: 700;
		text-align: initial;
	}
	.class_bibliographicReference {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: flex-start;
	}
	.label_bibliographicReference {
		font-weight: 700;
		text-align: initial;
	}
	.class_specification {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: flex-start;
	}
	.label_specification {
		font-weight: 700;
		text-align: initial;
	}

	/* CONTAINER CSS */
	.myCustom-box {
		border: solid 1px var(--color1);
		border-radius: 5px;
	}

	/* HEADER CSS */
	.myCustom-header-closed {
		color: var(--color1);
		background-color: #fbfbfb;
	}
	.myCustom-header-closed-false {
		color: var(--color1);
		background-color: #918f8f73;
	}
	.headDetailC {
		background-color: cornflowerblue;
		border: none;
		color: azure;
		border-radius: 10px;
		text-align: center;
		padding: 15%;
	}
	.headDetailI {
		background-color: rgb(132, 132, 132);
		border: none;
		color: azure;
		border-radius: 10px;
		padding: 25%;
		text-align: center;
	}

	.myCustom-header-open {
		background-color: #6a7e93;
		color: #ffffff;
	}

	.myCustom-name {
		min-width: 30vw;
		/* width: 41%; */
		width: 35vw;
	}
	.myCustom-frecuency {
		/* width: 20%; */
		width: 20vw;
	}
	.myCustom-variable {
		/* width: 21%; */
		width: 20vw;
	}
	.buttonNext {
		text-align: center;
	}

	.myCustom-toggle {
		transform: scale(1.25);
	}
	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
		/* transform: scale(1.25);*/
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-collapse:hover {
		cursor: pointer;
	}

	/* DETAIL CSS */
	.collapse {
		padding: 5px;
	}

	.myCustom-table {
		width: 100%;
		color: var(--color1);
	}

	.myCustom-table {
		width: 100%;
		color: var(--color1);
	}

	.myCustom-table thead tr th {
		font-size: 15px;
		font-weight: 700;
	}

	.myCustom-button {
		background-color: #25085e;
		border-radius: 5px;
	}
	.badge {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(255, 0, 0, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_green {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(28, 187, 7, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_blue {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(16, 48, 234, 0.645);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.badge_gray {
		/* top: -5px;
		right: -5px; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: rgba(146, 146, 146, 0.8);
		border-radius: 50%;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	.cabeceraID {
		/* width: 10%; */
		width: 12vw;
		display: flex;
		justify-content: space-between;
	}
	.createdbyQuestProf {
		width: 35vw;
	}
	.dateCompletedQuestProf {
		width: 20vw;
	}
	.requestQuestProf {
		width: 20vw;
	}
	.completeQuestProf {
		width: 12vw;
	}
	.buttonQuestProf {
		width: 13vw;
		display: flex;
		justify-content: end;
		padding-right: 10px;
	}	
	.buttonQuestProfAcc {
		display: flex;
		justify-content: end;
		padding-right: 10px;
	}
</style>