<!-- 
  1 Step on register (Before Mail)
 -->
<script>
	import Select from "svelte-select";
	import { onMount } from "svelte";
	import { Auth, container } from "aws-amplify";
	import awsconfig from "../../aws-exports";
	import { replace } from "svelte-spa-router";
	import { register_schema } from "../schema";
	import { validateOnlyNumbers } from "../../helpers/validation";
	import { utils_resources_get_open } from "../../helpers/apiBackend";
	//utils_resources_get_open
	import { language } from "../../helpers/storage/stores";
	import { Modals, closeModal, openModal, modals } from "svelte-modals";
	import LayoutModal from "../../components/Layouts/Layout_Modal.svelte";
	import { fade } from "svelte/transition";
	import { _ } from "svelte-i18n";

	import { CognitoIdentityProviderClient, ListUsersCommand } from "@aws-sdk/client-cognito-identity-provider";

	Auth.configure(awsconfig);
	let username = "";
	let password = "";
	let confirmUsername = "";
	let confirmPassword = "";
	let dni = "";
	let messageError = {};
	let modalTerm = false;
	let registerlog = true;
	let rol = "";
	let countrySelect = "";
	let countryCode = "";
	let termsChecked = false;
	let langSelect = "es";
	let errorMessagePassword;
	let storedLanguage = JSON.parse(localStorage.getItem("language")) || "es";
	let countryList = [
		{
			label: "Argentina",
			code: "AR",
			id: "a238K000000HXusQAG",
		},
		{
			label: "Colombia",
			code: "CO",
			id: "a238K000000HeWKQA0",
		},
		{
			label: "Estados Unidos",
			code: "US",
			id: "a238K000000HXudQAG",
		},
		{
			label: "Israel",
			code: "IL",
			id: "a238K000000HXvHQAW",
		},
		{
			label: "México",
			code: "MX",
			id: "a238K000000HXumQAG",
		},
	];
	langSelect = storedLanguage;
	if ($language == "es" || $language == "en") {
		console.log("ingreso");
		langSelect = $language;
	}

	console.log({ langSelect });

	function removeResources() {
		try {
			localStorage.removeItem("pathologies");
		} catch {}
		try {
			localStorage.removeItem("monitoringPlans");
		} catch {}
		try {
			localStorage.removeItem("prepaidMedicine");
		} catch {}
		try {
			localStorage.removeItem("specialties");
		} catch {}
		try {
			localStorage.removeItem("frecuencies");
		} catch {}
		try {
			localStorage.removeItem("languages");
		} catch {}
		try {
			localStorage.removeItem("country");
		} catch {}
		try {
			localStorage.removeItem("state");
		} catch {}
	}

	/**
	 * @description
	 */
	function changeLanguage(lang) {
		$language = lang;
		removeResources();
		window.location.reload();
	}

	/**
	 * @description
	 */
	/* function openTermsModal() {
		console.log("Terminos y condiciones");
		modalTerm = true;
	}*/

	async function buscarUsuarioPorDNI(dni) {
		try {
			// const authConfig = Auth.configure(awsconfig);
			// console.log("Configuración actual de Auth:", authConfig);

			// const credentials = await Auth.currentCredentials();
			// console.log("Credenciales", credentials)
			// const client = new CognitoIdentityProviderClient({
			// 	region: authConfig.aws_cognito_region,
			// 	credentials: Auth.essentialCredentials(credentials),
			// });

			// const command = new ListUsersCommand({
			// 	UserPoolId: authConfig.aws_user_pools_id, // Reemplaza con tu User Pool ID
			// 	// AttributesToGet: ["custom:dni"]
			// 	//Filter: `custom:dni = "CO-2121212121"`,
			// });

			// const response = await client.send(command);

			// console.log("lista users", response);

			// // Verificar si se encontraron usuarios
			// if (response.Users && response.Users.length > 0) {
			// 	// Recorrer los usuarios y buscar el DNI
			// 	const usuarioEncontrado = response.Users.find((user) =>
			// 		user.Attributes.some((attr) => attr.Name === "custom:dni" && attr.Value === dni)
			// 	);

			// 	if (usuarioEncontrado) {
			// 		console.log("Usuario encontrado:", usuarioEncontrado);
			// 		return true; // El usuario con el DNI existe
			// 	}
			// }

			// console.log("Usuario no encontrado");
			return false; // El usuario con el DNI no existe
		} catch (error) {
			console.error("Error al buscar usuario por DNI:", error);
			throw error;
		}
	}

	/**
	 * @description
	 */
	async function signUp() {
		countryCode = countrySelect.code;
		const dniToFind = countryCode + "-" + dni;
		try {
			if (!validatePassword()) {
				messageError.password = "Password no valido";
				const error = new Error("La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una letra minúscula, un número y un carácter especial (@$!%*?&.).");
				error.code = "PassInvalidate";
				throw error;
			}

			await register_schema
				.validate(
					{
						username,
						password,
						dni,
						confirmUsername,
						confirmPassword,
						rol,
						countryCode,
					},
					{ abortEarly: false }
				)
				.then(async () => {
					if (rol !== "") {
						const usuarioExiste = await buscarUsuarioPorDNI(dniToFind);
						console.log({ usuarioExiste });
						if (!usuarioExiste) {
							const dataUser = await Auth.signUp({
								username,
								password,
								attributes: {
									profile: rol.value,
									"custom:StatusReg": "false",
									"custom:StatusAccountSF": "In Registration Process",
									"custom:dni": dniToFind,
								},
							});
							replace("/EmailCodeVerify/" + username);
						} else {
							const error = new Error("message");
							error.code = "DNIExistsException";
							throw error;
						}
					}
					document.getElementById("select-rol").focus();
				});
		} catch (error) {
			messageError = {};
			if (error["inner"]) {
				for (const item of error["inner"]) {
					console.log("aca el error", { item });

					switch (item.path) {
						case "username":
							messageError.username = item.message;
							break;
						case "password":
							messageError.password = item.message;
							break;
						case "rol":
							messageError.rol = "Debe seleccionar un Rol";
							break;
						case "countrySelect.value":
							console.log("ingreso por este error");
							messageError.countrySelect = "";
							break;
						case "confirmUsername":
							confirmUsername = "";
							messageError.confirmUsername = item.message;
							//messageError.confirmUserStatus = true;
							break;
						case "confirmPassword":
							confirmPassword = "";
							messageError.confirmPassword = item.message;
							break;
						case "dni":
							dni = "";
							messageError.dni = item.message;

							break;

						default:
							messageError = {};
							break;
					}
				}
			} else {
				registerlog = false;
				switch (error.code) {
					case "UsernameExistsException":
						error.message = "El email ingresado ya se encuentra registrado en nuestra base de datos";
						break;
					case "DNIExistsException":
						error.message = "El DNI ingresado ya se encuentra registrado en nuestra base de datos";
						break;
					default:
						messageError = {};
						break;
				}
				openModal(() => import("../../components/ModalValidationsForm.svelte"), {
					title: "Error de registro",
					message: error.message ? error.message : error,
				});
			}
		}
	}
	/**
	 * @description
	 */
	function openSelect() {
		console.log("openSelect");
		var selectElement = document.getElementById("language-sel");
		selectElement.click();
	}
	/**
	 * @description
	 */
	function showPassword(idtipo, idimg) {
		console.log(idtipo, idimg);
		let tipo = document.getElementById(idtipo);
		let img = document.getElementById(idimg);

		if (tipo.type == "password") {
			img.src = "/img/show-password1.png";
			tipo.type = "text";
		} else {
			img.src = "/img/show-password.png";
			tipo.type = "password";
		}
	}
	onMount(async () => {
		await utils_resources_get_open("Country__c", username, $language);
	});
	function getTermsButtonText() {
		return langSelect === "es" ? "Términos y condiciones" : "Terms and Conditions";
	}
	function openTermsPDF() {
		const pdfUrl = "/terminos-condiciones/RDCom.pdf";
		window.open(pdfUrl, "_blank");
	}
	//

	function validatePassword() {
		const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
		if (!regex.test(password)) {
			return false;
			errorMessagePassword = console.log("pass no valido");
			("La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una letra minúscula, un número y un carácter especial.");
		} else {
			errorMessagePassword = "";
		}

		// Enviar un evento si la validación pasa
		if (!errorMessagePassword) {
			return true;
			dispatch("validPassword", password);
		}
	}
</script>

<svelte:head>
	<title>RDCOM - {$_("Register.titleHead")}</title>
	<link rel="icon" href="/img/new_favicon.png" />
</svelte:head>
<div>
	<!-- svelte-ignore a11y-missing-attribute -->
	<!-- <img class="imgBack" /> -->

	<!-- <div class="language-selector">
		<button>
			<img src="./img/globo.png" alt="Icono del mundo" />
		</button>
		<div class="d-flex" style="flex-direction: row-reverse;align-items: center;">
			<select
				class="form-control form-control-sm order-filter"
				id="language-sel"
				bind:value={langSelect}
				on:change={() => {
					changeLanguage(langSelect);
				}}>
				<option value="es">{$_("Register.spanish")}</option>
				<option value="en">{$_("Register.englis")}</option>
			</select>
		</div>
	</div> -->

	<!-- <div class="logo">
	
		<img src="/img/new_logo.png" alt="logo" width="170" height="138" style="margin: 20px;" />
	</div> -->
	<div class="register-conteiner">
		<div class="register-info-conteiner">
			<form class="register-form" on:submit|preventDefault={signUp}>
				<div class="email" style="d-flex justify-content-center">
					<h1 class="text-h1">{$_("Register.email")}</h1>
					<input
						class="input-log"
						id="username"
						type="email"
						bind:value={username}
						placeholder="name@domain.com" />
				</div>
				{#if messageError.username && username == ""}
					<div class="conteiner-error">
						<small class="messageError">{$_("Register.message_error_username")}</small>
					</div>
				{/if}
				<div class="confirmar-email" style="d-flex justify-content-center">
					<h1 class="text-h1">{$_("Register.emailConf")}</h1>
					<input
						class="input-log"
						id="confirmUsername"
						type="email"
						placeholder="name@domain.com"
						bind:value={confirmUsername}
						on:change={console.log(confirmUsername)} />
				</div>
				{#if messageError.confirmUsername && confirmUsername == ""}
					<div class="conteiner-error">
						<small class="messageError">{$_("Register.message_error_confirmUsername")}</small>
					</div>
				{/if}
				<div class="contraseña">
					<h1 class="text-h1">{$_("Register.password")}</h1>
					<div class="c-password">
						<input
							class="input-log"
							id="password"
							type="password"
							placeholder={$_("Register.placeholder_pass")}
							minlength="8"
							bind:value={password}
							on:input={validatePassword} />
						<!-- svelte-ignore a11y-missing-attribute -->
						<img
							id="passworImag"
							src="/img/show-password.png"
							class="showPassword"
							on:click={() => {
								showPassword("password", "passworImag");
							}} />
					</div>
				</div>
				{#if messageError.password && password == ""}
					<div class="conteiner-error">
						<small class="messageError">{$_("Register.message_error_password")}</small>
					</div>
				{/if}
				<div class="contraseña">
					<h1 class="text-h1">{$_("Register.passwordConf")}</h1>
					<div class="c-password">
						<input
							class="input-log"
							type="password"
							id="confirmPassword"
							placeholder={$_("Register.placeholder_pass")}
							minlength="8"
							bind:value={confirmPassword}
							on:onblur={(messageError.confirmPasswStatus = false)} />
						<!-- svelte-ignore a11y-missing-attribute -->
						<img
							id="passworImagConf"
							src="/img/show-password.png"
							class="showPassword"
							on:click={() => {
								showPassword("confirmPassword", "passworImagConf");
							}} />
					</div>
				</div>

				{#if messageError.confirmPassword && confirmPassword == ""}
					<div class="conteiner-error">
						<small class="messageError">{$_("Register.message_error_confirmPassword")}</small>
					</div>
				{/if}

				<div class="dni">
					<h1 class="text-h1">{$_("Register.credential")}</h1>
					<div class="c-dni">
						<input
							class="input-log"
							type="text"
							id="dni"
							minlength="8"
							bind:value={dni}
							on:keypress={validateOnlyNumbers} />
					</div>
				</div>
				{#if messageError.dni && dni == ""}
					<div class="conteiner-error">
						<small class="messageError">{messageError.dni ? messageError.dni : ""}</small>
					</div>
				{/if}

				<div class="country" style="d-flex justify-content-center">
					<h1 class="text-h1">{$_("Register.tex_country")}</h1>
					<Select id="select-Country" items={countryList} bind:value={countrySelect} />
				</div>
				{#if messageError.countrySelect && countrySelect == ""}
					<div class="conteiner-error">
						<small class="messageError">{$_("Register.message_error_rol")}</small>
					</div>
				{/if}
				<!--para quitar rol-->
				<div class="rol" style="d-flex justify-content-center">
					<h1 class="text-h1">{$_("Register.registerlog")}</h1>
					<Select id="select-rol" items={$_("Register.rolSelect")} bind:value={rol} />
				</div>
				{#if messageError.rol && rol == ""}
					<div class="conteiner-error">
						<small class="messageError">{$_("Register.message_error_rol")}</small>
					</div>
				{/if}
				<div class="terms-checkbox">
					<input type="checkbox" id="termsCheckbox" bind:checked={termsChecked} />
					<label class="lab_checkbox" for="termsCheckbox"
						>{$_("Register.TermsSucces")}
						<!-- svelte-ignore a11y-invalid-attribute -->
						<a href="#" on:click|preventDefault={openTermsPDF}>{getTermsButtonText()}</a></label>
				</div>
				<button class="btnRegister" disabled={termsChecked === false}>{$_("Register.button_sign")}</button>

				<button class="btnInicio" on:click={() => replace("/")}>{$_("Register.button_login")}</button>

				<!-- <a href="/Login" on:click={() => replace("/")}>Inicia sesión</a> -->
				<!-- <button on:click={()=>replace('/')} class="btnRegister">Ingresar</button> -->
			</form>
		</div>
		<!-- <div class="conteiner-img">
			<h3 class="title-welcome">{$_("Register.title")}</h3>
			<br />
			<img class="image-conteiner" src="./img/Frame 92.png" alt="login" />
		</div> -->
	</div>
</div>

<!-- error modal -->
<Modals>
	<div slot="backdrop" class="backdrop" transition:fade on:click={closeModal} />
</Modals>

<!--
<LayoutModal
	tam="50"
	isOpenModal={modalTerm}
	on:closeModal={() => {
		modalTerm = false;
	}}>
	<div class="terms-modal">
		{#if rol && rol.label}
			<h2>{$_("Register.terms_label")} {rol.label}</h2>
		{:else}
			<h2>{$_("Register.terms_label")}</h2>
		{/if}
		<p style="text-align: initial;">
			{$_("Register.TermsMessage")}
		</p>-->
<!--<div class="container d-flex justify-content-center justify-content-md-between">
			
			<button class="btnterm" on:click={openTermsPDF}>
                {getTermsButtonText()}
            </button>
			<button
			class="btnclosed"
			on:click={() => {
				modalTerm = false; // Close the modal when the button is clicked
			}}>{$_("Register.button_closed")}</button>

			
	</div>
	</div>

    
</LayoutModal>-->

<style>
	.logo {
		width: max-content;
		height: max-content;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
	}
	.btnInicio {
		margin: 10px;
		width: 70%;
		height: 3.2rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		color: #0c0c0c;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnclosed {
		margin: 10px;
		width: 30%;
		height: 3.2rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		color: #0c0c0c;
		border-radius: 10px;
		background-color: #fff;
	}
	.btnterm {
		margin: 10px;
		width: 50%;
		height: 3.2rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		color: #0c0c0c;
		border-radius: 10px;
		background-color: #fff;
	}
	.conteiner-error {
		text-align: start;
		width: 100%;
		align-items: flex-start;
		overflow: hidden;
	}
	.showPassword {
		cursor: pointer;
		width: 30px;
		right: 0;
		position: absolute;
		transform: translateY(25%);
	}
	.c-password {
		position: relative;
	}
	.imgBack {
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: #d8e3f2;
	}
	.text-h1 {
		font-size: 1rem;
		margin-top: 2%;
	}
	.language-selector {
		display: inline-block;
		border: 2px solid #ccc;
		border-radius: 20px;
		padding: 5px;
		position: absolute;
		margin: 1%;
	}
	.btnRegister:disabled {
		background-color: gray; /* Set the background color to gray */
		width: 70%;
	}

	.language-selector select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent;
		border: none;
		font-size: 14px;
		padding: 5px;
		padding-left: 25px;
		width: 150px;
		cursor: pointer;
		text-align: center;
	}
	.terms-checkbox {
		margin: 5%;
	}
	.terms-modal {
		text-align: center;
		margin: 2%;
	}
	.language-selector select:focus {
		outline: none;
	}

	.language-selector button:focus {
		outline: none;
	}

	.language-selector button {
		position: absolute;
		top: 50%;
		left: 5px;
		transform: translateY(-50%);
		background: none;
		border: none;
		cursor: pointer;
	}

	.language-selector button:focus {
		outline: none;
	}

	.language-selector button img {
		width: 40px;
		height: 40px;
	}

	.register-conteiner {
		width: 600px;
		height: max-content;
		padding: 17px;
		position: relative;
		background: #fff;
		border-radius: 15px;
		margin-left: auto;
		margin-top: 15px;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}
	/* .language-toggle {
		position: absolute;
	}

	#language-select {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.language-option {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.language-option span {
		margin-top: 5px;
	} */
	.messageError {
		color: red;
		padding-left: 6%;
	}
	.register-info-conteiner {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 0.5rem;
	}
	.register-form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.conteiner-img {
		text-align: center;
		padding-top: 10rem;
	}
	.title-welcome {
		color: #0c0c0c;
		width: 70%;
		text-align: center;
		margin: auto;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 20px;
	}
	.email {
		width: 90%;
		color: #0c0c0c;
		font-weight: 600;
	}
	.confirmar-email {
		width: 90%;
		color: #0c0c0c;
		font-weight: 600;
	}
	.contraseña {
		width: 90%;
		color: #0c0c0c;
		font-weight: 600;
	}
	.dni {
		width: 90%;
		color: #0c0c0c;
		font-weight: 600;
	}
	.rol {
		width: 90%;
		color: #0c0c0c;
		font-weight: 600;
	}
	.country {
		width: 90%;
		color: #0c0c0c;
		font-weight: 600;
	}

	.input-log {
		width: 100%;
		height: 2.6rem;
		padding-left: 9px;
		border-radius: 5px;
		letter-spacing: 1px;
	}

	.btnRegister {
		margin: 10px;
		width: 70%;
		height: 3.2rem;
		letter-spacing: 1px;
		color: #f7fafc;
		border-radius: 10px;
		background-color: #25085e;
	}

	@media (max-width: 767px) {
		.conteiner-img {
			display: none;
		}
		.register-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
		.register-conteiner {
			width: 95%;
			height: max-content;
			padding: 17px;
			position: relative;
			background: #fff;
			border-radius: 15px;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
		}
		.terms-checkbox {
			margin: 5%;
			display: flex;
			align-items: baseline;
		}
		.lab_checkbox {
			display: inline-block;
			margin-bottom: 0.5rem;
			padding: 2%;
		}
	}

	@media (min-width: 768px) and (max-width: 1023px) {
	}

	@media (min-width: 1024px) {
	}
</style>
