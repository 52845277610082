<script>
	import { Auth } from "aws-amplify";
	import { replace } from "svelte-spa-router";
	import awsconfig from "../../aws-exports";
	import { language } from "../../helpers/storage/stores";
	import { _ } from "svelte-i18n";

	Auth.configure(awsconfig);
	let username = "";
	let lang_select = "es";
	let storedLanguage = JSON.parse(localStorage.getItem("language")) || "es";
	lang_select = storedLanguage;
	if ($language == "es" || $language == "en") {
		lang_select = $language;
	}

	/**
	 * @description
	 */
	async function confirmEmail() {
		Auth.forgotPassword(username).then((data) => {
			replace("/NewPassword/" + username);
		});
	}
</script>

<svelte:head>
	<title>RDCOM - {$_("ResetPassword.titleHead")}</title>
	<link rel="icon" href="/img/new_favicon.png">
</svelte:head>
<div class="body_log">
	<!-- svelte-ignore a11y-missing-attribute -->
	<!-- <img class="imgBack"/>
	<div class="logo">
	
		<img src="/img/new_logo.png" alt="logo" width="170" height="138" style="margin: 20px;" />
	</div> -->

	<div class="login-conteiner">
		<div class="login-info-conteiner">
			<div class="block">
				<h1 class="title">{$_("ResetPassword.title")}</h1>
			</div>
			<form class="register-form" on:submit|preventDefault={confirmEmail}>
				<!-- <h1 class="title-welcome">Restablecer su contraseña</h1> -->
				<div class="email" style="d-flex justify-content-center">
					<p>{$_("ResetPassword.email")}</p>
					<input
						class="input-log"
						type="email"
						placeholder="name@domain.com"
						bind:value={username}
						required />
				</div>
				<button class="btnRegister">{$_("ResetPassword.button_confirm")}</button>
			</form>
			<p class="span_register">{$_("ResetPassword.textLabel")}</p>
			<a href="/Login" on:click={() => replace("/")}>{$_("ResetPassword.button_login")}</a>
		</div>
		<!-- <div class="conteiner-img">
			<h1 class="title-welcome">{$_("ResetPassword.titleSecund")}</h1>
			<img class="image-conteiner" src="/img/Frame 92.png" alt="login" />
		</div> -->
	</div>
</div>

<!-- <Modal isOpenModal={confirmModal} on:closeModal={()=>replace("/")}> 
  <p>{message}</p>
</Modal> -->
<style>
	.block {
		color: #0C0C0C;
		text-align-last: center;
		grid-column: 1/3;
	}
	.logo {
		width: max-content;
		height: max-content;
		background: #fff;
		margin-left: auto;
		padding-top: 30px;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
	}
	.span_register {
		text-align: center;
	}
	.login-conteiner {
		width: 500px;
		height: max-content;
		position: relative;
		padding: 17px;
		background: #fff;
		border-radius: 15px;
		margin-left: auto;
		margin-top: 200px;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
	}
	.login-info-conteiner {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 2.5rem;
	}
	.register-info-conteiner {
		/* width: 70%; */
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 0.5rem;
	}
	.imgBack {
		opacity: 0.5;
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: #d8e3f2;
	}
	.register-form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.conteiner-img {
		text-align: center;
	}
	.title-welcome {
		color: #0C0C0C;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		margin-top: 5%;
		font-size: 20px;
	}
	.title {
		color: #0C0C0C;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 30px;
	}
	.email {
		width: 90%;
		color: #0C0C0C;
		font-weight: 600;
	}
	/* .confirmar-email {
    width: 90%;
    color: #0C0C0C;
    font-weight: 600;
  } */
	.input-log {
		width: 100%;
		height: 3.125rem;
		padding-left: 9px;
		border-radius: 5px;
		letter-spacing: 1px;
	}

	.btnRegister {
		margin: 10px;
		width: 90%;
		height: 3.2rem;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #f7fafc;
		border-radius: 10px;
		background-color: #25085e;
	}
	@media screen and (max-width: 1000px) {
		.conteiner-img {
			display: none;
		}
		.login-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 1.5rem;
		}
		.title-welcome {
			color: #0C0C0C;
			font-family: "Roboto", sans-serif;
			font-weight: bold;
			font-size: 20px;
			text-align-last: center;
		}
		.login-conteiner {
			width: 90%;
			height: max-content;
			position: relative;
			padding: 17px;
			background: #fff;
			border-radius: 15px;
			margin-left: auto;
			margin-right: auto;
			display: grid;
			justify-content: space-between;
			overflow: hidden;
			box-shadow: 0px 10px 20px 2pxrgba (0, 0, 0, 0.25);
		}
		.title {
			color: #0C0C0C;
			font-family: "Roboto", sans-serif;
			font-weight: bold;
			font-size: 30px;
			text-align: center;
		}

		.register-info-conteiner {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0.5rem;
		}
	}
</style>
